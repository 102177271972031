import { FC } from 'react';

/** Types */
import { IconProps } from '@/types';

export const TrblCardsViewIcon: FC<IconProps> = ({ fill = '#DADADA' }) => (
  <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 1.93C0 1.05509 0 0.617637 0.255106 0.332817C0.279566 0.305509 0.305509 0.279566 0.332817 0.255106C0.617637 0 1.05509 0 1.93 0H3.07C3.94491 0 4.38236 0 4.66718 0.255106C4.69449 0.279566 4.72043 0.305509 4.74489 0.332817C5 0.617637 5 1.05509 5 1.93V3.07C5 3.94491 5 4.38236 4.74489 4.66718C4.72043 4.69449 4.69449 4.72043 4.66718 4.74489C4.38236 5 3.94491 5 3.07 5H1.93C1.05509 5 0.617637 5 0.332817 4.74489C0.305509 4.72043 0.279566 4.69449 0.255106 4.66718C0 4.38236 0 3.94491 0 3.07V1.93Z"
      fill={fill}
    />
    <path
      d="M7 1.93C7 1.05509 7 0.617637 7.25511 0.332817C7.27957 0.305509 7.30551 0.279566 7.33282 0.255106C7.61764 0 8.05509 0 8.93 0H10.07C10.9449 0 11.3824 0 11.6672 0.255106C11.6945 0.279566 11.7204 0.305509 11.7449 0.332817C12 0.617637 12 1.05509 12 1.93V3.07C12 3.94491 12 4.38236 11.7449 4.66718C11.7204 4.69449 11.6945 4.72043 11.6672 4.74489C11.3824 5 10.9449 5 10.07 5H8.93C8.05509 5 7.61764 5 7.33282 4.74489C7.30551 4.72043 7.27957 4.69449 7.25511 4.66718C7 4.38236 7 3.94491 7 3.07V1.93Z"
      fill={fill}
    />
    <path
      d="M0 8.93C0 8.05509 0 7.61764 0.255106 7.33282C0.279566 7.30551 0.305509 7.27957 0.332817 7.25511C0.617637 7 1.05509 7 1.93 7H3.07C3.94491 7 4.38236 7 4.66718 7.25511C4.69449 7.27957 4.72043 7.30551 4.74489 7.33282C5 7.61764 5 8.05509 5 8.93V10.07C5 10.9449 5 11.3824 4.74489 11.6672C4.72043 11.6945 4.69449 11.7204 4.66718 11.7449C4.38236 12 3.94491 12 3.07 12H1.93C1.05509 12 0.617637 12 0.332817 11.7449C0.305509 11.7204 0.279566 11.6945 0.255106 11.6672C0 11.3824 0 10.9449 0 10.07V8.93Z"
      fill={fill}
    />
    <path
      d="M7 8.93C7 8.05509 7 7.61764 7.25511 7.33282C7.27957 7.30551 7.30551 7.27957 7.33282 7.25511C7.61764 7 8.05509 7 8.93 7H10.07C10.9449 7 11.3824 7 11.6672 7.25511C11.6945 7.27957 11.7204 7.30551 11.7449 7.33282C12 7.61764 12 8.05509 12 8.93V10.07C12 10.9449 12 11.3824 11.7449 11.6672C11.7204 11.6945 11.6945 11.7204 11.6672 11.7449C11.3824 12 10.9449 12 10.07 12H8.93C8.05509 12 7.61764 12 7.33282 11.7449C7.30551 11.7204 7.27957 11.6945 7.25511 11.6672C7 11.3824 7 10.9449 7 10.07V8.93Z"
      fill={fill}
    />
  </svg>
);
