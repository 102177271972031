import { IconProps } from '@/types';
import { FC } from 'react';

export const TrblTimeComputerIcon: FC<IconProps> = ({ fill }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.00257 10C8.96067 9.99321 8.91793 10.0054 8.8859 10.0332C8.85327 10.0608 8.83448 10.1014 8.83457 10.1441V10.1412C8.83457 10.2217 8.76928 10.287 8.68874 10.287H1.64648C1.56594 10.287 1.50065 10.2217 1.50065 10.1412V5.81367C1.50034 5.77479 1.51557 5.73739 1.54295 5.70979C1.57033 5.68219 1.6076 5.66667 1.64648 5.66667H3.85417C3.89694 5.66696 3.93771 5.64854 3.96575 5.61623C3.99379 5.58393 4.00631 5.54098 4 5.49867C3.96219 5.26488 3.94268 5.02849 3.94167 4.79167C3.94167 4.74558 3.94167 4.6995 3.94633 4.654C3.94858 4.61393 3.93421 4.5747 3.90662 4.54556C3.87902 4.51642 3.84063 4.49994 3.8005 4.5H1.50065C0.856319 4.5 0.333984 5.02233 0.333984 5.66667V10.8333C0.333984 11.4777 0.856319 12 1.50065 12H4.52148C4.60202 12 4.66732 12.0653 4.66732 12.1458V12.6875C4.66732 12.768 4.60202 12.8333 4.52148 12.8333H3.58333C3.26117 12.8333 3 13.0945 3 13.4167C3 13.7388 3.26117 14 3.58333 14H6.91667C7.23883 14 7.5 13.7388 7.5 13.4167C7.5 13.0945 7.23883 12.8333 6.91667 12.8333H5.97982C5.89927 12.8333 5.83398 12.768 5.83398 12.6875V12.1458C5.83398 12.0653 5.89927 12 5.97982 12H8.83399C9.47832 12 10.0007 11.4777 10.0007 10.8333V10.1001C10.0007 10.0599 9.98429 10.0215 9.95515 9.99392C9.9257 9.96641 9.88631 9.95209 9.84607 9.95425C9.80057 9.95425 9.75507 9.95892 9.70899 9.95892C9.41406 10.0332 9.23626 10.0367 9.00257 10Z"
      fill={fill || '#DADADA'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 4.75041C5 2.40297 6.90297 0.5 9.25041 0.5C11.5967 0.502882 13.4979 2.40417 13.5008 4.75041C13.5008 7.09785 11.5978 9.00082 9.25041 9.00082C6.90297 9.00082 5 7.09785 5 4.75041ZM9.24988 1.4398C7.42192 1.4398 5.94006 2.92166 5.94006 4.74962C5.94006 6.57757 7.42192 8.05943 9.24988 8.05943C11.0778 8.05943 12.5597 6.57757 12.5597 4.74962C12.5597 2.92166 11.0778 1.4398 9.24988 1.4398Z"
      fill={fill || '#DADADA'}
    />
    <path
      d="M8.71875 2.89844C8.71875 2.67839 8.89714 2.5 9.11719 2.5C9.33724 2.5 9.51562 2.67839 9.51562 2.89844V4.88281C9.51562 5.10286 9.33724 5.28125 9.11719 5.28125C8.89714 5.28125 8.71875 5.10286 8.71875 4.88281V2.89844Z"
      fill={fill || '#D9D9D9'}
    />
    <path
      d="M8.71875 4.88281C8.71875 4.66276 8.89714 4.48438 9.11719 4.48438H10.4453C10.6654 4.48438 10.8438 4.66276 10.8438 4.88281C10.8438 5.10286 10.6654 5.28125 10.4453 5.28125H9.11719C8.89714 5.28125 8.71875 5.10286 8.71875 4.88281Z"
      fill={fill || '#D9D9D9'}
    />
  </svg>
);
