import { FC } from 'react';

/** Types */
import { IconProps } from '@/types';

export const TrblCrankWheelIcon: FC<IconProps> = ({ fill, width = 14, height = 14 }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.319 5.75H12.25C12.6642 5.75 13 6.08579 13 6.5V7.5C13.0003 7.69909 12.9214 7.89011 12.7807 8.03098C12.64 8.17185 12.4491 8.251 12.25 8.251H11.319C11.2266 8.57096 11.0987 8.87956 10.9375 9.171L11.596 9.8295C11.8888 10.1224 11.8888 10.5971 11.596 10.89L10.889 11.597C10.5961 11.8898 10.1214 11.8898 9.8285 11.597L9.1695 10.9385C8.87812 11.0994 8.56971 11.2274 8.25 11.32V12.25C8.25 12.6642 7.91421 13 7.5 13H6.5C6.08579 13 5.75 12.6642 5.75 12.25V11.32C5.43011 11.2272 5.12154 11.0991 4.83 10.938L4.171 11.5965C3.87326 11.8778 3.40774 11.8778 3.11 11.5965L2.403 10.8895C2.11021 10.5966 2.11021 10.1219 2.403 9.829L3.0625 9.1695C2.90126 8.87827 2.77329 8.56983 2.681 8.25H1.75C1.33579 8.25 1 7.91421 1 7.5V6.5C1 6.08579 1.33579 5.75 1.75 5.75H2.681C2.77332 5.43034 2.90129 5.12207 3.0625 4.831L2.4035 4.1725C2.26274 4.03183 2.18366 3.841 2.18366 3.642C2.18366 3.443 2.26274 3.25217 2.4035 3.1115L3.111 2.4045C3.40387 2.11171 3.87863 2.11171 4.1715 2.4045L4.8305 3.063C5.12189 2.9021 5.43029 2.77415 5.75 2.6815V1.75C5.75 1.33579 6.08579 1 6.5 1H7.5C7.91421 1 8.25 1.33579 8.25 1.75V2.6815C8.56962 2.77393 8.87788 2.9019 9.169 3.063L9.828 2.4045C10.1209 2.11171 10.5956 2.11171 10.8885 2.4045L11.596 3.111C11.7368 3.25167 11.8158 3.4425 11.8158 3.6415C11.8158 3.8405 11.7368 4.03133 11.596 4.172L10.9375 4.83C11.0985 5.12152 11.2264 5.4301 11.319 5.75ZM4.5 6.99994C4.5 8.38065 5.61929 9.49994 7 9.49994C8.38003 9.49829 9.49835 8.37997 9.5 6.99994C9.5 5.61923 8.38071 4.49994 7 4.49994C5.61929 4.49994 4.5 5.61923 4.5 6.99994Z"
      fill={fill || '#DADADA'}
    />
  </svg>
);
