export const CordImage = ({ fill = '#00F5BA' }: { fill?: string }) => (
  <svg width="1439" height="716" viewBox="0 0 1439 716" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2291_129780)">
      <path
        d="M421.937 288.168H393.607C393.927 288.488 394.251 288.813 394.571 289.133C394.571 274.226 394.571 259.314 394.571 244.406L393.602 245.371C403.047 245.371 412.488 245.371 421.932 245.371L420.968 244.402C420.968 259.309 420.968 274.221 420.968 289.129C420.968 290.372 422.901 290.376 422.901 289.129C422.901 274.221 422.901 259.309 422.901 244.402C422.901 243.881 422.457 243.432 421.937 243.432C412.492 243.432 403.051 243.432 393.607 243.432C393.086 243.432 392.638 243.876 392.638 244.397C392.638 259.305 392.638 274.217 392.638 289.125C392.638 289.646 393.082 290.095 393.602 290.095H421.932C423.174 290.095 423.179 288.16 421.932 288.16L421.937 288.168Z"
        fill={fill}
      />
      <path
        d="M418.394 293.956C411.314 293.956 404.229 293.956 397.149 293.956L398.114 294.926C398.114 293.213 398.114 291.504 398.114 289.791L397.145 290.757C404.225 290.757 411.31 290.757 418.39 290.757L417.426 289.787C417.426 291.5 417.426 293.209 417.426 294.921C417.426 296.164 419.359 296.169 419.359 294.921C419.359 293.209 419.359 291.5 419.359 289.787C419.359 289.266 418.915 288.817 418.394 288.817C411.314 288.817 404.229 288.817 397.149 288.817C396.628 288.817 396.18 289.262 396.18 289.783C396.18 291.496 396.18 293.204 396.18 294.917C396.18 295.438 396.624 295.887 397.145 295.887C404.225 295.887 411.31 295.887 418.39 295.887C419.632 295.887 419.636 293.952 418.39 293.952L418.394 293.956Z"
        fill={fill}
      />
      <path
        d="M412.53 329.068C409.355 329.068 406.18 329.068 403.005 329.068L403.969 330.038V295.575C403.649 295.895 403.325 296.22 403.005 296.54C406.18 296.54 409.355 296.54 412.53 296.54L411.566 295.571V330.034C411.566 331.277 413.499 331.281 413.499 330.034V295.571C413.499 295.05 413.055 294.601 412.535 294.601C409.359 294.601 406.184 294.601 403.009 294.601C402.488 294.601 402.04 295.045 402.04 295.566V330.029C402.04 330.551 402.484 330.999 403.005 330.999C406.18 330.999 409.355 330.999 412.53 330.999C413.772 330.999 413.777 329.064 412.53 329.064V329.068Z"
        fill={fill}
      />
      <path
        d="M418.394 238.468C411.314 238.468 404.229 238.468 397.149 238.468C396.628 238.468 396.18 238.913 396.18 239.434C396.18 241.091 396.18 242.749 396.18 244.402C396.18 244.923 396.624 245.371 397.145 245.371C404.225 245.371 411.31 245.371 418.39 245.371C418.911 245.371 419.359 244.927 419.359 244.406V239.438C419.359 238.195 417.426 238.191 417.426 239.438C417.426 241.096 417.426 242.753 417.426 244.406L418.394 243.441C411.314 243.441 404.229 243.441 397.149 243.441L398.114 244.41C398.114 242.753 398.114 241.096 398.114 239.442C397.789 239.763 397.469 240.087 397.145 240.408C404.225 240.408 411.31 240.408 418.39 240.408C419.632 240.408 419.636 238.473 418.39 238.473L418.394 238.468Z"
        fill={fill}
      />
      <path
        d="M421.322 237.819C412.287 237.819 403.252 237.819 394.213 237.819L395.177 238.789V217.606C394.857 217.927 394.533 218.251 394.213 218.572C403.248 218.572 412.283 218.572 421.322 218.572L420.358 217.602V238.785C420.358 240.028 422.291 240.032 422.291 238.785V217.602C422.291 217.081 421.847 216.632 421.326 216.632C412.291 216.632 403.256 216.632 394.217 216.632C393.696 216.632 393.248 217.077 393.248 217.598V238.78C393.248 239.301 393.692 239.75 394.213 239.75C403.248 239.75 412.283 239.75 421.322 239.75C422.564 239.75 422.568 237.815 421.322 237.815V237.819Z"
        fill={fill}
      />
      <path
        d="M408.096 339.256C405.13 339.256 405.318 336.907 405.318 334.745C405.318 333.178 405.318 331.61 405.318 330.042L404.349 331.008C406.628 331.008 408.907 331.008 411.186 331.008L410.221 330.038V334.169C410.221 336.035 410.746 339.017 408.096 339.252C406.867 339.363 406.854 341.298 408.096 341.187C413.529 340.7 412.155 333.861 412.155 330.034C412.155 329.513 411.711 329.064 411.19 329.064C408.911 329.064 406.632 329.064 404.353 329.064C403.832 329.064 403.384 329.508 403.384 330.029C403.384 334.186 402.019 341.182 408.096 341.182C409.338 341.182 409.342 339.247 408.096 339.247V339.256Z"
        fill={fill}
      />
      <path
        d="M398.135 248.806V284.734C398.135 285.977 400.068 285.981 400.068 284.734V248.806C400.068 247.563 398.135 247.558 398.135 248.806Z"
        fill={fill}
      />
      <path
        d="M403.914 248.806V284.734C403.914 285.977 405.847 285.981 405.847 284.734V248.806C405.847 247.563 403.914 247.558 403.914 248.806Z"
        fill={fill}
      />
      <path
        d="M409.692 248.806V284.734C409.692 285.977 411.626 285.981 411.626 284.734V248.806C411.626 247.563 409.692 247.558 409.692 248.806Z"
        fill={fill}
      />
      <path
        d="M415.475 248.806V284.734C415.475 285.977 417.409 285.981 417.409 284.734V248.806C417.409 247.563 415.475 247.558 415.475 248.806Z"
        fill={fill}
      />
      <path
        d="M403.743 324.241C406.427 324.241 409.116 324.241 411.8 324.241C413.042 324.241 413.047 322.306 411.8 322.306C409.116 322.306 406.427 322.306 403.743 322.306C402.501 322.306 402.497 324.241 403.743 324.241Z"
        fill={fill}
      />
      <path
        d="M403.743 316.177C406.427 316.177 409.116 316.177 411.8 316.177C413.042 316.177 413.047 314.242 411.8 314.242C409.116 314.242 406.427 314.242 403.743 314.242C402.501 314.242 402.497 316.177 403.743 316.177Z"
        fill={fill}
      />
      <path
        d="M415.817 179.158C410.452 179.158 405.092 179.158 399.727 179.158C399.176 179.158 398.797 179.602 398.758 180.123C397.9 192.618 397.038 205.108 396.18 217.602C396.146 218.123 396.654 218.572 397.145 218.572C404.225 218.572 411.31 218.572 418.39 218.572C418.885 218.572 419.393 218.127 419.359 217.606C418.501 205.112 417.639 192.622 416.781 180.128C416.696 178.893 414.762 178.885 414.848 180.128C415.706 192.622 416.568 205.112 417.426 217.606L418.394 216.641C411.314 216.641 404.229 216.641 397.149 216.641L398.114 217.61C398.971 205.116 399.834 192.626 400.691 180.132L399.723 181.097H415.812C417.054 181.097 417.059 179.162 415.812 179.162L415.817 179.158Z"
        fill={fill}
      />
      <path
        d="M397.922 213.113C403.052 213.113 408.177 213.113 413.307 213.113C414.549 213.113 414.553 211.177 413.307 211.177C408.177 211.177 403.052 211.177 397.922 211.177C396.68 211.177 396.675 213.113 397.922 213.113Z"
        fill={fill}
      />
      <path
        d="M398.651 205.778H412.816C414.058 205.778 414.063 203.843 412.816 203.843H398.651C397.41 203.843 397.405 205.778 398.651 205.778Z"
        fill={fill}
      />
      <path
        d="M398.899 198.201C403.214 198.201 407.529 198.201 411.843 198.201C413.085 198.201 413.09 196.265 411.843 196.265C407.529 196.265 403.214 196.265 398.899 196.265C397.657 196.265 397.653 198.201 398.899 198.201Z"
        fill={fill}
      />
      <path
        d="M399.629 190.623H411.352C412.594 190.623 412.599 188.688 411.352 188.688H399.629C398.387 188.688 398.383 190.623 399.629 190.623Z"
        fill={fill}
      />
      <path
        d="M398.246 224.112C404.596 224.112 410.943 224.112 417.293 224.112C418.535 224.112 418.539 222.177 417.293 222.177C410.943 222.177 404.596 222.177 398.246 222.177C397.004 222.177 397 224.112 398.246 224.112Z"
        fill={fill}
      />
      <path
        d="M398.246 229.161C404.596 229.161 410.943 229.161 417.293 229.161C418.535 229.161 418.539 227.226 417.293 227.226C410.943 227.226 404.596 227.226 398.246 227.226C397.004 227.226 397 229.161 398.246 229.161Z"
        fill={fill}
      />
      <path
        d="M398.246 234.214C404.596 234.214 410.943 234.214 417.293 234.214C418.535 234.214 418.539 232.279 417.293 232.279C410.943 232.279 404.596 232.279 398.246 232.279C397.004 232.279 397 234.214 398.246 234.214Z"
        fill={fill}
      />
      <path d="M402.407 179.717C402.407 180.96 404.34 180.965 404.34 179.717H402.407Z" fill={fill} />
      <path d="M411.199 179.717C411.199 180.96 413.132 180.965 413.132 179.717H411.199Z" fill={fill} />
      <path
        d="M386.77 416.755H428.774L427.805 415.79C427.805 437.318 428.347 458.915 427.805 480.435C427.69 485.07 425.765 489.371 421.95 492.135C418.454 494.672 414.596 494.83 410.495 494.83C407.635 494.83 404.767 494.916 401.908 494.83C395.656 494.643 390.163 490.764 388.319 484.69C387.222 481.076 387.735 476.651 387.735 472.922V415.79C387.735 414.546 385.801 414.542 385.801 415.79V472.26C385.801 476.044 385.383 480.213 386.151 483.951C387.133 488.739 390.389 492.925 394.764 495.091C397.973 496.68 401.285 496.765 404.763 496.765C412.642 496.765 420.802 497.56 426.282 490.61C429.777 486.176 429.739 481.17 429.739 475.899V415.79C429.739 415.268 429.295 414.824 428.774 414.824H386.77C385.528 414.824 385.524 416.759 386.77 416.759V416.755Z"
        fill={fill}
      />
      <path
        d="M428.121 477.242H387.06C385.906 477.242 385.902 479.28 387.06 479.28H428.121C429.275 479.28 429.279 477.242 428.121 477.242Z"
        fill={fill}
      />
      <path
        d="M428.121 471.129H387.06C385.906 471.129 385.902 473.167 387.06 473.167H428.121C429.275 473.167 429.279 471.129 428.121 471.129Z"
        fill={fill}
      />
      <path
        d="M428.121 465.015H387.06C385.906 465.015 385.902 467.053 387.06 467.053H428.121C429.275 467.053 429.279 465.015 428.121 465.015Z"
        fill={fill}
      />
      <path
        d="M428.121 458.902H387.06C385.906 458.902 385.902 460.939 387.06 460.939H428.121C429.275 460.939 429.279 458.902 428.121 458.902Z"
        fill={fill}
      />
      <path
        d="M428.121 452.788H387.06C385.906 452.788 385.902 454.826 387.06 454.826H428.121C429.275 454.826 429.279 452.788 428.121 452.788Z"
        fill={fill}
      />
      <path
        d="M391.362 492.259C392.438 507.017 393.509 521.775 394.585 536.534C394.623 537.055 394.998 537.499 395.553 537.499H419.995C420.546 537.499 420.926 537.055 420.96 536.534C422.035 521.775 423.106 507.017 424.182 492.259C424.272 491.016 422.338 491.02 422.249 492.259C421.173 507.017 420.102 521.775 419.026 536.534L419.995 535.568H395.553L396.518 536.534C395.442 521.775 394.371 507.017 393.296 492.259C393.206 491.025 391.273 491.016 391.362 492.259Z"
        fill={fill}
      />
      <path
        d="M421.446 506.722H412.654C411.413 506.722 411.408 508.658 412.654 508.658H421.446C422.688 508.658 422.692 506.722 421.446 506.722Z"
        fill={fill}
      />
      <path
        d="M420.802 516.867H412.654C411.413 516.867 411.408 518.802 412.654 518.802H420.802C422.044 518.802 422.048 516.867 420.802 516.867Z"
        fill={fill}
      />
      <path
        d="M420.157 527.012H412.654C411.413 527.012 411.408 528.947 412.654 528.947H420.157C421.399 528.947 421.404 527.012 420.157 527.012Z"
        fill={fill}
      />
      <path
        d="M393.85 508.658H402.642C403.884 508.658 403.888 506.722 402.642 506.722H393.85C392.608 506.722 392.604 508.658 393.85 508.658Z"
        fill={fill}
      />
      <path
        d="M394.495 518.802H402.642C403.884 518.802 403.888 516.867 402.642 516.867H394.495C393.253 516.867 393.248 518.802 394.495 518.802Z"
        fill={fill}
      />
      <path
        d="M395.139 528.947H402.642C403.884 528.947 403.888 527.012 402.642 527.012H395.139C393.897 527.012 393.893 528.947 395.139 528.947Z"
        fill={fill}
      />
      <path d="M413.132 537.187C413.132 535.944 411.199 535.94 411.199 537.187H413.132Z" fill={fill} />
      <path d="M404.341 537.187C404.341 535.944 402.407 535.94 402.407 537.187H404.341Z" fill={fill} />
      <path
        d="M392.327 409.096H423.213L422.249 408.131V415.79L423.213 414.824H392.327L393.296 415.79V408.131C393.296 406.888 391.362 406.883 391.362 408.131V415.79C391.362 416.311 391.806 416.755 392.327 416.755H423.213C423.734 416.755 424.178 416.311 424.178 415.79V408.131C424.178 407.609 423.734 407.165 423.213 407.165H392.327C391.085 407.165 391.081 409.1 392.327 409.1V409.096Z"
        fill={fill}
      />
      <path
        d="M336.337 372.578L294.539 372.828"
        stroke={fill}
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M341.958 356.777L315.05 324.765"
        stroke={fill}
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M342.144 388.302L315.627 420.641"
        stroke={fill}
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M479.202 372.578L521 372.828"
        stroke={fill}
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M473.588 356.777L500.489 324.765"
        stroke={fill}
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M473.395 388.302L499.912 420.641"
        stroke={fill}
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M404.973 176.593V179.14H410.067V176.593H404.973ZM-56.0189 6.95651C-57.4258 6.95651 -58.5662 8.09698 -58.5662 9.50381C-58.5662 10.9107 -57.4258 12.0511 -56.0189 12.0511V6.95651ZM410.067 176.593V53.3514H404.973V176.593H410.067ZM410.067 53.3514C410.067 27.7312 389.314 6.95651 363.711 6.95651V12.0511C386.496 12.0511 404.973 30.5406 404.973 53.3514H410.067ZM363.711 6.95651H-56.0189V12.0511H363.711V6.95651Z"
        fill={fill}
      />
      <path
        d="M410.208 540.054V537.506H405.114V540.054H410.208ZM1500.99 710.119C1502.4 710.119 1503.54 708.979 1503.54 707.572C1503.54 706.165 1502.4 705.025 1500.99 705.025V710.119ZM405.114 540.054V663.724H410.208V540.054H405.114ZM405.114 663.724C405.114 689.344 425.867 710.119 451.47 710.119V705.025C428.685 705.025 410.208 686.535 410.208 663.724H405.114ZM451.47 710.119H1500.99V705.025H451.47V710.119Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_2291_129780">
        <rect width="1439" height="716" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
