export type LibrarySound = {
  name: string;
  soundPath: string;
  id: string;
  category: string;
  tags: string[];
  active: boolean;
};

export const AVAILABLE_SOUNDS: LibrarySound[] = [
  {
    id: 'BoardMeetingAtmosphere-0',
    category: 'Soundscapes',
    name: 'Board Meeting Atmosphere',
    soundPath: '/anechoic-sounds/legacy/Board_Meeting_Atmos_32k.wav',
    tags: ['Office', 'Dialog'],
    active: true,
  },
  {
    id: 'BoardMeetingGroup-1',
    category: 'Soundscapes',
    name: 'Board Meeting Group',
    soundPath: '/anechoic-sounds/legacy/Board_Meeting_Group_32k.wav',
    tags: ['Office', 'Dialog'],
    active: true,
  },
  {
    id: 'BoardMeetingMisc-2',
    category: 'Soundscapes',
    name: 'Board Meeting Misc',
    soundPath: '/anechoic-sounds/legacy/Board_Meeting_Misc_32k.wav',
    tags: ['Office', 'Dialog'],
    active: true,
  },
  {
    id: 'BoardMeetingScene-3',
    category: 'Soundscapes',
    name: 'Board Meeting Scene',
    soundPath: '/anechoic-sounds/legacy/Board_Meeting_REF_32k.wav',
    tags: ['Office', 'Dialog'],
    active: true,
  },
  {
    id: 'BoardMeetingSteps-4',
    category: 'Soundscapes',
    name: 'Board Meeting Steps',
    soundPath: '/anechoic-sounds/legacy/Board_Meeting_Steps_32k.wav',
    tags: ['Office', 'Walking'],
    active: true,
  },
  {
    id: 'BoysCoughandGiggle-5',
    category: 'People',
    name: 'Boys Cough and Giggle',
    soundPath: '/anechoic-sounds/legacy/Boys_Cough_and_Giggle_32k.wav',
    tags: ['Coughing'],
    active: true,
  },
  {
    id: 'Boysdialogue-6',
    category: 'People',
    name: 'Boys dialogue',
    soundPath: '/anechoic-sounds/legacy/Boys_DIA_32k.wav',
    tags: ['Dialog'],
    active: true,
  },
  {
    id: 'BoysWhisperdialogue-7',
    category: 'People',
    name: 'Boys Whisper dialogue',
    soundPath: '/anechoic-sounds/legacy/Boys_Whisper_DIA_32k.wav',
    tags: ['Dialog', 'Whisper'],
    active: true,
  },
  {
    id: 'BriefcaseLaptopBag-8',
    category: 'Soundscapes',
    name: 'Briefcase Laptop Bag',
    soundPath: '/anechoic-sounds/legacy/Briefcase_Laptop_Bag_32k.wav',
    tags: ['Misc'],
    active: true,
  },
  {
    id: 'Broomanddustpan-9',
    category: 'Soundscapes',
    name: 'Broom and dustpan',
    soundPath: '/anechoic-sounds/legacy/Broom_and_dustpan_32k.wav',
    tags: ['Misc'],
    active: true,
  },
  {
    id: 'ChairWood-10',
    category: 'Soundscapes',
    name: 'Chair Wood',
    soundPath: '/anechoic-sounds/legacy/Chair_Wood_32k.wav',
    tags: ['Misc'],
    active: true,
  },
  {
    id: 'ClapGroupHiEnergyv01-11',
    category: 'Clap',
    name: 'Clap Group Hi Energy v01',
    soundPath: '/anechoic-sounds/legacy/Clap_Group_Hi_Energy_v01_32k.wav',
    tags: [],
    active: true,
  },
  {
    id: 'ClapGroupHiEnergyv02-12',
    category: 'Clap',
    name: 'Clap Group Hi Energy v02',
    soundPath: '/anechoic-sounds/legacy/Clap_Group_Hi_Energy_v02_32k.wav',
    tags: [],
    active: true,
  },
  {
    id: 'ClapgroupHighEnergyv02-13',
    category: 'Clap',
    name: 'Clap group High Energy v02',
    soundPath: '/anechoic-sounds/legacy/Clap_group_high_energy_v02_32k.wav',
    tags: [],
    active: true,
  },
  {
    id: 'ClapGroupLowEnergy-14',
    category: 'Clap',
    name: 'Clap Group Low Energy',
    soundPath: '/anechoic-sounds/legacy/Clap_Group_Low_Energy_32k.wav',
    tags: [],
    active: true,
  },
  {
    id: 'ClapGroupLowEnergy_01-15',
    category: 'Clap',
    name: 'Clap Group Low Energy_01',
    soundPath: '/anechoic-sounds/legacy/Clap_Group_Low_Energy_01_32k.wav',
    tags: [],
    active: true,
  },
  {
    id: 'ClapGroupMediumEnergy-16',
    category: 'Clap',
    name: 'Clap Group Medium Energy',
    soundPath: '/anechoic-sounds/legacy/Clap_Group_Medium_Energy_32k.wav',
    tags: [],
    active: true,
  },
  {
    id: 'ClapGroupv01-17',
    category: 'Clap',
    name: 'Clap Group v01',
    soundPath: '/anechoic-sounds/legacy/Clap_Group_Energy_v01_32k.wav',
    tags: [],
    active: true,
  },
  {
    id: 'ClapGroupv02-18',
    category: 'Clap',
    name: 'Clap Group v02',
    soundPath: '/anechoic-sounds/legacy/Clap_Group_Energy_v02_32k.wav',
    tags: [],
    active: true,
  },
  {
    id: 'ClapGroupv03-19',
    category: 'Clap',
    name: 'Clap Group v03',
    soundPath: '/anechoic-sounds/legacy/Clap_Group_Energy_v03_32k.wav',
    tags: [],
    active: true,
  },
  {
    id: 'ClapHardEnergy-20',
    category: 'Clap',
    name: 'Clap Hard Energy',
    soundPath: '/anechoic-sounds/legacy/Clap_Hard_Energy_32k.wav',
    tags: [],
    active: true,
  },
  {
    id: 'ClapMediumEnergy-21',
    category: 'Clap',
    name: 'Clap Medium Energy',
    soundPath: '/anechoic-sounds/legacy/Clap_Medium_Energy_32k.wav',
    tags: [],
    active: true,
  },
  {
    id: 'ClapsingleHiEnergy-22',
    category: 'Clap',
    name: 'Clap single Hi Energy',
    soundPath: '/anechoic-sounds/legacy/Clap_single_Hi_Energy_32k.wav',
    tags: [],
    active: true,
  },
  {
    id: 'ClapsingleMedEnergyv01-23',
    category: 'Clap',
    name: 'Clap single Med Energy v01',
    soundPath: '/anechoic-sounds/legacy/Clap_single_Med_Energy_v01_32k.wav',
    tags: [],
    active: true,
  },
  {
    id: 'ClapsingleMedEnergyv02-24',
    category: 'Clap',
    name: 'Clap single Med Energy v02',
    soundPath: '/anechoic-sounds/legacy/Clap_single_Med_Energy_v02_32k.wav',
    tags: [],
    active: true,
  },
  {
    id: 'ClapsingleMedEnergyv03-25',
    category: 'Clap',
    name: 'Clap single Med Energy v03',
    soundPath: '/anechoic-sounds/legacy/Clap_single_Med_Energy_v03_32k.wav',
    tags: [],
    active: true,
  },
  {
    id: 'ClapsingleMedEnergyv04-26',
    category: 'Clap',
    name: 'Clap single Med Energy v04',
    soundPath: '/anechoic-sounds/legacy/Clap_single_Med_Energy_v04_32k.wav',
    tags: [],
    active: true,
  },
  {
    id: 'Clapsinglev02-27',
    category: 'Clap',
    name: 'Clap single v02',
    soundPath: '/anechoic-sounds/legacy/clap_single_v02_lower_32k.wav',
    tags: [],
    active: true,
  },
  {
    id: 'ClassroomAtmos-28',
    category: 'Soundscapes',
    name: 'Classroom Atmos',
    soundPath: '/anechoic-sounds/legacy/Classroom_Atmos_32k.wav',
    tags: ['classroom'],
    active: true,
  },
  {
    id: 'ClassroomBagsandNotebooks-29',
    category: 'Soundscapes',
    name: 'Classroom Bags and Notebooks',
    soundPath: '/anechoic-sounds/legacy/Classroom_Bags_and_Notebooks_32k.wav',
    tags: ['classroom'],
    active: true,
  },
  {
    id: 'ClassroomChair-30',
    category: 'Soundscapes',
    name: 'Classroom Chair',
    soundPath: '/anechoic-sounds/legacy/Classroom_Chair_32k.wav',
    tags: ['classroom'],
    active: true,
  },
  {
    id: 'ClassroomLaptopKeyboardandMouse-31',
    category: 'Soundscapes',
    name: 'Classroom Laptop Keyboard and Mouse',
    soundPath: '/anechoic-sounds/legacy/Classroom_Laptop_Keyboard_and_Mouse_32k.wav',
    tags: ['classroom'],
    active: true,
  },
  {
    id: 'ClassroomMurmur-32',
    category: 'Soundscapes',
    name: 'Classroom Murmur',
    soundPath: '/anechoic-sounds/legacy/Classroom_Walla_32k.wav',
    tags: ['classroom'],
    active: true,
  },
  {
    id: 'ClassroomPhone-33',
    category: 'Soundscapes',
    name: 'Classroom Phone',
    soundPath: '/anechoic-sounds/legacy/Classroom_Phone_32k.wav',
    tags: ['classroom'],
    active: true,
  },
  {
    id: 'ClassroomScene-34',
    category: 'Soundscapes',
    name: 'Classroom Scene',
    soundPath: '/anechoic-sounds/legacy/Classroom_REF_32k.wav',
    tags: ['classroom'],
    active: true,
  },
  {
    id: 'ClassroomSteps-35',
    category: 'Soundscapes',
    name: 'Classroom Steps',
    soundPath: '/anechoic-sounds/legacy/Classroom_Steps_32k.wav',
    tags: ['classroom', 'walking'],
    active: true,
  },
  {
    id: 'ClassroomTeacher-36',
    category: 'Soundscapes',
    name: 'Classroom Teacher',
    soundPath: '/anechoic-sounds/legacy/Classroom_Teacher_32k.wav',
    tags: ['classroom'],
    active: true,
  },
  {
    id: 'CoffeemachineClose-37',
    category: 'Soundscapes',
    name: 'Coffee machine Close',
    soundPath: '/anechoic-sounds/legacy/Coffeemachine_Close_32k.wav',
    tags: ['coffeehouse'],
    active: true,
  },
  {
    id: 'CoffeemachineRelated-38',
    category: 'Soundscapes',
    name: 'Coffee machine Related',
    soundPath: '/anechoic-sounds/legacy/Coffeemachine_Related_32k.wav',
    tags: ['coffeehouse'],
    active: true,
  },
  {
    id: 'Coffeemachinesteamandfroth-39',
    category: 'Soundscapes',
    name: 'Coffee machine steam and froth',
    soundPath: '/anechoic-sounds/legacy/Coffeemachine_steam_and_froth_32k.wav',
    tags: ['coffeehouse'],
    active: true,
  },
  {
    id: 'CoffeeshopAtmosphere-40',
    category: 'Soundscapes',
    name: 'Coffeeshop Atmosphere',
    soundPath: '/anechoic-sounds/legacy/Coffeeshop_Hi_Energy_Atmos_32k.wav',
    tags: ['coffeehouse'],
    active: true,
  },
  {
    id: 'CoffeeshopCoffeemachine-41',
    category: 'Soundscapes',
    name: 'Coffeeshop Coffee machine',
    soundPath: '/anechoic-sounds/legacy/Coffeeshop_Hi_Energy_Coffeemachine_32k.wav',
    tags: ['coffeehouse'],
    active: true,
  },
  {
    id: 'CoffeeshopCupsandCutlery-42',
    category: 'Soundscapes',
    name: 'Coffeeshop Cups and Cutlery',
    soundPath: '/anechoic-sounds/legacy/Coffeeshop_Cups_and_Cutlery_32k.wav',
    tags: ['coffeehouse'],
    active: true,
  },
  {
    id: 'CoffeeshopCupsandCutlery-43',
    category: 'Soundscapes',
    name: 'Coffeeshop Cups and Cutlery',
    soundPath: '/anechoic-sounds/legacy/Coffeeshop_Hi_Energy_Cups_and_Cutlery_32k.wav',
    tags: ['coffeehouse'],
    active: true,
  },
  {
    id: 'CoffeeshopMurmur-44',
    category: 'Soundscapes',
    name: 'Coffeeshop Murmur',
    soundPath: '/anechoic-sounds/legacy/Coffeeshop_Hi_Energy_Walla_32k.wav',
    tags: ['coffeehouse'],
    active: true,
  },
  {
    id: 'CoffeeshopPhonering-45',
    category: 'Soundscapes',
    name: 'Coffeeshop Phone ring',
    soundPath: '/anechoic-sounds/legacy/Coffeeshop_Hi_Energy_Phone_ring_32k.wav',
    tags: ['coffeehouse'],
    active: true,
  },
  {
    id: 'CoffeeshopQuieterAtmos-46',
    category: 'Soundscapes',
    name: 'Coffeeshop Quieter Atmos',
    soundPath: '/anechoic-sounds/legacy/Coffeeshop_Low_Energy_Atmos_32k.wav',
    tags: ['coffeehouse'],
    active: true,
  },
  {
    id: 'CoffeeshopQuieterCoffeemachine-47',
    category: 'Soundscapes',
    name: 'Coffeeshop Quieter Coffee machine',
    soundPath: '/anechoic-sounds/legacy/Coffeeshop_Low_Energy_Cofeemachine_32k.wav',
    tags: ['coffeehouse'],
    active: true,
  },
  {
    id: 'CoffeeshopQuieterMurmur-48',
    category: 'Soundscapes',
    name: 'Coffeeshop Quieter Murmur',
    soundPath: '/anechoic-sounds/legacy/Coffeeshop_Low_Energy_Walla_32k.wav',
    tags: ['coffeehouse'],
    active: true,
  },
  {
    id: 'CoffeeshopQuieterScene-49',
    category: 'Soundscapes',
    name: 'Coffeeshop Quieter Scene',
    soundPath: '/anechoic-sounds/legacy/Coffeeshop_Low_Energy_REFERENCE_32k.wav',
    tags: ['coffeehouse'],
    active: true,
  },
  {
    id: 'CoffeeshopQuieterStepsChairsandMisc-50',
    category: 'Soundscapes',
    name: 'Coffeeshop Quieter Steps Chairs and Misc',
    soundPath: '/anechoic-sounds/legacy/Coffeeshop_Low_Energy_Steps_Chairs_and_Misc_32k.wav',
    tags: ['coffeehouse'],
    active: true,
  },
  {
    id: 'CoffeeshopScene-51',
    category: 'Soundscapes',
    name: 'Coffeeshop Scene',
    soundPath: '/anechoic-sounds/legacy/Coffeeshop_Hi_Energy_REFERENCE_32k.wav',
    tags: ['coffeehouse'],
    active: true,
  },
  {
    id: 'CoffeeshopStepsChairsandMisc-52',
    category: 'Soundscapes',
    name: 'Coffeeshop Steps Chairs and Misc',
    soundPath: '/anechoic-sounds/legacy/Coffeeshop_Hi_Energy_Steps_Chairs_and_Misc_32k.wav',
    tags: ['coffeehouse'],
    active: true,
  },
  {
    id: 'Conversation1-53',
    category: 'Soundscapes',
    name: 'Conversation 1',
    soundPath: '/anechoic-sounds/legacy/S2.wav',
    tags: ['dialog'],
    active: true,
  },
  {
    id: 'Conversation2-54',
    category: 'Soundscapes',
    name: 'Conversation 2',
    soundPath: '/anechoic-sounds/legacy/P1_Edited_shorterversion_HP2_32k.wav',
    tags: ['monologue'],
    active: true,
  },
  {
    id: 'Conversation3-55',
    category: 'Soundscapes',
    name: 'Conversation 3',
    soundPath: '/anechoic-sounds/legacy/P2_Edited_shorterversion_32k.wav',
    tags: ['monologue'],
    active: true,
  },
  {
    id: 'Conversation4-56',
    category: 'Soundscapes',
    name: 'Conversation 4',
    soundPath: '/anechoic-sounds/legacy/P3_Edited_shorterversion_HP_32k.wav',
    tags: ['monologue'],
    active: true,
  },
  {
    id: 'CoughandMisc-57',
    category: 'Soundscapes',
    name: 'Cough and Misc',
    soundPath: '/anechoic-sounds/legacy/Cough_and_Misc_32k.wav',
    tags: ['coughing'],
    active: true,
  },
  {
    id: 'CutleryandDishes-58',
    category: 'misc',
    name: 'Cutlery and Dishes',
    soundPath: '/anechoic-sounds/legacy/Cutlery_and_Dishes_32k.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'Cutlery-59',
    category: 'misc',
    name: 'Cutlery',
    soundPath: '/anechoic-sounds/legacy/Cutlery_01_32k.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'DadandDaughter-60',
    category: 'People',
    name: 'Dad and Daughter',
    soundPath: '/anechoic-sounds/legacy/Dad_and_Daughter_32k.wav',
    tags: ['dialog'],
    active: true,
  },
  {
    id: 'DishesandCutlerycuttingfoodonplate-61',
    category: 'Soundscapes',
    name: 'Dishes and Cutlery cutting food on plate',
    soundPath: '/anechoic-sounds/legacy/Dishes_and_Cutlery_cutting_food_on_plate_32k.wav',
    tags: ['misc'],
    active: true,
  },
  {
    id: 'DishesandMugs-62',
    category: 'Soundscapes',
    name: 'Dishes and Mugs',
    soundPath: '/anechoic-sounds/legacy/Dishes_and_Mugs_01_32k.wav',
    tags: ['misc'],
    active: true,
  },
  {
    id: 'Drumloop-63',
    category: 'Instrument',
    name: 'Drum loop',
    soundPath: '/anechoic-sounds/legacy/drum_32k.wav',
    tags: ['drums'],
    active: true,
  },
  {
    id: 'FemaleandMaledialogue-64',
    category: 'people',
    name: 'Female and Male dialogue',
    soundPath: '/anechoic-sounds/legacy/Female_and_Male_DIA_32k.wav',
    tags: ['dialog'],
    active: true,
  },
  {
    id: 'Femaledialogueenglishlowenergy-65',
    category: 'people',
    name: 'Female dialogue english low energy',
    soundPath: '/anechoic-sounds/legacy/Female_DIA_english_low_energy_32k.wav',
    tags: ['monologue'],
    active: true,
  },
  {
    id: 'Femaledialogueenglishmedenergy-66',
    category: 'people',
    name: 'Female dialogue english med energy',
    soundPath: '/anechoic-sounds/legacy/Female_DIA_english_med_energy_32k.wav',
    tags: ['monologue'],
    active: true,
  },
  {
    id: 'FoodHallAtmos-67',
    category: 'Soundscapes',
    name: 'Food Hall Atmos',
    soundPath: '/anechoic-sounds/legacy/Matholl_Atmos_32k.wav',
    tags: ['restaurant'],
    active: true,
  },
  {
    id: 'FoodHallDishesandCutlery-68',
    category: 'Soundscapes',
    name: 'Food Hall Dishes and Cutlery',
    soundPath: '/anechoic-sounds/legacy/Matholl_Dishes_and_Cutlery_32k.wav',
    tags: ['restaurant'],
    active: true,
  },
  {
    id: 'FoodHallGlassesandCups-69',
    category: 'Soundscapes',
    name: 'Food Hall Glasses and Cups',
    soundPath: '/anechoic-sounds/legacy/Matholl_Glasses_and_Cups_32k.wav',
    tags: ['restaurant'],
    active: true,
  },
  {
    id: 'FoodHallMurmur-70',
    category: 'Soundscapes',
    name: 'Food Hall Murmur',
    soundPath: '/anechoic-sounds/legacy/Matholl_Walla_32k.wav',
    tags: ['restaurant'],
    active: true,
  },
  {
    id: 'FoodHallPhone-71',
    category: 'Soundscapes',
    name: 'Food Hall Phone',
    soundPath: '/anechoic-sounds/legacy/Matholl_Phone_32k.wav',
    tags: ['restaurant'],
    active: true,
  },
  {
    id: 'FoodHallScene-72',
    category: 'Soundscapes',
    name: 'Food Hall Scene',
    soundPath: '/anechoic-sounds/legacy/Matholl_REF_32k.wav',
    tags: ['restaurant'],
    active: true,
  },
  {
    id: 'FoodHallSteps-73',
    category: 'Soundscapes',
    name: 'Food Hall Steps',
    soundPath: '/anechoic-sounds/legacy/Matholl_Steps_32k.wav',
    tags: ['restaurant', 'walking'],
    active: true,
  },
  {
    id: 'Glasses-74',
    category: 'Misc',
    name: 'Glasses',
    soundPath: '/anechoic-sounds/legacy/Glasses_32k.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'GroupBoardMeeting-75',
    category: 'people',
    name: 'Group Board Meeting',
    soundPath: '/anechoic-sounds/legacy/Group_Board_Meeting_32k.wav',
    tags: ['group'],
    active: true,
  },
  {
    id: 'GroupdialogueIcelandicquieterbackground-76',
    category: 'people',
    name: 'Group dialogue Icelandic quieter background',
    soundPath: '/anechoic-sounds/legacy/Group_DIA_low_energy_background_32k.wav',
    tags: ['group'],
    active: true,
  },
  {
    id: 'GroupdialogueIcelandicquieterv01-77',
    category: 'people',
    name: 'Group dialogue Icelandic quieter v01 ',
    soundPath: '/anechoic-sounds/legacy/Group_DIA_Low_Energy_v01__32k.wav',
    tags: ['group'],
    active: true,
  },
  {
    id: 'GroupdialogueIcelandicquieterv02-78',
    category: 'people',
    name: 'Group dialogue Icelandic quieter v02',
    soundPath: '/anechoic-sounds/legacy/Group_DIA_Low_Energy_v02_32k.wav',
    tags: ['group'],
    active: true,
  },
  {
    id: 'GroupdialogueIcelandicv01-79',
    category: 'people',
    name: 'Group dialogue Icelandic v01',
    soundPath: '/anechoic-sounds/legacy/Group_DIA_Hi_Energy_v01_32k.wav',
    tags: ['group'],
    active: true,
  },
  {
    id: 'GroupdialogueIcelandicv02-80',
    category: 'people',
    name: 'Group dialogue Icelandic v02',
    soundPath: '/anechoic-sounds/legacy/Group_DIA_Hi_Energy_v02_32k.wav',
    tags: ['group'],
    active: true,
  },
  {
    id: 'Groupdialoguewomenlowenergybackground-81',
    category: 'people',
    name: 'Group dialogue women low energy background',
    soundPath: '/anechoic-sounds/legacy/Group_DIA_women_low_energy_background_32k.wav',
    tags: ['group'],
    active: true,
  },
  {
    id: 'Grouplaughter-82',
    category: 'people',
    name: 'Group laughter',
    soundPath: '/anechoic-sounds/legacy/Group_laughter_32k.wav',
    tags: ['group'],
    active: true,
  },
  {
    id: 'HouseMusic-83',
    category: 'Music',
    name: 'House Music',
    soundPath: '/anechoic-sounds/legacy/House_Music_32k.wav',
    tags: ['House'],
    active: true,
  },
  {
    id: 'iPhone-84',
    category: 'Misc',
    name: 'iPhone',
    soundPath: '/anechoic-sounds/legacy/iPhone_32k.wav',
    tags: ['phone'],
    active: true,
  },
  {
    id: 'Jazz-85',
    category: 'Music',
    name: 'Jazz',
    soundPath: '/anechoic-sounds/legacy/jazz3.wav',
    tags: ['Jazz'],
    active: true,
  },
  {
    id: 'Keyboard02-86',
    category: 'Misc',
    name: 'Keyboard 02',
    soundPath: '/anechoic-sounds/legacy/Keyboard_02_32k.wav',
    tags: ['office'],
    active: true,
  },
  {
    id: 'KeyboardandMouse-87',
    category: 'Misc',
    name: 'Keyboard and Mouse',
    soundPath: '/anechoic-sounds/legacy/Keyboard_and_Mouse_32k.wav',
    tags: ['Office'],
    active: true,
  },
  {
    id: 'Keyboardv01-88',
    category: 'Misc',
    name: 'Keyboard v01',
    soundPath: '/anechoic-sounds/legacy/Keyboard_v01_32k.wav',
    tags: ['Office'],
    active: true,
  },
  {
    id: 'LaptopBag-89',
    category: 'Misc',
    name: 'Laptop Bag',
    soundPath: '/anechoic-sounds/legacy/Laptop_Bag_32k.wav',
    tags: ['Office'],
    active: true,
  },
  {
    id: 'LaptopFan-90',
    category: 'Misc',
    name: 'Laptop Fan',
    soundPath: '/anechoic-sounds/legacy/Laptop_Fan_32k.wav',
    tags: ['Office'],
    active: true,
  },
  {
    id: 'LaptopKeyboardandMouse-91',
    category: 'Misc',
    name: 'Laptop Keyboard and Mouse',
    soundPath: '/anechoic-sounds/legacy/Laptop_Keyboard_and_Mouse_32k.wav',
    tags: ['Office'],
    active: true,
  },
  {
    id: 'LectureHallAtmos-92',
    category: 'Soundscapes',
    name: 'Lecture Hall Atmos',
    soundPath: '/anechoic-sounds/legacy/Lecture_Hall_Atmos_32k.wav',
    tags: ['lecture hall'],
    active: true,
  },
  {
    id: 'LectureHallBagsandPen-93',
    category: 'Soundscapes',
    name: 'Lecture Hall Bags and Pen ',
    soundPath: '/anechoic-sounds/legacy/Lecture_Hall_Bags_and_Pen__32k.wav',
    tags: ['lecture hall'],
    active: true,
  },
  {
    id: 'LectureHallBodysounds-94',
    category: 'Soundscapes',
    name: 'Lecture Hall Body sounds',
    soundPath: '/anechoic-sounds/legacy/Lecture_Hall_Body_sounds_32k.wav',
    tags: ['lecture hall'],
    active: true,
  },
  {
    id: 'LectureHallKeyboardandmouse-95',
    category: 'Soundscapes',
    name: 'Lecture Hall Keyboard and mouse',
    soundPath: '/anechoic-sounds/legacy/Lecture_Hall_Keyboard_and_mouse_32k.wav',
    tags: ['lecture hall'],
    active: true,
  },
  {
    id: 'LectureHallPhone-96',
    category: 'Soundscapes',
    name: 'Lecture Hall Phone',
    soundPath: '/anechoic-sounds/legacy/Lecture_Hall_Phone_32k.wav',
    tags: ['lecture hall'],
    active: true,
  },
  {
    id: 'LectureHallScene-97',
    category: 'Soundscapes',
    name: 'Lecture Hall Scene',
    soundPath: '/anechoic-sounds/legacy/Lecture_Hall_REF_32k.wav',
    tags: ['lecture hall'],
    active: true,
  },
  {
    id: 'LectureHallSteps-98',
    category: 'Soundscapes',
    name: 'Lecture Hall Steps',
    soundPath: '/anechoic-sounds/legacy/Lecture_Hall_Steps_32k.wav',
    tags: ['lecture hall'],
    active: true,
  },
  {
    id: 'Lecturer-99',
    category: 'Soundscapes',
    name: 'Lecturer',
    soundPath: '/anechoic-sounds/legacy/Lecturer_32k.wav',
    tags: ['lecture hall', 'monologue'],
    active: true,
  },
  {
    id: 'LoFiHipHopLoop-100',
    category: 'Music',
    name: 'Lo Fi Hip Hop Loop',
    soundPath: '/anechoic-sounds/legacy/Lo_Fi_Hip_Hop_Loop_32k.wav',
    tags: ['Hip Hop'],
    active: true,
  },
  {
    id: 'MaledialogueQuieter-101',
    category: 'people',
    name: 'Male dialogue Quieter',
    soundPath: '/anechoic-sounds/legacy/Male_DIA_Low_Energy_32k.wav',
    tags: ['dialog'],
    active: true,
  },
  {
    id: 'Maledialoguev01-102',
    category: 'people',
    name: 'Male dialogue v01',
    soundPath: '/anechoic-sounds/legacy/Male_DIA_v01_32k.wav',
    tags: ['dialog'],
    active: true,
  },
  {
    id: 'Manspeechv01-103',
    category: 'people',
    name: 'Man speech v01',
    soundPath: '/anechoic-sounds/legacy/Carter_v01_32k.wav',
    tags: ['monologue'],
    active: true,
  },
  {
    id: 'Manspeechv02-104',
    category: 'people',
    name: 'Man speech v02',
    soundPath: '/anechoic-sounds/legacy/Carter_v02_32k.wav',
    tags: ['monologue'],
    active: true,
  },
  {
    id: 'Manspeechv03-105',
    category: 'people',
    name: 'Man speech v03',
    soundPath: '/anechoic-sounds/legacy/Carter_v03_32k.wav',
    tags: ['monologue'],
    active: true,
  },
  {
    id: 'MobilePhone-106',
    category: 'misc',
    name: 'Mobile Phone',
    soundPath: '/anechoic-sounds/legacy/samsung_phone_32k.wav',
    tags: ['office'],
    active: true,
  },
  {
    id: 'Mouse01-107',
    category: 'misc',
    name: 'Mouse 01',
    soundPath: '/anechoic-sounds/legacy/Mouse_01_32k.wav',
    tags: ['Office'],
    active: true,
  },
  {
    id: 'Mouse02-108',
    category: 'misc',
    name: 'Mouse 02',
    soundPath: '/anechoic-sounds/legacy/Mouse_02_32k.wav',
    tags: ['Office'],
    active: true,
  },
  {
    id: 'Mugstirwithteaspoon-109',
    category: 'misc',
    name: 'Mug stir with teaspoon',
    soundPath: '/anechoic-sounds/legacy/Mug_stir_with_teaspoon_32k.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'Mugsclinking-110',
    category: 'misc',
    name: 'Mugs clinking',
    soundPath: '/anechoic-sounds/legacy/Mugs_clinking_32k.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'Mugsontableandpouring-111',
    category: 'misc',
    name: 'Mugs on table and pouring',
    soundPath: '/anechoic-sounds/legacy/Mugs_on_table_and_pouring_32k.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'Mugs-112',
    category: 'misc',
    name: 'Mugs',
    soundPath: '/anechoic-sounds/legacy/Mugs_32k.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'Notebook-113',
    category: 'misc',
    name: 'Notebook',
    soundPath: '/anechoic-sounds/legacy/Notebook_32k.wav',
    tags: ['Office'],
    active: true,
  },
  {
    id: 'OfficeAtmos-114',
    category: 'Soundscapes',
    name: 'Office Atmos',
    soundPath: '/anechoic-sounds/legacy/Office_Atmos_32k.wav',
    tags: ['office'],
    active: true,
  },
  {
    id: 'OfficeChairandPhone-115',
    category: 'Soundscapes',
    name: 'Office Chair and Phone',
    soundPath: '/anechoic-sounds/legacy/Office_Chair_and_Phone_32k.wav',
    tags: ['office'],
    active: true,
  },
  {
    id: 'OfficeChair-116',
    category: 'Soundscapes',
    name: 'Office Chair',
    soundPath: '/anechoic-sounds/legacy/Office_Chair_32k.wav',
    tags: ['office'],
    active: true,
  },
  {
    id: 'OfficeCoffeemachine-117',
    category: 'Soundscapes',
    name: 'Office Coffee machine',
    soundPath: '/anechoic-sounds/legacy/Office_Coffeemachine_32k.wav',
    tags: ['office'],
    active: true,
  },
  {
    id: 'OfficeDialogue-118',
    category: 'Soundscapes',
    name: 'Office Dialogue',
    soundPath: '/anechoic-sounds/legacy/Office_DIA_32k.wav',
    tags: ['office'],
    active: true,
  },
  {
    id: 'OfficeKeyboardandmouse-119',
    category: 'Soundscapes',
    name: 'Office Keyboard and mouse',
    soundPath: '/anechoic-sounds/legacy/Office_Keyboard_and_mouse_32k.wav',
    tags: ['office'],
    active: true,
  },
  {
    id: 'OfficeMurmur-120',
    category: 'Soundscapes',
    name: 'Office Murmur',
    soundPath: '/anechoic-sounds/legacy/Office_walla_32k.wav',
    tags: ['office'],
    active: true,
  },
  {
    id: 'OfficeScene-121',
    category: 'Soundscapes',
    name: 'Office Scene',
    soundPath: '/anechoic-sounds/legacy/Office_REF_32k.wav',
    tags: ['office'],
    active: true,
  },
  {
    id: 'OfficeSteps-122',
    category: 'Soundscapes',
    name: 'Office Steps',
    soundPath: '/anechoic-sounds/legacy/Office_steps_32k.wav',
    tags: ['office', 'walking'],
    active: true,
  },
  {
    id: 'PencilCase-123',
    category: 'misc',
    name: 'Pencil Case',
    soundPath: '/anechoic-sounds/legacy/Pencil_Case_32k.wav',
    tags: ['Office'],
    active: true,
  },
  {
    id: 'SchoolBagandNotebook-124',
    category: 'misc',
    name: 'School Bag and Notebook',
    soundPath: '/anechoic-sounds/legacy/School_Bag_and_Notebook_32k.wav',
    tags: ['Office'],
    active: true,
  },
  {
    id: 'Speechwoman-125',
    category: 'people',
    name: 'Speech woman',
    soundPath: '/anechoic-sounds/legacy/S5_32k.wav',
    tags: ['monologue'],
    active: true,
  },
  {
    id: 'StepsHeelsonWoodFloor-126',
    category: 'Soundscapes',
    name: 'Steps Heels on Wood Floor',
    soundPath: '/anechoic-sounds/legacy/Steps_Heels_on_Wood_Floor_32k.wav',
    tags: ['walking'],
    active: true,
  },
  {
    id: 'StepsSlippersonVinylFloor-127',
    category: 'Soundscapes',
    name: 'Steps Slippers on Vinyl Floor',
    soundPath: '/anechoic-sounds/legacy/Steps_Slippers_on_Vinyl_Floor_32k.wav',
    tags: ['walking'],
    active: true,
  },
  {
    id: 'StepsSneakerVinylFloor-128',
    category: 'Soundscapes',
    name: 'Steps Sneaker Vinyl Floor',
    soundPath: '/anechoic-sounds/legacy/Steps_Sneaker_Vinyl_Floor_32k.wav',
    tags: ['walking'],
    active: true,
  },
  {
    id: 'StepsSneakersonWoodFloorv02-129',
    category: 'Soundscapes',
    name: 'Steps Sneakers on Wood Floor v02',
    soundPath: '/anechoic-sounds/legacy/Steps_Sneakers_on_Wood_Floor_v02_32k.wav',
    tags: ['walking'],
    active: true,
  },
  {
    id: 'StepsSneakersonWoodFloor-130',
    category: 'Soundscapes',
    name: 'Steps Sneakers on Wood Floor',
    soundPath: '/anechoic-sounds/legacy/Steps_Sneakers_on_Wood_Floor_32k.wav',
    tags: ['walking'],
    active: true,
  },
  {
    id: 'StepsStepShoesonWoodfloor-131',
    category: 'Soundscapes',
    name: 'Steps Step Shoes on Wood floor',
    soundPath: '/anechoic-sounds/legacy/Steps_Step_Shoes_on_Wood_floor_32k.wav',
    tags: ['walking'],
    active: true,
  },
  {
    id: 'StepsSuedeShoesonWoodFloor-132',
    category: 'Soundscapes',
    name: 'Steps Suede Shoes on Wood Floor',
    soundPath: '/anechoic-sounds/legacy/Steps_Suede_Shoes_on_Wood_Floor_32k.wav',
    tags: ['walking'],
    active: true,
  },
  {
    id: 'Teacherandstudentdialogue-133',
    category: 'People',
    name: 'Teacher and student dialogue',
    soundPath: '/anechoic-sounds/legacy/Techer_and_student_DIA_32k.wav',
    tags: ['monologue'],
    active: true,
  },
  {
    id: 'Teacher-134',
    category: 'People',
    name: 'Teacher',
    soundPath: '/anechoic-sounds/legacy/Teacher_32k.wav',
    tags: ['monologue'],
    active: true,
  },
  {
    id: 'Typing-135',
    category: 'Misc',
    name: 'Typing',
    soundPath: '/anechoic-sounds/legacy/S3.wav',
    tags: ['Office'],
    active: true,
  },
  {
    id: 'Wineglass-136',
    category: 'Misc',
    name: 'Wineglass',
    soundPath: '/anechoic-sounds/legacy/Wineglass_32k.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'WomanAv01-137',
    category: 'people',
    name: 'Woman A v01',
    soundPath: '/anechoic-sounds/legacy/Maria_v01_32k.wav',
    tags: ['monologue'],
    active: true,
  },
  {
    id: 'WomanAv02-138',
    category: 'People',
    name: 'Woman A v02',
    soundPath: '/anechoic-sounds/legacy/Maria_v02_32k.wav',
    tags: ['monologue'],
    active: true,
  },
  {
    id: 'WomanAv03-139',
    category: 'People',
    name: 'Woman A v03',
    soundPath: '/anechoic-sounds/legacy/Maria_v03_32k.wav',
    tags: ['monologue'],
    active: true,
  },
  {
    id: 'WomanBv01-140',
    category: 'People',
    name: 'Woman B v01',
    soundPath: '/anechoic-sounds/legacy/Rex_v01_32k.wav',
    tags: ['monologue'],
    active: true,
  },
  {
    id: 'WomanBv02-141',
    category: 'People',
    name: 'Woman B v02',
    soundPath: '/anechoic-sounds/legacy/Rex_v02_32k.wav',
    tags: ['monologue'],
    active: true,
  },
  {
    id: 'WomanBv03-142',
    category: 'People',
    name: 'Woman B v03',
    soundPath: '/anechoic-sounds/legacy/Rex_v03_32k.wav',
    tags: ['monologue'],
    active: true,
  },
  {
    id: 'WoodChaironWoodFloorSitting-143',
    category: 'Misc',
    name: 'Wood Chair on Wood Floor Sitting',
    soundPath: '/anechoic-sounds/legacy/Wood_Chair_on_Wood_Floor_Sitting_32k.wav',
    tags: ['Furniture'],
    active: true,
  },
  {
    id: 'WoodChaironWoodFloor-144',
    category: 'Misc',
    name: 'Wood Chair on Wood Floor',
    soundPath: '/anechoic-sounds/legacy/Wood_Chair_on_Wood_Floor_32k.wav',
    tags: ['Furniture'],
    active: true,
  },
];

const NEW_SOUNDS: LibrarySound[] = [
  {
    id: 'Clapsinglev03-150',
    category: 'Clap',
    name: 'Clap single v03',
    soundPath: '/anechoic-sounds/v2/Claps/Clap_06.wav',
    tags: [],
    active: true,
  },
  {
    id: 'Clapgroupv04-151',
    category: 'Clap',
    name: 'Clap group v04',
    soundPath: '/anechoic-sounds/v2/Claps/Clap_Crowd_01.wav',
    tags: [],
    active: true,
  },

  {
    id: 'Claptwopeoplev01-153',
    category: 'Clap',
    name: 'Clap two people v01',
    soundPath: '/anechoic-sounds/v2/Claps/Clap_Two_Persons.wav',
    tags: [],
    active: true,
  },
  {
    id: 'ElectricDeskDown-154',
    category: 'Soundscapes',
    name: 'Electric Desk Down',
    soundPath: '/anechoic-sounds/v2/Electric_Desk/Electric_Desk_Down.wav',
    tags: ['office'],
    active: true,
  },

  {
    id: 'ElectricDeskUp-157',
    category: 'Soundscapes',
    name: 'Electric Desk Up',
    soundPath: '/anechoic-sounds/v2/Electric_Desk/Electric_Desk_Up.wav',
    tags: ['office'],
    active: true,
  },
  {
    id: '3DPrinter-158',
    category: 'Soundscapes',
    name: '3D Printer',
    soundPath: '/anechoic-sounds/v2/Kitchen_Appliences/3D_printer.wav',
    tags: ['office'],
    active: true,
  },
  {
    id: 'Blender-159',
    category: 'Soundscapes',
    name: 'Blender',
    soundPath: '/anechoic-sounds/v2/Kitchen_Appliences/Blender_Nutribullet.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'CoffeeGrinderandBeans-160',
    category: 'Soundscapes',
    name: 'Coffee Grinder and Beans',
    soundPath: '/anechoic-sounds/v2/Kitchen_Appliences/Coffee_grinder_and_beans.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'Drill-161',
    category: 'Soundscapes',
    name: 'Drill',
    soundPath: '/anechoic-sounds/v2/Kitchen_Appliences/Drill.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'DryingOven-162',
    category: 'Soundscapes',
    name: 'Drying Oven',
    soundPath: '/anechoic-sounds/v2/Kitchen_Appliences/Drying_Oven.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'FloorFanMoving-163',
    category: 'Soundscapes',
    name: 'Floor Fan Moving',
    soundPath: '/anechoic-sounds/v2/Kitchen_Appliences/Floor_Fan_movement_medium.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'FloorFanStillFast-164',
    category: 'Soundscapes',
    name: 'Floor Fan Still Fast',
    soundPath: '/anechoic-sounds/v2/Kitchen_Appliences/Floor_Fan_still_fast.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'FloorFanStillSlow-165',
    category: 'Soundscapes',
    name: 'Floor Fan Still Slow',
    soundPath: '/anechoic-sounds/v2/Kitchen_Appliences/Floor_Fan_still_slow.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'KettleBoiling-166',
    category: 'Soundscapes',
    name: 'Kettle Boiling',
    soundPath: '/anechoic-sounds/v2/Kitchen_Appliences/Kettle_boiling.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'OvenTicking-167',
    category: 'Soundscapes',
    name: 'Oven Ticking',
    soundPath: '/anechoic-sounds/v2/Kitchen_Appliences/Oven_ticking.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'Sodastream-168',
    category: 'Soundscapes',
    name: 'Sodastream',
    soundPath: '/anechoic-sounds/v2/Kitchen_Appliences/Sodastream.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'VacuumCleaner-169',
    category: 'Soundscapes',
    name: 'Vacuum Cleaner',
    soundPath: '/anechoic-sounds/v2/Kitchen_Appliences/Vacuum.wav',
    tags: ['Kitchen'],
    active: true,
  },
  {
    id: 'CrowdIcelandicLarge-170',
    category: 'People',
    name: 'Crowd Icelandic Large',
    soundPath: '/anechoic-sounds/v2/Misc/Crowd_Large.wav',
    tags: ['group'],
    active: true,
  },
  {
    id: 'CrowdIcelandicsmallv01-171',
    category: 'People',
    name: 'Crowd Icelandic small v01',
    soundPath: '/anechoic-sounds/v2/Misc/Crowd_Small_01.wav',
    tags: ['group'],
    active: true,
  },

  {
    id: 'CrowdIcelandicsmallv03-173',
    category: 'People',
    name: 'Crowd Icelandic small v03',
    soundPath: '/anechoic-sounds/v2/Misc/Crowd_Small_03.wav',
    tags: ['group'],
    active: true,
  },
  {
    id: 'CrowdWomenlaughing-174',
    category: 'People',
    name: 'Crowd Women laughing',
    soundPath: '/anechoic-sounds/v2/Misc/Crowd_Women_laughing.wav',
    tags: ['group'],
    active: true,
  },
  {
    id: 'PinkNoise-175',
    category: 'Noise Signals',
    name: 'Pink Noise',
    soundPath: '/anechoic-sounds/v2/Noise_Signals/Pink_Noise.wav',
    tags: ['pink noise'],
    active: true,
  },
  {
    id: 'PinkNoisefiltered1000Hz-176',
    category: 'Noise Signals',
    name: 'Pink Noise filtered 1000 Hz',
    soundPath: '/anechoic-sounds/v2/Noise_Signals/Pink_Noise_filtered_1000_Hz.wav',
    tags: ['pink noise'],
    active: true,
  },
  {
    id: 'PinkNoisefiltered125Hz-177',
    category: 'Noise Signals',
    name: 'Pink Noise filtered 125 Hz',
    soundPath: '/anechoic-sounds/v2/Noise_Signals/Pink_Noise_filtered_125_Hz.wav',
    tags: ['pink noise'],
    active: true,
  },
  {
    id: 'PinkNoisefiltered2000Hz-178',
    category: 'Noise Signals',
    name: 'Pink Noise filtered 2000 Hz',
    soundPath: '/anechoic-sounds/v2/Noise_Signals/Pink_Noise_filtered_2000_Hz.wav',
    tags: ['pink noise'],
    active: true,
  },
  {
    id: 'PinkNoisefiltered250Hz-179',
    category: 'Noise Signals',
    name: 'Pink Noise filtered 250 Hz',
    soundPath: '/anechoic-sounds/v2/Noise_Signals/Pink_Noise_filtered_250_Hz.wav',
    tags: ['pink noise'],
    active: true,
  },
  {
    id: 'PinkNoisefiltered4000Hz-180',
    category: 'Noise Signals',
    name: 'Pink Noise filtered 4000 Hz',
    soundPath: '/anechoic-sounds/v2/Noise_Signals/Pink_Noise_filtered_4000_Hz.wav',
    tags: ['pink noise'],
    active: true,
  },
  {
    id: 'PinkNoisefiltered500Hz-181',
    category: 'Noise Signals',
    name: 'Pink Noise filtered 500 Hz',
    soundPath: '/anechoic-sounds/v2/Noise_Signals/Pink_Noise_filtered_500_Hz.wav',
    tags: ['pink noise'],
    active: true,
  },
  {
    id: 'PinkNoisefiltered63Hz-182',
    category: 'Noise Signals',
    name: 'Pink Noise filtered 63 Hz',
    soundPath: '/anechoic-sounds/v2/Noise_Signals/Pink_Noise_filtered_63_Hz.wav',
    tags: ['pink noise'],
    active: true,
  },
  {
    id: 'PinkNoisefiltered8000Hz-183',
    category: 'Noise Signals',
    name: 'Pink Noise filtered 8000 Hz',
    soundPath: '/anechoic-sounds/v2/Noise_Signals/Pink_Noise_filtered_8000_Hz.wav',
    tags: ['pink noise'],
    active: true,
  },
  {
    id: 'WhiteNoise-184',
    category: 'Noise Signals',
    name: 'White Noise',
    soundPath: '/anechoic-sounds/v2/Noise_Signals/White_Noise.wav',
    tags: ['white noise'],
    active: true,
  },
  {
    id: 'WhiteNoiseFiltered1000Hz-185',
    category: 'Noise Signals',
    name: 'White Noise Filtered 1000 Hz',
    soundPath: '/anechoic-sounds/v2/Noise_Signals/White_Noise_filtered_1000_Hz.wav',
    tags: ['white noise'],
    active: true,
  },
  {
    id: 'WhiteNoiseFiltered125Hz-186',
    category: 'Noise Signals',
    name: 'White Noise Filtered 125 Hz',
    soundPath: '/anechoic-sounds/v2/Noise_Signals/White_Noise_filtered_125_Hz.wav',
    tags: ['white noise'],
    active: true,
  },
  {
    id: 'WhiteNoiseFiltered2000Hz-187',
    category: 'Noise Signals',
    name: 'White Noise Filtered 2000 Hz',
    soundPath: '/anechoic-sounds/v2/Noise_Signals/White_Noise_filtered_2000_Hz.wav',
    tags: ['white noise'],
    active: true,
  },
  {
    id: 'WhiteNoiseFiltered250Hz-188',
    category: 'Noise Signals',
    name: 'White Noise Filtered 250 Hz',
    soundPath: '/anechoic-sounds/v2/Noise_Signals/White_Noise_filtered_250_Hz.wav',
    tags: ['white noise'],
    active: true,
  },
  {
    id: 'WhiteNoiseFiltered4000Hz-189',
    category: 'Noise Signals',
    name: 'White Noise Filtered 4000 Hz',
    soundPath: '/anechoic-sounds/v2/Noise_Signals/White_Noise_filtered_4000_Hz.wav',
    tags: ['white noise'],
    active: true,
  },
  {
    id: 'WhiteNoiseFiltered500Hz-190',
    category: 'Noise Signals',
    name: 'White Noise Filtered 500 Hz',
    soundPath: '/anechoic-sounds/v2/Noise_Signals/White_Noise_filtered_500_Hz.wav',
    tags: ['white noise'],
    active: true,
  },
  {
    id: 'WhiteNoiseFiltered63Hz-191',
    category: 'Noise Signals',
    name: 'White Noise Filtered 63 Hz',
    soundPath: '/anechoic-sounds/v2/Noise_Signals/White_Noise_filtered_63Hz.wav',
    tags: ['white noise'],
    active: true,
  },
  {
    id: 'WhiteNoiseFiltered8000Hz-192',
    category: 'Noise Signals',
    name: 'White Noise Filtered 8000 Hz',
    soundPath: '/anechoic-sounds/v2/Noise_Signals/White_Noise_filtered_8000_Hz.wav',
    tags: ['white noise'],
    active: true,
  },
  {
    id: 'ChoirIcelandic:Hverásérfegraföðurland-193',
    category: 'Music',
    name: 'Choir Icelandic: Hver á sér fegra föðurland',
    soundPath: '/anechoic-sounds/v2/Music/choir/Choir-Hver_a_ser_fegra_fodurland.wav',
    tags: ['Choir'],
    active: true,
  },
  {
    id: 'ChoirIcelandic:ÍslandFarsældaFrón-194',
    category: 'Music',
    name: 'Choir Icelandic: Ísland Farsælda Frón',
    soundPath: '/anechoic-sounds/v2/Music/choir/Choir-Island_faraeldar_fron.wav',
    tags: ['Choir'],
    active: true,
  },
  {
    id: 'ChoirMusicalScale-195',
    category: 'Music',
    name: 'Choir Musical Scale',
    soundPath: '/anechoic-sounds/v2/Music/choir/Choir_Musical_Scale.wav',
    tags: ['Choir'],
    active: true,
  },
  {
    id: 'ChoirOneNote-196',
    category: 'Music',
    name: 'Choir One Note',
    soundPath: '/anechoic-sounds/v2/Music/choir/Choir_one_note.wav',
    tags: ['Choir'],
    active: true,
  },

  {
    id: 'BassFunk-205',
    category: 'Instrument',
    name: 'Bass Funk',
    soundPath: '/anechoic-sounds/v2/Music/funk/Bass_Funk.wav',
    tags: ['Bass'],
    active: true,
  },
  {
    id: 'DrumsFunk-206',
    category: 'Instrument',
    name: 'Drums Funk',
    soundPath: '/anechoic-sounds/v2/Music/funk/Drums_Funk.wav',
    tags: ['Drums'],
    active: true,
  },
  {
    id: 'FunkMusic-207',
    category: 'Music',
    name: 'Funk Music',
    soundPath: '/anechoic-sounds/v2/Music/funk/Funk_Music_v06.wav',
    tags: ['Func'],
    active: true,
  },
  {
    id: 'GuitarFunkv01-208',
    category: 'Instrument',
    name: 'Guitar Funk v01',
    soundPath: '/anechoic-sounds/v2/Music/funk/Guitar_01_Funk.wav',
    tags: ['Guitar'],
    active: true,
  },
  {
    id: 'GuitarFunkv02-209',
    category: 'Instrument',
    name: 'Guitar Funk v02',
    soundPath: '/anechoic-sounds/v2/Music/funk/Guitar_02_Funk.wav',
    tags: ['Guitar'],
    active: true,
  },

  {
    id: 'BassJazz-213',
    category: 'Instrument',
    name: 'Bass Jazz',
    soundPath: '/anechoic-sounds/v2/Music/jazz/Bass_Jazz.wav',
    tags: ['Bass'],
    active: true,
  },
  {
    id: 'GuitarJazz-214',
    category: 'Instrument',
    name: 'Guitar Jazz',
    soundPath: '/anechoic-sounds/v2/Music/jazz/Guitar_Jazz.wav',
    tags: ['Guitar'],
    active: true,
  },

  {
    id: 'BassRock-217',
    category: 'Instrument',
    name: 'Bass Rock',
    soundPath: '/anechoic-sounds/v2/Music/rock/Bass_Rock.wav',
    tags: ['Bass'],
    active: true,
  },
  {
    id: 'GuitarRockv01-218',
    category: 'Instrument',
    name: 'Guitar Rock v01',
    soundPath: '/anechoic-sounds/v2/Music/rock/Guitar_01_Rock.wav',
    tags: ['Guitar'],
    active: true,
  },
  {
    id: 'GuitarRockv02-219',
    category: 'Instrument',
    name: 'Guitar Rock v02',
    soundPath: '/anechoic-sounds/v2/Music/rock/Guitar_02_Rock.wav',
    tags: ['Guitar'],
    active: false,
  },

  {
    id: 'AcousticGuitarv01-225',
    category: 'Instrument',
    name: 'Acoustic Guitar Fingerpicking',
    soundPath: '/anechoic-sounds/v2/Music/strings/Accoustic_Guitar_01.wav',
    tags: ['Guitar'],
    active: true,
  },
  {
    id: 'AcousticGuitarv02-226',
    category: 'Instrument',
    name: 'Acoustic Guitar v02',
    soundPath: '/anechoic-sounds/v2/Music/strings/Accoustic_Guitar_02.wav',
    tags: ['Guitar'],
    active: false,
  },
  {
    id: 'Cellov01-227',
    category: 'Instrument',
    name: 'Cello v01',
    soundPath: '/anechoic-sounds/v2/Music/strings/Chello_01.wav',
    tags: ['Cello'],
    active: true,
  },
  {
    id: 'Cellov02-228',
    category: 'Instrument',
    name: 'Cello v02',
    soundPath: '/anechoic-sounds/v2/Music/strings/Chello_02.wav',
    tags: ['Cello'],
    active: true,
  },
  {
    id: 'Cellov03-229',
    category: 'Instrument',
    name: 'Cello v03',
    soundPath: '/anechoic-sounds/v2/Music/strings/Chello_03.wav',
    tags: ['Cello'],
    active: true,
  },
  {
    id: 'AltoSaxophone-230',
    category: 'Instrument',
    name: 'Alto Saxophone',
    soundPath: '/anechoic-sounds/v2/Music/woodwinds/Alto_Sax.wav',
    tags: ['Woodwinds'],
    active: true,
  },
  {
    id: 'BassClarinet-231',
    category: 'Instrument',
    name: 'Bass Clarinet',
    soundPath: '/anechoic-sounds/v2/Music/woodwinds/Bassklarinet.wav',
    tags: ['Woodwinds'],
    active: true,
  },
  {
    id: 'ConcertFlutev01-232',
    category: 'Instrument',
    name: 'Concert Flute v01',
    soundPath: '/anechoic-sounds/v2/Music/woodwinds/Concert_Flute_01.wav',
    tags: ['Woodwinds'],
    active: true,
  },
  {
    id: 'ConcertFlutev02-233',
    category: 'Instrument',
    name: 'Concert Flute v02',
    soundPath: '/anechoic-sounds/v2/Music/woodwinds/Concert_Flute_02.wav',
    tags: ['Woodwinds'],
    active: true,
  },
  {
    id: 'ConcertFlutev03-234',
    category: 'Instrument',
    name: 'Concert Flute v03',
    soundPath: '/anechoic-sounds/v2/Music/woodwinds/Concert_Flute_03.wav',
    tags: ['Woodwinds'],
    active: true,
  },
  {
    id: 'Clarinetv01-235',
    category: 'Instrument',
    name: 'Clarinet v01',
    soundPath: '/anechoic-sounds/v2/Music/woodwinds/Klarinet_01.wav',
    tags: ['Woodwinds'],
    active: true,
  },
  {
    id: 'Clarinetv02-236',
    category: 'Instrument',
    name: 'Clarinet v02',
    soundPath: '/anechoic-sounds/v2/Music/woodwinds/Klarinet_02.wav',
    tags: ['Woodwinds'],
    active: true,
  },
  {
    id: 'PiccoloFlutev01-237',
    category: 'Instrument',
    name: 'Piccolo Flute v01',
    soundPath: '/anechoic-sounds/v2/Music/woodwinds/Piccolo_flute_01.wav',
    tags: ['Woodwinds'],
    active: true,
  },
  {
    id: 'PiccoloFlutev02-238',
    category: 'Instrument',
    name: 'Piccolo Flute v02',
    soundPath: '/anechoic-sounds/v2/Music/woodwinds/Piccolo_flute_02.wav',
    tags: ['Woodwinds'],
    active: true,
  },
  {
    id: 'SopranoSaxophonev01-239',
    category: 'Instrument',
    name: 'Soprano Saxophone v01',
    soundPath: '/anechoic-sounds/v2/Music/woodwinds/Sopransax_01.wav',
    tags: ['Woodwinds'],
    active: true,
  },
  {
    id: 'SopranoSaxophonev02-240',
    category: 'Instrument',
    name: 'Soprano Saxophone v02',
    soundPath: '/anechoic-sounds/v2/Music/woodwinds/Sopransax_02.wav',
    tags: ['Woodwinds'],
    active: true,
  },
  {
    id: 'TenorSaxophonev01-241',
    category: 'Instrument',
    name: 'Tenor Saxophone v01',
    soundPath: '/anechoic-sounds/v2/Music/woodwinds/Tenor_Saxophone_01.wav',
    tags: ['Woodwinds'],
    active: true,
  },
  {
    id: 'TenorSaxophonev02-242',
    category: 'Instrument',
    name: 'Tenor Saxophone v02',
    soundPath: '/anechoic-sounds/v2/Music/woodwinds/Tenor_Saxophone_02.wav',
    tags: ['Woodwinds'],
    active: true,
  },

  {
    id: 'VentilatorinfrontofhoseSetting02-244',
    category: 'Soundscapes',
    name: 'Ventilator in front of hose Setting 02',
    soundPath:
      '/anechoic-sounds/v2/Office_Ventilator/Ventilator_in_front_of_hose/Ventilator_in_front_of_hose_setting_02.wav',
    tags: ['office'],
    active: true,
  },

  {
    id: 'VentilatornohoseSetting2-249',
    category: 'Soundscapes',
    name: 'Ventilator no hose Setting 2',
    soundPath: '/anechoic-sounds/v2/Office_Ventilator/Ventilator_no_hose/Ventilator_no_hose_setting_02.wav',
    tags: ['office'],
    active: true,
  },

  {
    id: 'VentilatornohoseFireplaceSetting-253',
    category: 'Soundscapes',
    name: 'Ventilator no hose Fireplace Setting',
    soundPath: '/anechoic-sounds/v2/Office_Ventilator/Ventilator_no_hose/Ventilator_with_hose_Fireplace_setting.wav',
    tags: ['office'],
    active: true,
  },
  {
    id: 'VentilatorwithhoseFireplacesetting-254',
    category: 'Soundscapes',
    name: 'Ventilator with hose Fireplace setting',
    soundPath: '/anechoic-sounds/v2/Office_Ventilator/Ventilator_with_hose/Ventilator_with_hose_Fireplace.wav',
    tags: ['office'],
    active: true,
  },

  {
    id: 'VentilatorwithhoseSetting2-256',
    category: 'Soundscapes',
    name: 'Ventilator with hose Setting 2',
    soundPath: '/anechoic-sounds/v2/Office_Ventilator/Ventilator_with_hose/Ventilator_with_hose_setting_02.wav',
    tags: ['office'],
    active: true,
  },

  {
    id: 'ChildsingingHappyBirthday-260',
    category: 'Music',
    name: 'Child singing Happy Birthday',
    soundPath: '/anechoic-sounds/v2/Spoken/reading/Child_Singing_Happy_Birthday.wav',
    tags: [],
    active: true,
  },
  {
    id: 'MaleDanishReadText-261',
    category: 'People',
    name: 'Male Danish Read Text',
    soundPath: '/anechoic-sounds/v2/Spoken/reading/Danish-Jasper.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'MaleFrenchReadText-262',
    category: 'People',
    name: 'Male French Read Text',
    soundPath: '/anechoic-sounds/v2/Spoken/reading/Fench-Guillaume.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'MaleGreekReadText-263',
    category: 'People',
    name: 'Male Greek Read Text',
    soundPath: '/anechoic-sounds/v2/Spoken/reading/Greek-Faedon.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'FemaleHindiReadText-264',
    category: 'People',
    name: 'Female Hindi Read Text',
    soundPath: '/anechoic-sounds/v2/Spoken/reading/Hindi-Atibhi.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'FemaleItalianReadText-265',
    category: 'People',
    name: 'Female Italian Read Text',
    soundPath: '/anechoic-sounds/v2/Spoken/reading/Italian-Alissia.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'FemaleMandarinReadText-266',
    category: 'People',
    name: 'Female Mandarin Read Text',
    soundPath: '/anechoic-sounds/v2/Spoken/reading/Mandarin-Lola.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'MaleSpanishReadText-267',
    category: 'People',
    name: 'Male Spanish Read Text',
    soundPath: '/anechoic-sounds/v2/Spoken/reading/Spanish-Hermes.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'MaleSwedishReadText-268',
    category: 'People',
    name: 'Male Swedish Read Text',
    soundPath: '/anechoic-sounds/v2/Spoken/reading/Swedish-Martin.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'MaleUKEnglishReadText-270',
    category: 'People',
    name: 'Male UK English Read Text',
    soundPath: '/anechoic-sounds/v2/Spoken/reading/UK-Guillaume.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'FemaleUSEnglishReadTextv01-271',
    category: 'People',
    name: 'Female US English Read Text v01',
    soundPath: '/anechoic-sounds/v2/Spoken/reading/US-Erin_01.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'FemaleUSEnglishReadTextv02-272',
    category: 'People',
    name: 'Female US English Read Text v02',
    soundPath: '/anechoic-sounds/v2/Spoken/reading/US-Erin_02.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'MaleUSEnglishReadText03-273',
    category: 'People',
    name: 'Male US English Read Text 03',
    soundPath: '/anechoic-sounds/v2/Spoken/reading/US-Faedon.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'MaleDanishTalking-274',
    category: 'People',
    name: 'Male Danish Talking',
    soundPath: '/anechoic-sounds/v2/Spoken/talking/Danish_talking.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'MaleFrenchTalking-275',
    category: 'People',
    name: 'Male French Talking',
    soundPath: '/anechoic-sounds/v2/Spoken/talking/French_talking.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'MaleGreekTalking-276',
    category: 'People',
    name: 'Male Greek Talking',
    soundPath: '/anechoic-sounds/v2/Spoken/talking/Greek_talking.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'HindiandTeluguDialog-277',
    category: 'People',
    name: 'Hindi and Telugu Dialog',
    soundPath: '/anechoic-sounds/v2/Spoken/talking/Hindi_and_Telugu_talking.wav',
    tags: ['Dialog'],
    active: true,
  },
  {
    id: 'FemaleMandarinTalking-278',
    category: 'People',
    name: 'Female Mandarin Talking',
    soundPath: '/anechoic-sounds/v2/Spoken/talking/Mandarin_talking.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'MaleSpanishTalking-279',
    category: 'People',
    name: 'Male Spanish Talking',
    soundPath: '/anechoic-sounds/v2/Spoken/talking/Spanish_talking.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'MaleSwedishTalking-280',
    category: 'People',
    name: 'Male Swedish Talking',
    soundPath: '/anechoic-sounds/v2/Spoken/talking/Swedish_talking.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'FemaleUSEnglishtalking-281',
    category: 'People',
    name: 'Female US English talking',
    soundPath: '/anechoic-sounds/v2/Spoken/talking/US_talking.wav',
    tags: ['Monologue'],
    active: true,
  },
  {
    id: 'FloorDrumSingleHits-282',
    category: 'Instrument',
    name: 'Floor Drum Single Hits',
    soundPath: '/anechoic-sounds/v2/Music/drums/hits/Floor_Tom_Single_Hits.wav',
    tags: ['Drums'],
    active: true,
  },
  {
    id: 'KickDrumSingleHits-283',
    category: 'Instrument',
    name: 'Kick Drum Single Hits',
    soundPath: '/anechoic-sounds/v2/Music/drums/hits/Kick_Drum_Single_Hits.wav',
    tags: ['Drums'],
    active: true,
  },
  {
    id: 'SnareDrumSingleHits-284',
    category: 'Instrument',
    name: 'Snare Drum Single Hits',
    soundPath: '/anechoic-sounds/v2/Music/drums/hits/Snare_Drum_Single_Hits.wav',
    tags: ['Drums'],
    active: true,
  },
  {
    id: 'BassSynth:AroundtheWorld(song)-285',
    category: 'Instrument',
    name: 'Bass Synth: Around the World (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Around_the_World/Bass_Synth.wav',
    tags: ['Synth'],
    active: false,
  },
  {
    id: 'Clap01:AroundtheWorld(song)-286',
    category: 'Clap',
    name: 'Clap 01: Around the World (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Around_the_World/Clap_01.wav',
    tags: [],
    active: false,
  },
  {
    id: 'Clap02:AroundtheWorld(song)-287',
    category: 'Clap',
    name: 'Clap 02: Around the World (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Around_the_World/Clap_02.wav',
    tags: [],
    active: false,
  },
  {
    id: 'HighHat01:AroundtheWorld(song)-288',
    category: 'Instrument',
    name: 'High Hat 01: Around the World (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Around_the_World/High_Hat_01.wav',
    tags: ['Drums'],
    active: false,
  },
  {
    id: 'HighHat02:AroundtheWorld(song)-289',
    category: 'Instrument',
    name: 'High Hat 02: Around the World (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Around_the_World/High_Hat_02.wav',
    tags: ['Drums'],
    active: false,
  },
  {
    id: 'HighHat03:AroundtheWorld(song)-290',
    category: 'Instrument',
    name: 'High Hat 03: Around the World (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Around_the_World/High_Hat_03.wav',
    tags: ['Drums'],
    active: false,
  },
  {
    id: 'SongbyHildur:AroundtheWorld-291',
    category: 'Music',
    name: 'Song by Hildur: Around the World',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Around_the_World/Hildur-Around_the_World.wav',
    tags: ['Pop'],
    active: true,
  },
  {
    id: 'KeysSynth01:AroundtheWorld(song)-292',
    category: 'Instrument',
    name: 'Keys Synth 01: Around the World (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Around_the_World/Keys_Synth_01.wav',
    tags: ['Synth'],
    active: true,
  },
  {
    id: 'KeysSynth02:AroundtheWorld(song)-293',
    category: 'Instrument',
    name: 'Keys Synth 02: Around the World (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Around_the_World/Keys_Synth_02.wav',
    tags: ['Synth'],
    active: true,
  },
  {
    id: 'Kick:AroundtheWorld(song)-294',
    category: 'Music',
    name: 'Kick: Around the World (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Around_the_World/KICK.wav',
    tags: ['Pop'],
    active: false,
  },
  {
    id: 'PopDrumBeat:AroundtheWorld(song)-295',
    category: 'Instrument',
    name: 'Pop Drum Beat: Around the World (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Around_the_World/Pop_Drum_Beat.wav',
    tags: ['Synth'],
    active: false,
  },
  {
    id: 'PopMusicInstrumental:AroundtheWorld(song)-296',
    category: 'Music',
    name: 'Pop Music Instrumental: Around the World (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Around_the_World/Pop_Instrumental.wav',
    tags: ['Pop'],
    active: true,
  },
  {
    id: 'PopVocals:AroundtheWorld(song)-297',
    category: 'Music',
    name: 'Pop Vocals: Around the World (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Around_the_World/Pop_Vocals.wav',
    tags: ['Pop'],
    active: true,
  },
  {
    id: 'SynthDroplet:AroundtheWorld(song)-298',
    category: 'Instrument',
    name: 'Synth Droplet: Around the World (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Around_the_World/Synth_Droplet.wav',
    tags: ['Synth'],
    active: false,
  },
  {
    id: 'BassSynth02:WhereYouAre(song)-299',
    category: 'Instrument',
    name: 'Bass Synth 02: Where You Are (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Where_You_Are/Bass_Synth_02.wav',
    tags: ['Synth'],
    active: false,
  },
  {
    id: 'BassSynth03:WhereYouAre(song)-300',
    category: 'Instrument',
    name: 'Bass Synth 03: Where You Are (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Where_You_Are/Bass_synth_03.wav',
    tags: ['Synth'],
    active: false,
  },
  {
    id: 'BrassSynth01:WhereYouAre(song)-301',
    category: 'Instrument',
    name: 'Brass Synth 01: Where You Are (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Where_You_Are/Brass_Synth_01.wav',
    tags: ['Synth'],
    active: false,
  },
  {
    id: 'BrassSynth02:WhereYouAre(song)-302',
    category: 'Instrument',
    name: 'Brass Synth 02: Where You Are (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Where_You_Are/Brass_Synth_02.wav',
    tags: ['Synth'],
    active: false,
  },
  {
    id: 'Clap:WhereyouAre(song)-303',
    category: 'Clap',
    name: 'Clap: Where you Are (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Where_You_Are/Clap_FX.wav',
    tags: [],
    active: false,
  },
  {
    id: 'FX01Synth:WhereYouAre(song)-304',
    category: 'Instrument',
    name: 'FX 01 Synth: Where You Are (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Where_You_Are/FX_Plonk_01_Synth.wav',
    tags: ['Synth'],
    active: false,
  },
  {
    id: 'FX02Synth:WhereYouAre(song)-305',
    category: 'Instrument',
    name: 'FX 02 Synth: Where You Are (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Where_You_Are/FX_Plonk_02_Synth.wav',
    tags: ['Synth'],
    active: false,
  },
  {
    id: 'HatSynth:WhereYouAre(song)-306',
    category: 'Instrument',
    name: 'Hat Synth: Where You Are (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Where_You_Are/Hat_Synth.wav',
    tags: ['Synth'],
    active: false,
  },
  {
    id: 'SongbyHildur:WhereyouAre-307',
    category: 'Music',
    name: 'Song by Hildur: Where you Are',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Where_You_Are/Hildur-Where_You_Are.wav',
    tags: ['Pop'],
    active: false,
  },
  {
    id: 'KickSynth:WhereYouAre(song)-308',
    category: 'Instrument',
    name: 'Kick Synth: Where You Are (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Where_You_Are/Kick_Synth.wav',
    tags: ['Synth'],
    active: false,
  },
  {
    id: 'PadSynth:WhereYouAre(song)-309',
    category: 'Instrument',
    name: 'Pad Synth: Where You Are (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Where_You_Are/Pad_Synth.wav',
    tags: ['Synth'],
    active: false,
  },
  {
    id: 'PopDrumBeat:WhereYouAre(song)-310',
    category: 'Instrument',
    name: 'Pop Drum Beat: Where You Are (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Where_You_Are/Pop_Drum_Beat.wav',
    tags: ['Synth'],
    active: false,
  },
  {
    id: 'PopMusicInstrumental:WhereYouAre(song)-311',
    category: 'Music',
    name: 'Pop Music Instrumental: Where You Are (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Where_You_Are/Pop_Music_Instrumental.wav',
    tags: ['Pop'],
    active: false,
  },
  {
    id: 'SnareDrumSample:WhereYouAre(song)-312',
    category: 'Instrument',
    name: 'Snare Drum Sample: Where You Are (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Where_You_Are/Snare_Drum_Sample.wav',
    tags: ['Synth'],
    active: false,
  },
  {
    id: 'WoodTomSample:WhereYouAre(song)-313',
    category: 'Instrument',
    name: 'Wood Tom Sample: Where You Are (song)',
    soundPath: '/anechoic-sounds/v2/Music/pop/Hildur-Where_You_Are/Wood_Tom_Sample.wav',
    tags: ['Synth'],
    active: false,
  },
];

export const SORTED_SOUNDS: LibrarySound[] = [...AVAILABLE_SOUNDS, ...NEW_SOUNDS].sort((a, b) => {
  const nameA = a.name.toLowerCase();
  const nameB = b.name.toLowerCase();
  return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
});
