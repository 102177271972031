export const int16ToFloat32 = (inputArray: Buffer, startIndex: number, length: number) => {
  // unsigned 16-bit integer array converted to a 32-bit float array.
  // The first startIndex items are skipped, and only 'length' number of items is converted.
  // source: https://stackoverflow.com/questions/35234551/javascript-converting-from-int16-to-float32
  // TODO verify no data is lost in conversion

  const output = new Float32Array(inputArray.length - startIndex);
  for (let i = startIndex; i < length; i++) {
    const int = inputArray[i];
    // If the high bit is on, then it is a negative number, and actually counts backwards.
    const float = int >= 0x8000 ? -(0x10000 - int) / 0x8000 : int / 0x7fff;
    output[i] = float;
  }
  return output;
};
