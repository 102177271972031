import { FC } from 'react';

/** Types */
import { IconProps } from '@/types';

export const TrblClipboardIcon: FC<IconProps> = ({ fill = '#DADADA' }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.75024 0.999899H10.2502C10.6645 0.999899 11.0002 1.33569 11.0002 1.7499V11.2499C11.0002 11.6641 10.6645 11.9999 10.2502 11.9999H1.75024C1.33603 11.9999 1.00024 11.6641 1.00024 11.2499V1.7499C1.00024 1.33569 1.33603 0.999899 1.75024 0.999899H4.25024C4.2925 0.999016 4.33121 0.976059 4.35224 0.939399C4.6966 0.357158 5.32279 0 5.99924 0C6.6757 0 7.30188 0.357158 7.64624 0.939399C7.66764 0.976668 7.70727 0.99972 7.75024 0.999899ZM9.20024 8.94286C9.39399 8.7163 9.50039 8.42796 9.50024 8.12986V2.74986C9.50024 2.61179 9.38832 2.49986 9.25024 2.49986H2.75024C2.61217 2.49986 2.50024 2.61179 2.50024 2.74986V10.2494C2.50024 10.3874 2.61217 10.4994 2.75024 10.4994H7.29074C7.65597 10.4996 8.00305 10.3402 8.24074 10.0629L9.20024 8.94286Z"
      fill={fill}
    />
    <path
      d="M8.00024 4.12451H4.00024C3.79314 4.12451 3.62524 4.2924 3.62524 4.49951C3.62524 4.70662 3.79314 4.87451 4.00024 4.87451H8.00024C8.20735 4.87451 8.37524 4.70662 8.37524 4.49951C8.37524 4.2924 8.20735 4.12451 8.00024 4.12451Z"
      fill={fill}
    />
    <path
      d="M8.00024 5.87451H4.00024C3.79314 5.87451 3.62524 6.0424 3.62524 6.24951C3.62524 6.45662 3.79314 6.62451 4.00024 6.62451H8.00024C8.20735 6.62451 8.37524 6.45662 8.37524 6.24951C8.37524 6.0424 8.20735 5.87451 8.00024 5.87451V5.87451Z"
      fill={fill}
    />
    <path
      d="M6.25024 7.62451H4.00024C3.79314 7.62451 3.62524 7.7924 3.62524 7.99951C3.62524 8.20662 3.79314 8.37451 4.00024 8.37451H6.25024C6.45735 8.37451 6.62524 8.20662 6.62524 7.99951C6.62524 7.7924 6.45735 7.62451 6.25024 7.62451Z"
      fill={fill}
    />
  </svg>
);
