import { IconButton as MuiIconButton, ButtonProps } from '@mui/material';
import { FC } from 'react';

interface IconButtonProps extends ButtonProps {
  icon: React.ReactElement;
  edge?: false | 'start' | 'end' | undefined;
  label?: string;
  className?: string | '';
}

export const IconButton: FC<IconButtonProps> = ({ icon, edge = 'start', label, className, ...IconButtonProps }) => (
  <MuiIconButton edge={edge} name={label} {...IconButtonProps} className={className}>
    {icon}
  </MuiIconButton>
);
