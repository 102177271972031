import { FC } from 'react';

/** Types */
import { IconProps } from '@/types';

export const Trbl3DBoxIcon: FC<IconProps> = ({ fill }) => (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6178 10.5268L14.1124 11.2951L14.113 11.2927C14.4491 11.4782 14.5817 11.909 14.4119 12.2635C14.242 12.6179 13.8318 12.7665 13.4875 12.5982L11.8075 11.7337C11.7898 11.7249 11.7692 11.7249 11.7515 11.7337L7.95187 13.5228H7.94795C7.84121 13.5734 7.7187 13.5734 7.61195 13.5228H7.60691L3.80507 11.7337C3.78841 11.7255 3.7691 11.7255 3.75243 11.7337L2.07243 12.6087C1.72635 12.7885 1.30589 12.642 1.13331 12.2815C0.960736 11.921 1.10139 11.483 1.44747 11.3033L2.94267 10.5274C2.98995 10.5024 3.0198 10.452 3.01995 10.3967V5.54167C3.01999 5.31254 3.14881 5.10465 3.34923 5.01025L6.99931 3.29233C7.04947 3.26871 7.08168 3.21667 7.08163 3.15933V1.16667C7.08163 0.763959 7.39503 0.4375 7.78163 0.4375C8.16823 0.4375 8.48163 0.763959 8.48163 1.16667V3.15758C8.48187 3.21483 8.51399 3.26673 8.56395 3.29058L12.2096 5.00967C12.4106 5.10381 12.5399 5.31205 12.54 5.54167V10.3962C12.54 10.4516 12.5701 10.5022 12.6178 10.5268ZM5.13965 5.54178C5.13965 5.59918 5.17197 5.65124 5.22222 5.67478L7.7243 6.85311C7.76098 6.87031 7.80298 6.87031 7.83966 6.85311L10.3597 5.67478C10.4099 5.65124 10.4422 5.59918 10.4422 5.54178C10.4422 5.48438 10.4099 5.43232 10.3597 5.40878L7.83966 4.23045C7.80292 4.21355 7.76104 4.21355 7.7243 4.23045L5.22222 5.40878C5.17197 5.43232 5.13965 5.48438 5.13965 5.54178Z"
      fill={fill || '#DADADA'}
    />
  </svg>
);
