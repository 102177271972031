/** Types */
import { IconProps } from '@/types';

export const TrblElevationIcon = ({ fill }: IconProps) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5548_295184)">
      <path
        d="M2.3125 5.375V14.5625L11.0625 11.5"
        stroke="#7f7f7f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="2 2"
      />
      <path
        d="M2.3125 3.25C2.83027 3.25 3.25 2.83027 3.25 2.3125C3.25 1.79473 2.83027 1.375 2.3125 1.375C1.79473 1.375 1.375 1.79473 1.375 2.3125C1.375 2.83027 1.79473 3.25 2.3125 3.25Z"
        stroke="#7f7f7f"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6875 11.125C14.2053 11.125 14.625 10.7053 14.625 10.1875C14.625 9.66973 14.2053 9.25 13.6875 9.25C13.1697 9.25 12.75 9.66973 12.75 10.1875C12.75 10.7053 13.1697 11.125 13.6875 11.125Z"
        stroke="#7f7f7f"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7861 9.32043C10.7861 9.32043 9.07376 5.77478 4.67291 5.97481M4.67291 5.97481L6.30242 8.0845M4.67291 5.97481L6.78243 4.34534"
        stroke="#7f7f7f"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5548_295184">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
