import { useEffect } from 'react';
import {
  BufferGeometry,
  Color,
  EdgesGeometry,
  LineBasicMaterial,
  LineSegments,
  Mesh,
  MeshStandardMaterial,
} from 'three';

/** Types */
import { SelectedDetails, View3DType } from '@/context/EditorContext/types';
import { ModelLayerGroup } from '@/context/ModelContext/types';
import { MaterialCategoryColors } from './utils';
import { HiddenLayer } from '@/types';

/** Constants */
import { DEFAULT_LAYER_COLOR } from './constants';

type Model3DProps = {
  layerGroups: ModelLayerGroup[];
  view3D?: View3DType;
  selectedDetails?: SelectedDetails | null;
  hiddenLayers?: HiddenLayer[];
  layerColors?: Record<string, string>;
  onRendered?: () => void;
};

export const Model3D: React.FC<Model3DProps> = ({
  layerGroups,
  view3D,
  selectedDetails,
  hiddenLayers = [],
  layerColors = {},
  onRendered,
}) => {
  return (
    <group name="Main geometry">
      {layerGroups.map((layerGroup, i) => {
        return (
          <Model3DLayerGroup
            key={layerGroup.id}
            layerGroup={layerGroup}
            view3D={view3D}
            selectedDetails={selectedDetails}
            hiddenLayers={hiddenLayers}
            layerColors={layerColors}
            // When last group has been rendered we can consider the whole model to be rendered and trigger tha callback
            onRendered={i === layerGroups.length - 1 ? onRendered : undefined}
          />
        );
      })}
    </group>
  );
};

type Model3dLayerGroupProps = {
  layerGroup: ModelLayerGroup;
  hiddenLayers: HiddenLayer[];
  view3D?: View3DType;
  selectedDetails?: SelectedDetails | null;
  layerColors: Record<string, string>;
  onRendered?: () => void;
};
const Model3DLayerGroup: React.FC<Model3dLayerGroupProps> = ({
  layerGroup,
  view3D = 'shaded',
  selectedDetails = null,
  hiddenLayers,
  layerColors,
  onRendered,
}) => {
  return (
    <group uuid={layerGroup.id} name={layerGroup.name}>
      {layerGroup.children.map((layer, i) => {
        const isSelected =
          (selectedDetails?.type === 'LayerGroup' && selectedDetails.id === layerGroup.id) ||
          (selectedDetails?.type === 'Layer' && selectedDetails.id === layer.userData.attributes.id);

        const isHidden =
          hiddenLayers.findIndex((x) => x.id === layer.userData.attributes.id || x.id === layerGroup.id) > -1;

        const color = layerColors[layer.userData.attributes.id] ?? DEFAULT_LAYER_COLOR;

        return (
          <Model3DLayer
            key={layer.uuid}
            layer={layer}
            color={color}
            isSelected={isSelected}
            isHidden={isHidden}
            view3D={view3D}
            //When last layer renders we can consider the whole group rendered and trigger the callback
            onRendered={i === layerGroup.children.length - 1 ? onRendered : undefined}
          />
        );
      })}
    </group>
  );
};

let edgeMaterial = new LineBasicMaterial({
  color: 0x1a1a1a,
  linewidth: 1,
  opacity: 1,
  transparent: true,
});

type Model3DLayerProps = {
  layer: Mesh<BufferGeometry, MeshStandardMaterial>;
  isSelected: boolean;
  isHidden: boolean;
  view3D: View3DType;
  color: string;
  onRendered?: () => void;
};
const Model3DLayer: React.FC<Model3DLayerProps> = ({ layer, isSelected, isHidden, view3D, color, onRendered }) => {
  edgeMaterial.color.set(new Color(0x5a5a5a));

  let opacity = 0.5,
    transparent = true,
    depthWrite = false;

  if (view3D === 'shaded') {
    depthWrite = true;

    // Check if layer name indicates window or glass, or if the color is the window color (see utils)
    if (!(layer.name.includes('window') || layer.name.includes('glass') || color === MaterialCategoryColors.Windows)) {
      opacity = 1;
    }
  } else if (view3D === 'wireframe') {
    opacity = 0;
    edgeMaterial.color.set(new Color(0x4a4a4a));
  }

  useEffect(() => {
    const geo = new EdgesGeometry(layer.geometry);
    const wireframe = new LineSegments(geo, edgeMaterial);

    layer.add(wireframe);

    if (onRendered) {
      onRendered();
    }
  }, []);

  return !isHidden ? (
    <primitive
      object={layer}
      material-opacity={opacity}
      material-transparent={transparent}
      material-depthWrite={depthWrite}
      material-color={isSelected ? '#fd5b5b' : color}
      material-emissive={isSelected ? '#cd3f1f' : 0x000000}
      visible={!isHidden}
      renderOrder={5}
    />
  ) : null;
};
