import { FC } from 'react';

import { TrblArrowLeft, TrblArrowRight } from '@/components/Icons';
import { ControlButton, ReceiverSelector } from '@/components/Shared/Auralizer';

type ReceiverControlsProps = {
  receiverOptions: { id: string; name: string }[];
  selectedReceiverId: string;
  onReceiverChanged: (receiverId: string) => void;
};

export const ReceiverControls: FC<ReceiverControlsProps> = ({
  receiverOptions,
  selectedReceiverId,
  onReceiverChanged,
}) => {
  const clickPreviousReceiver = () => {
    const selectedReceiverIndex = receiverOptions.findIndex((rec) => rec.id === selectedReceiverId);

    if (selectedReceiverIndex > 0) {
      onReceiverChanged(receiverOptions[selectedReceiverIndex - 1].id);
    } else {
      onReceiverChanged(receiverOptions[receiverOptions.length - 1].id);
    }
  };
  const clickNextReceiver = () => {
    const selectedReceiverIndex = receiverOptions.findIndex((rec) => rec.id === selectedReceiverId);

    if (selectedReceiverIndex < receiverOptions.length - 1) {
      onReceiverChanged(receiverOptions[selectedReceiverIndex + 1].id);
    } else {
      onReceiverChanged(receiverOptions[0].id);
    }
  };

  return (
    <>
      <ControlButton
        title="Previous receiver"
        onClick={clickPreviousReceiver}
        style={{ margin: '0 4px', width: 32, height: 32 }}
        icon={<TrblArrowLeft width="12" height="12" />}
      />
      <ControlButton
        title="Next receiver"
        onClick={clickNextReceiver}
        style={{ margin: '0 12px 0 4px', width: 32, height: 32 }}
        icon={<TrblArrowRight width="12" height="12" />}
      />
      <ReceiverSelector
        label="Selected position:"
        value={selectedReceiverId}
        setValue={onReceiverChanged}
        menuItems={receiverOptions}
      />
    </>
  );
};
