/** Types */
import { IconProps } from '@/types';

export const TrblLinkIcon = ({ fill = '#DADADA' }: IconProps) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.96867 8.36542C5.67115 8.24188 5.32981 8.38291 5.20625 8.68042C5.17714 8.75189 5.13366 8.81661 5.0785 8.87059C4.96983 8.98067 4.82136 9.04229 4.66667 9.0415H2.33333C1.689 9.0415 1.16667 8.51917 1.16667 7.87484V6.12484C1.16667 5.4805 1.689 4.95817 2.33333 4.95817H4.66667C4.82177 4.95762 4.97058 5.01941 5.07967 5.12967C5.1342 5.18347 5.17726 5.24777 5.20625 5.31867C5.28496 5.51295 5.46184 5.65006 5.66961 5.67783C5.87737 5.70561 6.08406 5.61977 6.21104 5.45299C6.33801 5.28621 6.36574 5.06413 6.28367 4.87125C6.0126 4.21747 5.37442 3.79133 4.66667 3.7915H2.33333C1.04467 3.7915 0 4.83617 0 6.12484V7.87484C0 9.1635 1.04467 10.2082 2.33333 10.2082H4.66667C5.37394 10.2073 6.01153 9.78183 6.28367 9.129C6.34336 8.98599 6.34369 8.82511 6.28459 8.68186C6.2255 8.5386 6.11183 8.42475 5.96867 8.36542Z"
      fill={fill}
    />
    <path
      d="M3.5 6.99984C3.5 7.322 3.76117 7.58317 4.08333 7.58317H10.5C10.8222 7.58317 11.0833 7.322 11.0833 6.99984C11.0833 6.67767 10.8222 6.4165 10.5 6.4165H4.08333C3.76117 6.4165 3.5 6.67767 3.5 6.99984Z"
      fill={fill}
    />
    <path
      d="M11.6668 3.7915H9.3335C8.62617 3.79217 7.98851 4.21774 7.7165 4.87067C7.63443 5.06355 7.66215 5.28563 7.78913 5.45241C7.9161 5.61919 8.12279 5.70502 8.33056 5.67725C8.53832 5.64947 8.71521 5.51236 8.79391 5.31809C8.82317 5.2467 8.86663 5.182 8.92166 5.12792C9.03068 5.01848 9.17903 4.95734 9.3335 4.95817H11.6668C12.3112 4.95817 12.8335 5.4805 12.8335 6.12484V7.87484C12.8335 8.51917 12.3112 9.0415 11.6668 9.0415H9.3335C9.17834 9.04232 9.02943 8.98048 8.9205 8.87C8.86584 8.81631 8.82276 8.75198 8.79391 8.681C8.71388 8.48854 8.53726 8.35339 8.33057 8.32647C8.12388 8.29954 7.91852 8.38493 7.79186 8.55047C7.6652 8.71601 7.63647 8.93654 7.7165 9.129C7.98775 9.78256 8.62589 10.2084 9.3335 10.2082H11.6668C12.9555 10.2082 14.0002 9.1635 14.0002 7.87484V6.12484C14.0002 4.83617 12.9555 3.7915 11.6668 3.7915Z"
      fill={fill}
    />
  </svg>
);
