import { Box, SxProps } from '@mui/material';
import { FC } from 'react';

type LabelProps = {
  children: React.ReactNode;
  htmlFor?: string;
  sx?: SxProps;
};

export const Label: FC<LabelProps> = ({ children, htmlFor, sx }) => {
  return (
    <Box component="div" fontWeight={500} sx={sx}>
      <label htmlFor={htmlFor}>{children}</label>
    </Box>
  );
};
