import { LinearProgress, linearProgressClasses, styled } from '@mui/material';

export const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: '8px',
  border: '0.5px solid #171717',
  borderRadius: '50px',
  backgroundColor: '#171717',
  margin: '10px 0',

  [`& .${linearProgressClasses.barColorPrimary}`]: {
    borderRadius: '50px',
    backgroundColor: '#00F5BA',
  },

  [`& .${linearProgressClasses.barColorSecondary}`]: {
    borderRadius: '50px',
    backgroundColor: '#00D1FF',
  },
}));
