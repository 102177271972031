import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblChevronLeft: FC<IconProps> = ({ width = 8, height = 13, fill = '#F5F5F5' }) => (
  <svg width={width} height={height} viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.52344 11.7144L0.809152 6.71436L6.52344 1.71436"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
