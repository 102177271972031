import { RouterProvider, createBrowserRouter } from 'react-router-dom';

/** Date and localization stuff (MUI only) */
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

/** Datadog RUM for user behaviour tracking */
import { datadogRum } from '@datadog/browser-rum';

/** Auth for authentication */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

/** Components */
import { AuralizerPage } from '@/pages/AuralizerStandalone/AuralizerPage';
import { ErrorPage } from '@/pages';
import { MobileUnsupported } from '@/pages/MobileUnsupported';
import { UnexpectedErrorPage } from '@/pages/AuralizerStandalone/UnexpectedError';

/** Context */
import { ModelProvider } from '@/context/ModelContext';
import { AuralizerProvider } from './Auralizer/AuralizerContext';

/** Utils */
import { isMobile } from '@/utils/trebleFunctions';

/** Theme */
import { darkTheme } from '@/theme';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: '/',
    element: <AuralizerPage />,
    errorElement: <UnexpectedErrorPage />,
  },
  {
    path: '/error',
    element: (
      <ErrorPage
        title=""
        header="Invalid or expired Auralization"
        text="It looks like the Auralization you are trying to load is either invalid or expired. Please contact the owner of the Auralization for more information."
        linkOptions={null}
        logoHref="https://www.treble.tech/"
      />
    ),
  },
]);

const App = () => {
  const location = window.location;

  const testString = location.hostname.toLowerCase();
  let environment = '';
  let ddEnvironment = '';
  const env = import.meta.env;

  if (testString === 'auralizer.treble.tech') {
    environment = 'production';
    ddEnvironment = 'prod';
  } else if (testString === 'auralizer.staging.treble.tech') {
    environment = 'staging';
    ddEnvironment = 'staging';
  } else if (testString === 'auralizer.dev.treble.tech') {
    environment = 'development';
    ddEnvironment = 'dev';
  }

  if (environment && environment !== '') {
    datadogRum.init({
      applicationId: env.VITE_DATADOG_ID_AUR,
      clientToken: env.VITE_DATADOG_CLIENT_TOKEN_AUR,
      site: 'datadoghq.eu',
      proxy: 'https://drum.treble.tech',
      service: 'treble-auralizer-react',
      env: ddEnvironment,
      allowedTracingUrls: env.VITE_DATADOG_TRACING_URLS.split(';'),

      // Specify a version number to identify the deployed version of your application in Datadog
      version: env.VITE_APP_VERSION,
      sessionSampleRate: 100,
      sessionReplaySampleRate: environment === 'production' ? 0 : 20,
      trackUserInteractions: true,
      trackFrustrations: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      enableExperimentalFeatures: ['clickmap'],
    });

    if (environment !== 'production') {
      datadogRum.startSessionReplayRecording();
    }
  }

  if (isMobile()) {
    return <MobileUnsupported />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={darkTheme}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ModelProvider>
              <AuralizerProvider>
                <RouterProvider router={router} />
              </AuralizerProvider>
            </ModelProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
