import { useReducer, createContext, useContext, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/** Context */
import { useEditorContext, ActionType as EditorActionType } from './EditorContext';
import { useAppContext } from './AppContext';
import { useModelContext } from './ModelContext';

/** Hooks */
import { useGetPointValidity } from '@/components/SourceRecieverSettings/hooks/useGetPointValidity';
import { useSabineEstimate } from '@/components/Editor/hooks';
import { MissingMaterialInfo, useGetMissingMaterialInfo } from '@/hooks';

/** Types */
import { HiddenLayer, Material, MaterialLayer, Simulation, SimulationRun } from '@/types';
import { Receiver, Source } from './EditorContext/types';
import { ModelLayerGroup } from './ModelContext/types';

/** Utils */
import { createSurfaceLayers } from './createSurfaceLayers';
import { getStatusBySimulationRun, sortAndOrderSimulation } from './utils';
import { getSimStatus } from '@/utils/getSimStatus';
import { getSimFromLastSimRun } from '@/components/Auralizer/utils';
import { mapToGridReceivers, mapToReceivers, mapToSources } from '@/components/SourceRecieverSettings/utils';

declare global {
  interface Window {
    debuggerSimSelected: any;
    sabineEstimate: number[];
  }
}

const initialState: State = {
  originalSim: null,
  lastSimRunDate: '',
  selectedSimulation: null,
  availableSimulations: null,
  hiddenLayers: [],
  surfaceLayers: [],
  missingMaterials: [],
  userTouched: false,
  sabineEstimate: [],
};

enum ActionType {
  SET_SELECTED_SIMULATION = 'SET_SELECTED_SIMULATION',
  SET_ORIGINAL_SIMULATION = 'SET_ORIGINAL_SIMULATION',
  UPDATE_SELECTED_SIMULATION = 'UPDATE_SELECTED_SIMULATION',
  SET_LAST_SIMULATION_RUN = 'SET_LAST_SIMULATION_RUN',
  UPDATE_MESH_TASK_ID = 'UPDATE_MESH_TASK_ID',
  SET_MODEL_SIMULATIONS = 'SET_MODEL_SIMULATIONS',
  SET_HIDDEN_LAYERS = 'SET_HIDDEN_LAYERS',
  UPDATE_SURFACE_LAYERS = 'UPDATE_SURFACE_LAYERS',
  CREATE_SURFACE_LAYERS = 'CREATE_SURFACE_LAYERS',
  SET_USER_TOUCHED = 'SET_USER_TOUCHED',
  SET_SABINE_ESTIMATE = 'SET_SABINE_ESTIMATE',
}

type SimulationAction =
  | { type: ActionType.SET_SELECTED_SIMULATION; simulation: Simulation }
  | { type: ActionType.SET_ORIGINAL_SIMULATION; simulation: Simulation | null }
  | { type: ActionType.UPDATE_SELECTED_SIMULATION; simulation: Simulation | null }
  | { type: ActionType.SET_LAST_SIMULATION_RUN; simulationRun: SimulationRun }
  | { type: ActionType.UPDATE_MESH_TASK_ID; meshTaskId: string | null }
  | { type: ActionType.SET_MODEL_SIMULATIONS; simulations: Simulation[] }
  | { type: ActionType.SET_USER_TOUCHED; userTouched: boolean }
  | { type: ActionType.SET_HIDDEN_LAYERS; payload: HiddenLayer[] }
  | { type: ActionType.UPDATE_SURFACE_LAYERS; newSurfaceLayers: MaterialLayer[] }
  | {
      type: ActionType.CREATE_SURFACE_LAYERS;
      modelLayers: ModelLayerGroup[] | null;
      materials: Material[];
      missingMaterials: MissingMaterialInfo[];
    }
  | { type: ActionType.SET_SABINE_ESTIMATE; sabineEstimate: number[] };

type State = {
  lastSimRunDate: string | null;
  selectedSimulation: Simulation | null;
  originalSim: Simulation | null;
  availableSimulations: Simulation[] | null;
  surfaceLayers: MaterialLayer[];
  missingMaterials: MissingMaterialInfo[];
  hiddenLayers: HiddenLayer[];
  userTouched: boolean;
  sabineEstimate: number[];
};

type SimulationProviderProps = { children: ReactNode };

type Dispatch = (action: SimulationAction) => void;

const SimulationContext = createContext<{ simulationState: State; dispatch: Dispatch } | undefined>(undefined);

const simulationReducer = (state: State, action: SimulationAction): State => {
  switch (action.type) {
    case ActionType.SET_SELECTED_SIMULATION: {
      const newSelectedSimulation = action.simulation;
      const [newStatus, runDate] = getSimStatus(newSelectedSimulation, null);
      const updatedSimulation: Simulation = {
        ...newSelectedSimulation,
        extra: {
          ...state?.selectedSimulation?.extra,
          status: newStatus,
        },
      };

      const userTouched = newSelectedSimulation.hasBeenEdited === null ? false : newSelectedSimulation.hasBeenEdited;
      window.debuggerSimSelected = updatedSimulation;

      return {
        ...state,
        userTouched,
        // @ts-ignore
        lastSimRunDate: runDate,
        selectedSimulation: { ...updatedSimulation },
        surfaceLayers: [],
      };
    }

    case ActionType.SET_ORIGINAL_SIMULATION: {
      return {
        ...state,
        originalSim: action.simulation,
      };
    }

    case ActionType.UPDATE_SELECTED_SIMULATION: {
      if (action.simulation) {
        const updatedSimulation: Simulation = {
          ...action.simulation,
          extra: {
            ...state?.selectedSimulation?.extra,
          },
        };
        const newAvailableSimulations = state.availableSimulations?.map((s) =>
          s.id === updatedSimulation.id ? updatedSimulation : s
        );

        // @ts-ignore TODO: Bjarni check out this ts error
        const sortedSimulations = newAvailableSimulations ? sortAndOrderSimulation(newAvailableSimulations) : null;

        window.debuggerSimSelected = updatedSimulation;
        return {
          ...state,
          availableSimulations: sortedSimulations,
          userTouched: updatedSimulation.hasBeenEdited ? updatedSimulation.hasBeenEdited : false,
          selectedSimulation: { ...updatedSimulation },
        };
      } else {
        return { ...state };
      }
    }

    case ActionType.SET_LAST_SIMULATION_RUN: {
      const newStatus = getStatusBySimulationRun(action.simulationRun);
      const newAvailableSimulations =
        state.availableSimulations?.map((s) =>
          s.id === action.simulationRun.simulationId
            ? {
                ...s,
                lastSimulationRun: action.simulationRun,
                extra: { ...s.extra, status: newStatus },
              }
            : s
        ) || null;

      const updatedSimulation =
        state.selectedSimulation !== null && state.selectedSimulation.id === action.simulationRun.simulationId
          ? {
              ...state.selectedSimulation,
              lastSimulationRun: action.simulationRun,
              extra: { ...state.selectedSimulation.extra, status: newStatus },
            }
          : state.selectedSimulation;

      window.debuggerSimSelected = updatedSimulation;

      return {
        ...state,
        userTouched: newStatus === 2 || newStatus === 5 ? false : state.userTouched,
        availableSimulations: newAvailableSimulations,
        selectedSimulation: updatedSimulation,
      };
    }

    case ActionType.UPDATE_MESH_TASK_ID: {
      const updatedSimulation =
        state.selectedSimulation !== null
          ? {
              ...state.selectedSimulation,
              extra: {
                ...state.selectedSimulation.extra,
                meshTaskId: action.meshTaskId,
              },
            }
          : null;
      window.debuggerSimSelected = updatedSimulation;
      return {
        ...state,
        selectedSimulation: updatedSimulation,
      };
    }

    // This changes all the simulations for that particular model
    case ActionType.SET_MODEL_SIMULATIONS: {
      const sortedSimulations = sortAndOrderSimulation(action.simulations);
      const updatedSimulation = sortedSimulations.length === 0 ? null : state.selectedSimulation;
      window.debuggerSimSelected = updatedSimulation;
      return {
        ...state,
        availableSimulations: [...sortedSimulations],
        selectedSimulation: updatedSimulation,
      };
    }

    case ActionType.SET_HIDDEN_LAYERS: {
      return {
        ...state,
        hiddenLayers: action.payload,
      };
    }

    case ActionType.UPDATE_SURFACE_LAYERS: {
      return {
        ...state,
        surfaceLayers: [...action.newSurfaceLayers],
      };
    }

    case ActionType.CREATE_SURFACE_LAYERS: {
      if (state.selectedSimulation) {
        const { newLayers, newSimulation } = createSurfaceLayers(
          action.modelLayers,
          state.selectedSimulation,
          action.materials,
          action.missingMaterials
        );
        const updatedSimulation = newSimulation ? { ...newSimulation } : state.selectedSimulation;
        window.debuggerSimSelected = updatedSimulation;
        return {
          ...state,
          missingMaterials: action.missingMaterials,
          surfaceLayers: [...newLayers],
          selectedSimulation: updatedSimulation,
        };
      } else {
        return { ...state };
      }
    }

    case ActionType.SET_USER_TOUCHED: {
      return {
        ...state,
        userTouched: action.userTouched,
      };
    }

    case ActionType.SET_SABINE_ESTIMATE: {
      return {
        ...state,
        sabineEstimate: action.sabineEstimate,
      };
    }
  }
};

const SimulationProvider = ({ children }: SimulationProviderProps) => {
  const { dispatch: editorDispatch } = useEditorContext();
  const {
    appState: { filteredMaterials },
  } = useAppContext();
  const { currentModel3dLayerGroups, isModelLoaded, bypassPointValidity } = useModelContext();
  const [simulationState, dispatch] = useReducer(simulationReducer, initialState);
  const getPointValidity = useGetPointValidity();
  const { pathname } = useLocation();
  const calculateSabineEstimate = useSabineEstimate();
  const { mutate: getMissingMaterialInfo } = useGetMissingMaterialInfo();

  useEffect(() => {
    // When a new simulation is loaded, we create sources, receivers and grid receivers points, only once.
    // These then get updated through hooks but are always referenced from the editor context.
    // The reason why we want these values to live in a context is because that is a shared state
    // but Hooks do not share state between the components using them.
    let selectedSimulation = simulationState.selectedSimulation;

    if (isModelLoaded && selectedSimulation) {
      // if page is Results or Auralizer, then set lastSimulationRun as the selectedSimulation
      if (pathname === '/results' || pathname === '/auralizer') {
        selectedSimulation = getSimFromLastSimRun(selectedSimulation) ?? selectedSimulation;
      }

      const sources = mapToSources(selectedSimulation.sources, selectedSimulation.sourceParameters);
      const receivers = mapToReceivers(selectedSimulation.receivers);
      const gridReceivers = mapToGridReceivers(selectedSimulation.gridReceivers);

      // if page is Results or Auralizer, then set the sources/receivers without validating them
      if (pathname === '/results' || pathname === '/auralizer') {
        editorDispatch({
          type: EditorActionType.SET_SOURCES,
          sources,
        });

        editorDispatch({
          type: EditorActionType.SET_RECEIVERS,
          receivers: receivers,
        });
      }
      // set the gridReceivers regardless of what page it is, they have internal validation
      editorDispatch({
        type: EditorActionType.SET_GRID_RECEIVERS,
        gridReceivers,
      });
    }
  }, [simulationState.selectedSimulation?.id, isModelLoaded, simulationState.userTouched, pathname]);

  useEffect(() => {
    let selectedSimulation = simulationState.selectedSimulation;

    // if page is Editor then validate the sources/receivers before setting them
    if (isModelLoaded && selectedSimulation && pathname === '/editor') {
      const sources = mapToSources(selectedSimulation.sources, selectedSimulation.sourceParameters);
      const receivers = mapToReceivers(selectedSimulation.receivers);

      if (sources.length > 0) {
        validateSources(sources, selectedSimulation);
      } else {
        editorDispatch({
          type: EditorActionType.SET_SOURCES,
          sources: [],
        });
      }
      if (receivers.length > 0) {
        checkValidReceivers(receivers, sources, selectedSimulation);
      } else {
        editorDispatch({
          type: EditorActionType.SET_RECEIVERS,
          receivers: [],
        });
      }

      editorDispatch({
        type: EditorActionType.SET_TASK_TYPE,
        taskType: selectedSimulation.taskType || '',
      });
    }
  }, [
    isModelLoaded,
    simulationState.selectedSimulation?.id,
    simulationState.selectedSimulation?.taskType,
    simulationState.userTouched,
    bypassPointValidity,
    pathname,
  ]);

  useEffect(() => {
    if (simulationState.selectedSimulation?.modelSettingsV2?.materialIdByObjectId) {
      const sabineEstimate = calculateSabineEstimate(
        simulationState.selectedSimulation?.modelSettingsV2?.materialIdByObjectId
      );

      if (sabineEstimate) {
        window.sabineEstimate = sabineEstimate;
        dispatch({
          type: ActionType.SET_SABINE_ESTIMATE,
          sabineEstimate,
        });
      }
    }
  }, [simulationState.selectedSimulation?.modelSettingsV2?.materialIdByObjectId]);

  // Explanation of the setTimeout "hack": Javascript uses something called the "Event Loop".
  // There is a single thread that executes the code for each event as it occurs.
  // You can use setTimeout to make your Javascript code run in a future iteration of the loop.
  // One trick that some developers use to keep the UI responsive during a long-running Javascript task
  // is to periodically invoke code to be run with a timeout of zero.
  const checkValidReceivers = (receivers: Receiver[], sources: Source[] = [], sim: Simulation | null) => {
    const validatedReceivers = [...receivers];

    validatedReceivers.forEach((receiver: Receiver) => {
      setTimeout(async () => {
        // @ts-ignore
        const validationError = await getPointValidity(receiver.x, receiver.y, receiver.z, 'ReceiverPoint', sources);
        receiver.isValid = validationError === null;
        receiver.validationError = validationError || undefined;
      }, 0);

      editorDispatch({
        type: EditorActionType.SET_RECEIVERS,
        receivers: validatedReceivers,
      });
    });
  };

  const validateSources = (sources: Source[], sim: Simulation | null) => {
    const validatedSources = [...sources];

    validatedSources.forEach((source: Source) => {
      setTimeout(async () => {
        const validationError = await getPointValidity(
          // @ts-ignore
          source.x,
          source.y,
          source.z,
          'SourcePoint',
          undefined,
          sim?.taskType
        );
        source.isValid = validationError === null;
        source.validationError = validationError || undefined;
      }, 0);

      editorDispatch({
        type: EditorActionType.SET_SOURCES,
        sources: validatedSources,
      });
    });
  };

  useEffect(() => {
    // Make sure we create those surface layers
    // even though materials are taking a long time to load
    if (
      currentModel3dLayerGroups?.length &&
      simulationState.surfaceLayers &&
      simulationState.surfaceLayers.length === 0 &&
      simulationState.selectedSimulation?.modelSettingsV2 &&
      filteredMaterials.length > 0
    ) {
      const missingMaterialIds = Object.values(
        simulationState.selectedSimulation?.modelSettingsV2.materialIdByObjectId || {}
      ).filter((materialId) => !filteredMaterials.some((m) => m.id === materialId));

      const missingMaterialIdsWithoutDuplicates = [...new Set(missingMaterialIds)];
      if (missingMaterialIdsWithoutDuplicates.length > 0) {
        getMissingMaterialInfo(missingMaterialIdsWithoutDuplicates, {
          onSuccess: (data) => {
            dispatch({
              type: ActionType.CREATE_SURFACE_LAYERS,
              modelLayers: currentModel3dLayerGroups,
              materials: filteredMaterials,
              missingMaterials: data,
            });
          },
        });
      } else {
        dispatch({
          type: ActionType.CREATE_SURFACE_LAYERS,
          modelLayers: currentModel3dLayerGroups,
          materials: filteredMaterials,
          missingMaterials: [],
        });
      }
    }
  }, [
    simulationState.surfaceLayers,
    simulationState.selectedSimulation?.id,
    currentModel3dLayerGroups,
    filteredMaterials,
  ]);

  const value = {
    simulationState: {
      ...simulationState,
    },
    dispatch,
  };

  return <SimulationContext.Provider value={value}>{children}</SimulationContext.Provider>;
};

const useSimulationContext = () => {
  const context = useContext(SimulationContext);
  if (context === undefined) {
    throw new Error('useSimulationContext must be used within SimulationProvider');
  }
  return context;
};

export { SimulationProvider, useSimulationContext, ActionType };
