/** Types */
import { IconProps } from '@/types';

export const TrblQuickGuideIcon = ({ fill = '#DADADA', width = '18', height = '19' }: IconProps) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.12537 0H16.5004C16.8982 0 17.2797 0.158035 17.561 0.43934C17.8423 0.720644 18.0004 1.10218 18.0004 1.5V13.875C18.0004 14.2728 17.8423 14.6544 17.561 14.9357C17.2797 15.217 16.8982 15.375 16.5004 15.375H4.12537C3.72754 15.375 3.34601 15.217 3.06471 14.9357C2.7834 14.6544 2.62537 14.2728 2.62537 13.875V1.5C2.62537 1.10218 2.7834 0.720644 3.06471 0.43934C3.34601 0.158035 3.72754 0 4.12537 0ZM9.13334 5.40865C9.18181 4.98117 9.19392 4.49669 9.19392 4H10.7208C10.7167 5.40458 10.64 6.58524 10.0462 7.46463C9.25047 8.64122 7.80039 8.84885 6.51995 8.86107V7.30585C8.07505 7.28142 8.5638 6.91908 8.79 6.58524C8.98388 6.29211 9.08083 5.87277 9.13334 5.40865ZM9.31914 12C9.32318 10.5954 9.39992 9.41069 9.99369 8.53537C10.7894 7.35878 12.2395 7.15114 13.5199 7.13893V8.69415C11.9648 8.71857 11.4761 9.08499 11.2499 9.41883C11.056 9.70789 10.9591 10.1272 10.9066 10.5913C10.8581 11.0188 10.846 11.5033 10.846 12H9.31914Z"
      fill={fill}
    />
    <path
      d="M12.0536 16.4999H1.875C1.77554 16.4999 1.68016 16.4604 1.60984 16.39C1.53951 16.3197 1.5 16.2243 1.5 16.1249V5.94629C1.5 5.74738 1.42098 5.55661 1.28033 5.41596C1.13968 5.27531 0.948912 5.19629 0.75 5.19629C0.551088 5.19629 0.360322 5.27531 0.21967 5.41596C0.0790176 5.55661 0 5.74738 0 5.94629L0 16.4999C0 16.8977 0.158035 17.2792 0.43934 17.5605C0.720644 17.8418 1.10218 17.9999 1.5 17.9999H12.0536C12.2525 17.9999 12.4433 17.9208 12.5839 17.7802C12.7246 17.6395 12.8036 17.4488 12.8036 17.2499C12.8036 17.0509 12.7246 16.8602 12.5839 16.7195C12.4433 16.5789 12.2525 16.4999 12.0536 16.4999Z"
      fill={fill}
    />
  </svg>
);
