import { deinterleaveBuffers } from '@/components/Auralizer/aural/utils/deinterleaveBuffers';

/** Types */
import { AudioObject, ChannelBuffers } from '@/components/Auralizer/types';

/** Class */
import { StandaloneAudioEngine } from '../StandaloneAudioEngine';

export const createConvolverPromise = (
  url: string,
  firstSimNormMax: number,
  sourceNormFactor: number
): Promise<unknown> => {
  let audioObject: AudioObject = {
    merger: null,
    inputGain: null,
    convolverArray: [],
    channelCount: 9,
    sampleRate: 32000,
  };

  const audioEngine = StandaloneAudioEngine.getInstance();

  audioObject.merger = audioEngine.audioContext.createChannelMerger(audioObject.channelCount);
  audioObject.inputGain = audioEngine.audioContext.createGain();

  const ratio = sourceNormFactor / firstSimNormMax;

  const originalGain = 1;

  const newGain = ratio * originalGain;

  audioObject.inputGain.gain.value = newGain;
  audioObject.inputGain.channelCount = 1;

  return new Promise(async (resolve) => {
    const response = await fetch(url);
    const arrayBuffer: ArrayBuffer = await response.arrayBuffer();

    const channelBuffers: ChannelBuffers | undefined = deinterleaveBuffers(arrayBuffer);

    if (channelBuffers) {
      channelBuffers.buffers.forEach((buffer: Buffer, index: number) => {
        const convolver: ConvolverNode = audioEngine.createConvolver(
          buffer,
          channelBuffers.datatype,
          audioObject.sampleRate
        );

        audioObject.convolverArray[index] = convolver;

        if (audioObject.inputGain && audioObject.merger) {
          // connect convolver channel to input node
          audioObject.inputGain.connect(audioObject.convolverArray[index]);
          // connect convolver channel to merger
          audioObject.convolverArray[index].connect(audioObject.merger, 0, index);
        }
      });
    }

    resolve(audioObject);
  });
};
