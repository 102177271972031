/** Types */
import { MaterialLayer } from '@/types';
import { Duration } from 'dayjs/plugin/duration';

export const updateObjMats = (simObjMats: { [key: string]: string }, layer: MaterialLayer, materialId: string) => {
  const objMats = { ...simObjMats };

  const updateMaterialId = (id: string) => {
    if (objMats[id] !== materialId) {
      objMats[id] = materialId;
    }
  };

  if (layer.children?.length) {
    // if the layer has mesh object children
    layer.children.forEach((child) => {
      if (child.type === 'Mesh') {
        updateMaterialId(child.id);
      }
    });
  } else {
    // if it's a single mesh object
    updateMaterialId(layer.id);
  }

  return objMats;
};

export const updateObjScats = (
  simObjScats: { [key: string]: number[] },
  layer: MaterialLayer,
  scat: number[] | null
) => {
  if (scat === null) return simObjScats;

  const objScats = { ...simObjScats };
  const idsToUpdate = layer.children.length
    ? layer.children.filter((child) => child.type === 'Mesh').map((child) => child.id)
    : [layer.id];

  idsToUpdate.forEach((id) => {
    objScats[id] = scat;
  });

  return objScats;
};

export const deepCompare = (arg1: any, arg2: any) => {
  if (Object.prototype.toString.call(arg1) === Object.prototype.toString.call(arg2)) {
    if (
      Object.prototype.toString.call(arg1) === '[object Object]' ||
      Object.prototype.toString.call(arg1) === '[object Array]'
    ) {
      if (Object.keys(arg1).length !== Object.keys(arg2).length) {
        return false;
      }
      return Object.keys(arg1).every((key): any => {
        if (typeof arg1[key] === 'string') arg1[key] = arg1[key].toLowerCase();
        if (typeof arg2[key] === 'string') arg2[key] = arg2[key].toLowerCase();

        return deepCompare(arg1[key], arg2[key]);
      });
    }
    return arg1 === arg2;
  }
  return false;
};

export function roundFloat(value: number | string, decimal: number): number {
  const decimalPow = Math.pow(10, decimal);
  return Math.round((parseFloat(value.toString()) + Number.EPSILON) * decimalPow) / decimalPow;
}

export const getTimeStringByDuration = (duration: Duration) => {
  let minutes = duration.seconds() > 30 ? duration.minutes() + 1 : duration.minutes();
  if (duration.days() > 0 || duration.hours() > 0 || minutes === 60) {
    let hours = duration.hours(); // this can be undefined
    const days = duration.days(); // this can be undefined

    if (days > 0) {
      hours = hours + days * 24;
    }
    if (minutes === 60) {
      hours = hours ? hours++ : 1;
      minutes = 0;
    }
    if (hours === 1) {
      return `${hours} hour` + (minutes ? ` ${minutes} min` : '');
    } else {
      return `${hours} hours` + (minutes ? ` ${minutes} min` : '');
    }
  } else if (minutes < 2) {
    return '< 2 min';
  } else {
    return `${minutes} min`;
  }
};

export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

// Implements a stable sort without mutating the original array ()
export const toSorted = <T>(array: T[], compareFunction?: (a: T, b: T) => number): T[] => {
  return [...array].sort(compareFunction);
};
