import { ALPHABET_OPTIONS } from './constants';

/** Styles */
import styles from './styles.module.scss';

type AlphabetOptionType = {
  selectedOptionId: string | undefined;
  optionId: string;
  index: number;
  onSelect: (optionId: string) => void;
  disabled?: boolean;
  size?: 'small' | 'large';
};

export const AlphabetOption = ({
  selectedOptionId,
  optionId,
  index,
  onSelect,
  disabled = false,
  size = 'small',
}: AlphabetOptionType) => {
  return (
    <button
      onClick={() => onSelect(optionId)}
      className={`${styles['alphabet-letter']} ${selectedOptionId === optionId ? styles['selected'] : ''} ${
        styles[size]
      } ${disabled ? styles['disabled'] : ''} `}
      disabled={disabled}>
      {ALPHABET_OPTIONS[index]}
    </button>
  );
};
