import { useReducer, createContext, useContext, ReactNode, useMemo } from 'react';

/** Types */
import { AuralizationPresetDto } from '../AuralizerPresets/types';

/** Utils */
import { createInitialPresetSettingsObjects } from './components/utils';
import { AuralizerSound } from '../SoundLibrary/types';

/** Constants */
import { LibrarySound } from '../Auralizer/audioSounds';

enum ActionType {
  SET_SELECTED_PRESET = 'SET_SELECTED_PRESET',
  SET_SELECTED_SOUND = 'SET_SELECTED_SOUND',
  SET_SOURCE_VOLUME = 'SET_SOURCE_VOLUME',
  SET_SOURCE_MUTE_SETTING = 'SET_SOURCE_MUTE_SETTING',
  SET_MASTER_VOLUME = 'SET_MASTER_VOLUME',
  SET_AVAILABLE_PRESETS = 'SET_AVAILABLE_PRESETS',
  SET_PRESET_EDITED = 'SET_PRESET_EDITED',
}

type AurSelectedSound = {
  id: string;
  name: string;
  soundPath: string;
  urlObject: string | null;
};

type PresetContextAction =
  | {
      type: ActionType.SET_SELECTED_PRESET;
      preset: AuralizationPresetDto | null;
    }
  | {
      type: ActionType.SET_AVAILABLE_PRESETS;
      presets: AuralizationPresetDto[];
    }
  | {
      type: ActionType.SET_SELECTED_SOUND;
      sound: AurSelectedSound;
      sourceIndex: number;
    }
  | {
      type: ActionType.SET_SOURCE_VOLUME;
      sourceIndex: number;
      volume: number;
    }
  | {
      type: ActionType.SET_SOURCE_MUTE_SETTING;
      sourceIndex: number;
      isMuted: boolean;
    }
  | {
      type: ActionType.SET_MASTER_VOLUME;
      volume: number;
    }
  | {
      type: ActionType.SET_PRESET_EDITED;
      presetEdited: boolean;
    };

type State = {
  selectedPreset: AuralizationPresetDto | null;
  availablePresets: AuralizationPresetDto[];
  selectedPresetEdited: boolean;
  selectedSounds: { [key: string]: AuralizerSound };
  sourceVolumes: { [key: string]: number };
  sourceMuteSettings: { [key: string]: boolean };
  masterVolume: number;
  isPlayingAuralization: boolean;
  auralizerSounds: LibrarySound[];
  dispatch: (action: PresetContextAction) => void;
};

const initialState: State = {
  selectedPreset: null,
  availablePresets: [],
  selectedPresetEdited: false,
  selectedSounds: {},
  sourceVolumes: {},
  sourceMuteSettings: {},
  masterVolume: 0,
  isPlayingAuralization: false,
  auralizerSounds: [],
  dispatch: () => {},
};

const PresetContext = createContext(initialState);

function handleUnknownAction(action: never): never;
function handleUnknownAction(action: PresetContextAction) {
  throw new Error(`Unhandled action type: ${action.type}`);
}

type PresetProviderProps = { children: ReactNode };

const presetReducer = (state: State, action: PresetContextAction): State => {
  switch (action.type) {
    case ActionType.SET_SELECTED_PRESET: {
      const sourceSettings = action.preset?.mixerSettings?.sourceSettings ?? null;

      const { newSourceVolumes, newSourceSounds, newSourceMuteSettings } =
        createInitialPresetSettingsObjects(sourceSettings);

      return {
        ...state,
        selectedPreset: action.preset,
        masterVolume: action?.preset?.mixerSettings?.masterVolume ?? 0,
        selectedPresetEdited: false,
        selectedSounds: newSourceSounds,
        sourceVolumes: newSourceVolumes,
        sourceMuteSettings: newSourceMuteSettings,
      };
    }

    case ActionType.SET_AVAILABLE_PRESETS: {
      return {
        ...state,
        availablePresets: [...action.presets],
      };
    }

    case ActionType.SET_SELECTED_SOUND: {
      const newSounds = {
        ...state.selectedSounds,
        [action.sourceIndex]: {
          id: action.sound.id,
          name: action.sound.name,
          soundPath: action.sound.soundPath,
          urlObject: action.sound.urlObject,
        },
      };

      return {
        ...state,
        selectedSounds: newSounds,
        selectedPresetEdited: true,
      };
    }

    case ActionType.SET_SOURCE_VOLUME: {
      const newSourceVolumes = {
        ...state.sourceVolumes,
        [action.sourceIndex]: action.volume,
      };

      return {
        ...state,
        sourceVolumes: newSourceVolumes,
        selectedPresetEdited: true,
      };
    }

    case ActionType.SET_SOURCE_MUTE_SETTING: {
      const newSourceMuteSettings = {
        ...state.sourceMuteSettings,
        [action.sourceIndex]: action.isMuted,
      };
      return {
        ...state,
        sourceMuteSettings: newSourceMuteSettings,
        selectedPresetEdited: true,
      };
    }

    case ActionType.SET_MASTER_VOLUME: {
      return {
        ...state,
        masterVolume: action.volume,
        selectedPresetEdited: true,
      };
    }

    case ActionType.SET_PRESET_EDITED: {
      return {
        ...state,
        selectedPresetEdited: action.presetEdited,
      };
    }

    default: {
      handleUnknownAction(action);
    }
  }
};

const PresetProvider = ({ children }: PresetProviderProps) => {
  const [presetState, dispatch] = useReducer(presetReducer, initialState);

  const value = useMemo(() => {
    return {
      ...presetState,
      dispatch,
    };
  }, [presetState]);

  return <PresetContext.Provider value={value}>{children}</PresetContext.Provider>;
};

const usePresetContext = () => {
  const context = useContext(PresetContext);
  if (context === undefined) {
    throw new Error('usePresetContext must be used within PresetProvider');
  }
  return context;
};

export { PresetProvider, usePresetContext, ActionType };
