/** Types */
import { IconProps } from '@/types';

export const TrblPlayAlternativeIcon = ({ fill = '#DADADA' }: IconProps) => (
  <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 9.5C0 4.52944 4.02944 0.5 9 0.5C13.9683 0.505374 17.9946 4.53166 18 9.5C18 14.4706 13.9706 18.5 9 18.5C4.02944 18.5 0 14.4706 0 9.5ZM7.08567 12.9381L12.6214 10.1706V10.1698C12.8773 10.0435 13.0394 9.783 13.0395 9.49766C13.0397 9.21233 12.8779 8.95161 12.6222 8.82506L7.08492 6.05981C6.85244 5.94396 6.57656 5.95665 6.3557 6.09335C6.13483 6.23006 6.00043 6.47131 6.00042 6.73106V12.2676C6.00056 12.5274 6.13517 12.7686 6.35621 12.9052C6.57726 13.0418 6.85324 13.0542 7.08567 12.9381Z"
      fill={fill}
    />
  </svg>
);
