import { FC } from 'react';

/** Types */
import { IconProps } from '@/types';

export const TrblSettingsSliderIcon: FC<IconProps> = ({ fill }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.39754 9.88192H0.706778C0.316975 9.88192 0.000976562 9.56592 0.000976562 9.17612C0.000976562 8.78632 0.316975 8.47032 0.706778 8.47032H5.39754C5.70975 7.42351 6.67242 6.70605 7.76479 6.70605C8.85717 6.70605 9.81984 7.42351 10.1321 8.47032H11.2938C11.6836 8.47032 11.9996 8.78632 11.9996 9.17612C11.9996 9.56592 11.6836 9.88192 11.2938 9.88192H10.1321C9.81984 10.9287 8.85717 11.6462 7.76479 11.6462C6.67242 11.6462 5.70975 10.9287 5.39754 9.88192ZM8.82346 9.17613C8.82346 8.59143 8.34946 8.11743 7.76476 8.11743C7.18005 8.11743 6.70605 8.59143 6.70605 9.17613C6.70605 9.76084 7.18005 10.2348 7.76476 10.2348C8.34946 10.2348 8.82346 9.76084 8.82346 9.17613Z"
      fill="#DADADA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.51563 3.52963H0.706778C0.316975 3.52963 0.000976562 3.21363 0.000976562 2.82382C0.000976562 2.43402 0.316975 2.11802 0.706778 2.11802H1.51563C1.82784 1.07122 2.79051 0.35376 3.88289 0.35376C4.97526 0.35376 5.93793 1.07122 6.25014 2.11802H11.2938C11.6836 2.11802 11.9996 2.43402 11.9996 2.82382C11.9996 3.21363 11.6836 3.52963 11.2938 3.52963H6.25014C5.93793 4.57643 4.97526 5.29389 3.88289 5.29389C2.79051 5.29389 1.82784 4.57643 1.51563 3.52963ZM4.94162 2.82396C4.94162 2.23926 4.46763 1.76526 3.88292 1.76526C3.29822 1.76526 2.82422 2.23926 2.82422 2.82396C2.82422 3.40867 3.29822 3.88266 3.88292 3.88266C4.46763 3.88266 4.94162 3.40867 4.94162 2.82396Z"
      fill="#DADADA"
    />
  </svg>
);
