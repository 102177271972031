import { Marker } from '@/components/SourceRecieverSettings/Marker';
import { SelectOption, TrblSelect } from '../../TrblSelect';

/** Styles */
import componentStyles from './styles.module.scss';

type ReceiverSelectorProps = {
  label?: string;
  value: string;
  menuItems: SelectOption[];
  setValue: (value: string) => void;
};

export const ReceiverSelector = ({
  label = 'Selected receiver:',
  value,
  menuItems,
  setValue,
}: ReceiverSelectorProps) => {
  return (
    <div className={componentStyles['selector-container']}>
      <p className={componentStyles['form-text']}>{label}</p>
      <TrblSelect
        value={value}
        setValue={setValue}
        menuItems={menuItems}
        style={{ height: '28px', width: '160px', backgroundColor: 'transparent' }}
        minimal
        placeholder="Select receiver"
        renderMenuItem={(item, index) => (
          <>
            <Marker color={'blue'} label={(index + 1).toString()} small margin="0px 6px 0 0" /> <em>{item.name}</em>
          </>
        )}
        renderSelectedItem={(item, index) => (
          <>
            <Marker color={'blue'} label={(index + 1).toString()} small margin="0 6px 0 0" /> <em>{item.name}</em>
          </>
        )}
      />
    </div>
  );
};
