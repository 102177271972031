import { FC } from 'react';

/** Types */
import { IconProps } from '@/types';

export const TrblDeleteIcon: FC<IconProps> = ({ fill }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.29167 5H10.7083C10.8694 5 11 5.13058 11 5.29167V11.8333C11 12.4777 10.4777 13 9.83333 13H4.16667C3.52233 13 3 12.4777 3 11.8333V5.29167C3 5.13058 3.13058 5 3.29167 5ZM5.54199 11.4374C5.78362 11.4374 5.97949 11.2415 5.97949 10.9999V6.4375C5.97949 6.19588 5.78362 6 5.54199 6C5.30037 6 5.10449 6.19588 5.10449 6.4375V10.9999C5.10449 11.2415 5.30037 11.4374 5.54199 11.4374ZM8.45866 11.4374C8.70028 11.4374 8.89616 11.2415 8.89616 10.9999L8.89616 6.4375C8.89616 6.19588 8.70028 6 8.45866 6C8.21703 6 8.02116 6.19588 8.02116 6.4375V10.9999C8.02116 11.2415 8.21703 11.4374 8.45866 11.4374Z"
      fill={fill || '#DADADA'}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0622 3H11.9167C12.2388 3 12.5 3.09446 12.5 3.41663C12.5 3.73879 12.2388 3.99996 11.9167 3.99996L2.08333 4C1.76117 4 1.5 3.82217 1.5 3.5C1.5 3.17783 1.76117 3 2.08333 3H3.93717C3.97585 3 4.01295 2.98464 4.04029 2.95729C4.06764 2.92994 4.08301 2.89284 4.08301 2.85417V2.62504C4.08301 1.81963 4.73593 1 5.54134 1H8.45801C9.26342 1 9.91634 1.81963 9.91634 2.62504V2.85417C9.91634 2.93471 9.98163 3 10.0622 3ZM5.25 2.29167V2.85421C5.25 2.93475 5.31529 3.00004 5.39583 3.00004H8.60417C8.68471 3.00004 8.75 2.93475 8.75 2.85421V2.29167C8.75 2.13058 8.61942 2 8.45833 2H5.54167C5.38058 2 5.25 2.13058 5.25 2.29167Z"
      fill={fill || '#DADADA'}
    />
  </svg>
);
