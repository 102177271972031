import { FC } from 'react';

import styles from './styles.module.scss';

type MarkerProps = {
  color: 'blue' | 'green' | 'white';
  isSelected?: boolean;
  label: string;
  small?: boolean;
  margin?: string;
};

export const Marker: FC<MarkerProps> = ({ isSelected, label, color, small, margin }) => {
  return (
    <span
      style={{ margin }}
      className={`${styles['marker']} ${styles[color]} ${isSelected ? styles['selected'] : ''} ${
        small ? styles['small'] : ''
      }`}>
      <span className={styles['marker-inside']}>
        <span className={styles['marker-text']}>{label}</span>
      </span>
    </span>
  );
};
