import { Object3DNode, Properties, ReactThreeFiber } from '@react-three/fiber';
import THREE, { Color, Raycaster, Scene, Vector3 } from 'three';

export const DEFAULT_CAMERA_SETTINGS: Partial<
  ReactThreeFiber.Object3DNode<THREE.Camera, typeof THREE.Camera> &
    ReactThreeFiber.Object3DNode<THREE.PerspectiveCamera, typeof THREE.PerspectiveCamera> &
    ReactThreeFiber.Object3DNode<THREE.OrthographicCamera, typeof THREE.OrthographicCamera>
> = {
  name: 'Camera',
  position: new Vector3(Math.PI * 6, Math.PI * 3, Math.PI * 6),
  up: new Vector3(0, 0, 1),
  fov: 50,
  /** The "near" value might need to be adjusted. The idea is to have it low enough to make sure that
   * when standing in a receiver position as close as possible to a wall, that while rotating the camera you don't see through the wall
   */
  near: 0.08,
  far: 1000,
};

export const DEFAULT_RAYCASTER_SETTINGS: Partial<Raycaster> = {
  params: {
    Points: { threshold: 0.2 },
    Line: { threshold: 0.1 },
  },
};

export const DEFAULT_SCENE_SETTINGS: Partial<Object3DNode<Scene, typeof Scene>> = {
  background: new Color(0x555657),
};

export const DEFAULT_GL_SETTINGS: Partial<Properties<THREE.WebGLRenderer> | THREE.WebGLRendererParameters> = {
  alpha: true,
};

export const DEFAULT_RESIZE_SETTINGS = { debounce: 0, offsetSize: false };

export const DEFAULT_LAYER_COLOR = '#ffffff';

export const DEFAULT_CAMERA_POSITION = new Vector3(Math.PI * 6, Math.PI * 3, Math.PI * 6);
