/** Types */
import { IconProps } from '@/types';

export const TrblArrowThinRight = ({ fill = '#DADADA' }: IconProps) => (
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2 7.5H12H2Z" fill={fill} />
    <path d="M2 7.5H12" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M7.33301 12.1663L11.9997 7.49967L7.33301 2.83301"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
