import styles from './styles.module.scss';

export const MouseSVG = () => {
  return (
    <svg
      className={styles['mouse-movement']}
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="54"
      viewBox="0 0 36 54"
      fill="none">
      <g filter="url(#filter0_di_2268_302393)">
        <path
          d="M33 17.7207V38.2181C33 45.2762 27.4199 51 20.5389 51H15.4611C8.58012 51 3 45.2762 3 38.2181V17.7207C3 9.58914 9.42374 3 17.3512 3H18.652C26.5763 3 33 9.58914 33 17.7207ZM22.8412 27.3105C22.8412 27.3114 22.8419 27.3121 22.8428 27.3121H25.9355C27.6646 27.3121 29.0653 25.8754 29.0653 24.1018V16.9445C29.0653 11.1778 24.5702 6.49109 18.9772 6.36558C18.8549 6.36558 18.7519 6.46797 18.7519 6.59348V11.6435C18.7519 11.7657 18.8452 11.8582 18.9611 11.8681C20.1751 11.9771 21.1282 13.0241 21.1282 14.299V25.5517C21.1282 26.5222 21.8936 27.3079 22.8395 27.3088C22.8404 27.3088 22.8412 27.3096 22.8412 27.3105ZM17.8438 22.2225H18.1529C18.7293 22.2225 19.193 21.7435 19.193 21.1556V14.9133C19.193 14.3221 18.7261 13.8465 18.1529 13.8465H17.8438C17.2675 13.8465 16.8038 14.3254 16.8038 14.9133V21.1556C16.8038 21.7469 17.2707 22.2225 17.8438 22.2225ZM10.0645 27.3138C10.0645 27.3129 10.0652 27.3121 10.0662 27.3121H13.1588C14.1055 27.3121 14.8718 26.526 14.8718 25.555V14.3023C14.8718 13.0274 15.8249 11.9837 17.0389 11.8714C17.1548 11.8615 17.2481 11.7657 17.2481 11.6468V6.59678C17.2481 6.47127 17.1451 6.36558 17.0228 6.36888C11.4298 6.49109 6.93474 11.1811 6.93474 16.9478V24.1051C6.93474 25.8781 8.33451 27.3145 10.0628 27.3154C10.0638 27.3154 10.0645 27.3147 10.0645 27.3138Z"
          fill="url(#paint0_linear_2268_302393)"
        />
        <path
          d="M22.8402 26.5588L22.8411 26.5588C22.8643 26.5589 22.8873 26.56 22.9101 26.5621H25.9355C27.2325 26.5621 28.3153 25.4793 28.3153 24.1018V16.9445C28.3153 11.7581 24.4188 7.53768 19.5019 7.14314V11.2012C20.8773 11.5463 21.8782 12.8143 21.8782 14.299V25.5517C21.8782 26.1263 22.3261 26.5583 22.8402 26.5588ZM19.025 11.1208L19.0282 11.1211L19.025 11.1208ZM14.1218 14.3023C14.1218 12.8157 15.1243 11.552 16.4981 11.2053V7.14612C11.5817 7.5379 7.68474 11.7608 7.68474 16.9478V24.1051C7.68474 25.4612 8.73409 26.5317 10.0042 26.5646C10.0246 26.563 10.0453 26.5621 10.0662 26.5621H13.1588C13.6734 26.5621 14.1218 26.1299 14.1218 25.555V14.3023ZM17.043 7.11862L17.0391 7.11871L17.043 7.11862ZM33.75 17.7207V38.2181C33.75 45.6723 27.852 51.75 20.5389 51.75H15.4611C8.14805 51.75 2.25 45.6723 2.25 38.2181V17.7207C2.25 9.19302 8.99167 2.25 17.3512 2.25H18.652C27.0084 2.25 33.75 9.19313 33.75 17.7207ZM17.8438 21.4725H18.1529C18.2957 21.4725 18.443 21.349 18.443 21.1556V14.9133C18.443 14.7198 18.2955 14.5965 18.1529 14.5965H17.8438C17.701 14.5965 17.5538 14.72 17.5538 14.9133V21.1556C17.5538 21.3492 17.7013 21.4725 17.8438 21.4725Z"
          stroke="#1F1F1F"
          strokeWidth="1.5"
        />
      </g>
      <g filter="url(#filter1_d_2268_302393)">
        <path
          d="M22.8412 27.3123H25.9355C27.6646 27.3123 29.0653 25.8755 29.0653 24.1019V16.9447C29.0653 11.1779 24.5703 6.49123 18.9773 6.36572C18.8549 6.36572 18.7519 6.46811 18.7519 6.59362V11.6436C18.7519 11.7658 18.8453 11.8583 18.9612 11.8682C20.1751 11.9772 21.1282 13.0242 21.1282 14.2991V25.5519C21.1282 26.5229 21.8945 27.309 22.8412 27.309V27.3123ZM17.8439 22.2226H18.153C18.7294 22.2226 19.193 21.7437 19.193 21.1558V14.9134C19.193 14.3222 18.7261 13.8466 18.153 13.8466H17.8439C17.2675 13.8466 16.8039 14.3255 16.8039 14.9134V21.1558C16.8039 21.747 17.2707 22.2226 17.8439 22.2226ZM10.0646 27.3123H13.1589C14.1056 27.3123 14.8719 26.5262 14.8719 25.5552V14.3024C14.8719 13.0275 15.825 11.9838 17.0389 11.8715C17.1548 11.8616 17.2482 11.7658 17.2482 11.6469V6.59692C17.2482 6.47141 17.1452 6.36572 17.0228 6.36903C11.4298 6.49123 6.93479 11.1812 6.93479 16.948V24.1052C6.93479 25.8788 8.33546 27.3156 10.0646 27.3156V27.3123Z"
          fill="#F5F5F5"
        />
      </g>
      <g filter="url(#filter2_d_2268_302393)">
        <path
          className={styles['button-animation']}
          d="M13.1589 27.312H10.0645V27.3153C8.33543 27.3153 6.93476 25.8785 6.93476 24.1049V16.9477C6.93476 11.1809 11.4298 6.49093 17.0228 6.36873C17.1451 6.36542 17.2482 6.47112 17.2482 6.59662V11.6466C17.2482 11.7655 17.1548 11.8613 17.0389 11.8712C15.825 11.9835 14.8719 13.0272 14.8719 14.3021V25.5549C14.8719 26.5259 14.1055 27.312 13.1589 27.312Z"
          fill="#999999"
        />
      </g>
      <defs>
        <filter
          id="filter0_di_2268_302393"
          x="0.235714"
          y="0.235714"
          width="35.5286"
          height="53.5286"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.632143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2268_302393" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2268_302393" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.632143" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.375 0 0 0 0 0.375 0 0 0 0 0.375 0 0 0 0.22 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2268_302393" />
        </filter>
        <filter
          id="filter1_d_2268_302393"
          x="6.93481"
          y="6.36572"
          width="22.7626"
          height="21.5818"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.632143" dy="0.632143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.375 0 0 0 0 0.375 0 0 0 0 0.375 0 0 0 0.18 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2268_302393" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2268_302393" result="shape" />
        </filter>
        <filter
          id="filter2_d_2268_302393"
          x="6.93481"
          y="6.36865"
          width="10.9455"
          height="21.5789"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="0.632143" dy="0.632143" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.375 0 0 0 0 0.375 0 0 0 0 0.375 0 0 0 0.18 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2268_302393" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2268_302393" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_2268_302393" x1="18" y1="3" x2="18" y2="51" gradientUnits="userSpaceOnUse">
          <stop offset="0.0572917" stopColor="#545454" />
          <stop offset="0.244792" stopColor="#707070" />
          <stop offset="0.578125" stopColor="#737373" />
          <stop offset="0.932292" stopColor="#545454" />
        </linearGradient>
      </defs>
    </svg>
  );
};
