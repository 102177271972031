/** Types */
import { IconProps } from '@/types';

export const TrblArrowLeft = ({ fill = '#DADADA', width = '16', height = '16' }: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 16" fill="none">
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.898306 6.88999L7.37174 0.329993C7.61392 0.117218 7.96124 0.065086 8.25707 0.197108C8.5529 0.329129 8.74135 0.620358 8.73744 0.939464V5.2873H13.9C14.7146 5.2873 15.375 5.94769 15.375 6.76231V8.23731C15.375 9.05193 14.7146 9.71231 13.9 9.71231H8.73744V14.0605C8.74135 14.3796 8.5529 14.6709 8.25707 14.8029C7.96124 14.9349 7.61392 14.8828 7.37174 14.67L0.898306 8.10947C0.724165 7.95286 0.625 7.73163 0.625 7.49973C0.625 7.26784 0.724165 7.0466 0.898306 6.88999Z"
        fill={fill}
      />
    </g>
  </svg>
);
