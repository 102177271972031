import { FC } from 'react';

/** Types */
import { IconProps } from '@/types';

export const TrblPlayIcon: FC<IconProps> = ({ height = '10', width = '9' }) => (
  <svg width={width} height={height} viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_3171_170275)">
      <path
        d="M9.41726 5.66042L3.69034 1.24251C3.40859 1.02516 3 1.226 3 1.58185V10.4177C3 10.7735 3.40859 10.9744 3.69034 10.757L9.41726 6.33909C9.63966 6.16753 9.63966 5.83199 9.41726 5.66042Z"
        fill="#DADADA"
      />
      <path
        d="M9.41726 5.66042L3.69034 1.24251C3.40859 1.02516 3 1.226 3 1.58185V10.4177C3 10.7735 3.40859 10.9744 3.69034 10.757L9.41726 6.33909C9.63966 6.16753 9.63966 5.83199 9.41726 5.66042Z"
        stroke="white"
        strokeWidth="0.428571"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_3171_170275"
        x="2.78613"
        y="0.937805"
        width="7.44127"
        height="10.5525"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.428571" dy="0.428571" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.375 0 0 0 0 0.375 0 0 0 0 0.375 0 0 0 0.18 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3171_170275" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3171_170275" result="shape" />
      </filter>
    </defs>
  </svg>
);
