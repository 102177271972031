import { AuralizationPresetDto } from '@/components/AuralizerPresets/types';
import { AuralizerSimulationDto } from '../types';

export const getOrderedSimulations = (
  simulations: AuralizerSimulationDto[] | undefined,
  preset: AuralizationPresetDto | undefined
) => {
  if (simulations && preset) {
    const sortedSimulations = simulations.sort((a, b) => {
      const aIndex = preset.simulations.indexOf(a.id);
      const bIndex = preset.simulations.indexOf(b.id);

      return aIndex - bIndex;
    });
    return sortedSimulations;
  }
};
