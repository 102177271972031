import axios from '@/axios';
import { useQuery } from '@tanstack/react-query';

/** Types */
import { AuralizationPresetDto } from '@/components/AuralizerPresets/types';
import { Material } from '@/types';
import { AuralizerSimulationDto } from '../types';

export type GetSharedAuraliztionRequest = {
  auralizationShareAccessId: string;
  token: string;
};

export type SharedAuralizationDto = {
  auralizationPreset: AuralizationPresetDto;
  simulations: AuralizerSimulationDto[];
  materials: Material[];
};

const getAuralizationData = async (
  auralizationShareAccessId: string,
  token: string
): Promise<SharedAuralizationDto> => {
  const { data } = await axios.post(`/api/SharedAuralization/GetSharedAuralization`, {
    auralizationShareAccessId,
    token,
  });
  return data;
};

export const useGetSharedAuralization = (shareId: string | null, token: string | null, onError?: () => void) => {
  const response = useQuery<SharedAuralizationDto>(
    ['auralizationData', shareId, token],
    () => getAuralizationData(shareId!, token!),
    {
      enabled: !!(shareId && token),
      refetchOnWindowFocus: false,
      onError,
    }
  );

  return response;
};
