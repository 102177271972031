import { sum } from 'lodash';

import { A, OCTAVE_BANDS } from './constants';

// Calculates the distance between two points in 3D space
export const calculateDistance = (point1: [number, number, number], point2: [number, number, number]) => {
  const [x1, y1, z1] = point1;
  const [x2, y2, z2] = point2;

  const squaredDiffX = (x2 - x1) ** 2;
  const squaredDiffY = (y2 - y1) ** 2;
  const squaredDiffZ = (z2 - z1) ** 2;

  const distance = Math.sqrt(squaredDiffX + squaredDiffY + squaredDiffZ);
  return distance;
};

/**
 * Calculates the slope and intercept of a linear regression model
 * that fits the given x and y data points using the least squares method.
 *
 * @param xValues - Array of x values (independent variable)
 * @param yValues - Array of y values (dependent variable)
 * @returns An object containing the calculated slope and intercept.
 */
export const linearRegression = (xValues: number[], yValues: number[]): { slope: number; intercept: number } => {
  const xMean = xValues.reduce((sum, x) => sum + x, 0) / xValues.length;
  const yMean = yValues.reduce((sum, y) => sum + y, 0) / yValues.length;

  let numerator = 0;
  let denominator = 0;
  for (let i = 0; i < xValues.length; i++) {
    numerator += (xValues[i] - xMean) * (yValues[i] - yMean);
    denominator += Math.pow(xValues[i] - xMean, 2);
  }

  const slope = numerator / denominator;
  const intercept = yMean - slope * xMean;

  return { slope, intercept };
};

// Calculate A-weighted SPL of speech in position L_p_A_S_n, formula (5) in ISO 3382-3
export const calculate_L_p_A_S_n = (L_p_S_n_i: number[][], n: number) => {
  let sum = 0;
  for (let i = 0; i < OCTAVE_BANDS.length; i++) {
    const val = L_p_S_n_i[n][i];

    // We need to make sure we have valid values for each octave band.
    // To be decided if we should stil calculate or display some warning to the user
    if (!isNaN(val)) {
      sum += Math.pow(10, (L_p_S_n_i[n][i] + A[i]) / 10);
    }
  }

  return 10 * Math.log10(sum);
};

// Spatial decay rate of speech
// Calculated from formula (6) in ISO 3382-3
export const calculate_D_2_S = (L_p_A_S_n: number[], r_n: number[]) => {
  // Number of receivers
  const N = r_n.length;

  const D_2_S =
    -Math.log10(2) *
    ((N * sum(L_p_A_S_n.map((x, i) => x * Math.log10(r_n[i]))) - sum(L_p_A_S_n) * sum(r_n.map((x) => Math.log10(x)))) /
      (N * sum(r_n.map((x) => Math.pow(Math.log10(x), 2))) - Math.pow(sum(r_n.map((x) => Math.log10(x))), 2)));

  return D_2_S;
};

// Speech level at 4m distance, formula (7) in ISO 3382-3
export const calculate_L_p_A_S_4m = (L_p_A_S_n: number[], r_n: number[], D_2_S: number) => {
  // Number of receivers
  const N = r_n.length;

  const L_p_A_S_4m = sum(L_p_A_S_n) / N + (D_2_S * sum(r_n.map((x) => Math.log2(x / 4)))) / N;

  return L_p_A_S_4m;
};

// Comfort distance, formula (8) in ISO 3382-3
export const calculate_r_C = (L_p_A_S_4m: number, D_2_S: number) => {
  const r_C = Math.pow(2, (L_p_A_S_4m - 45 + 2 * D_2_S) / D_2_S);

  return Math.max(r_C, 0);
};

// Calculates the distraction distance (find where STI hits 0.5)
export const calculate_r_D = (r_n: number[], sti_nc_n: number[]) => {
  const { slope, intercept } = linearRegression(r_n, sti_nc_n);

  const r_D = (0.5 - intercept) / slope;

  return Math.max(r_D, 0);
};

// Calculates the A-Weighted SPL of the background noise Lp,A,B
export const calculate_L_p_A_B = (nc: number[]) => {
  let L_p_A_B_sum = 0;
  for (let i = 0; i < OCTAVE_BANDS.length; i++) {
    L_p_A_B_sum += Math.pow(10, (nc[i] + A[i]) / 10);
  }
  const L_p_A_B = 10 * Math.log10(L_p_A_B_sum);

  return L_p_A_B;
};
