import styles from './styles.module.scss';

export const getClassNameForTransition = (transition: 'left-in' | 'left-out' | 'right-in' | 'right-out' | null) => {
  if (transition) {
    if (transition === 'left-in') {
      return styles['start-left'];
    } else if (transition === 'left-out') {
      return styles['slide-out-left'];
    } else if (transition === 'right-in') {
      return styles['start-right'];
    } else if (transition === 'right-out') {
      return styles['slide-out-right'];
    }
  } else {
    return styles['slide-in'];
  }
};
