import { RecUrl, SimSrcRecHash } from '@/components/Auralizer/types';
import { Source } from '@/types';

export const getAudioSettings = (
  recUrl: RecUrl,
  simSrcRecHash: SimSrcRecHash,
  simId: string,
  originalSimId: string
) => {
  let audioSettings: {
    [sourceId: string]: {
      normFactor: number;
    };
  } = {};

  for (const sourceId in recUrl) {
    const srcHash = getSourceHashById(simId, sourceId, simSrcRecHash);
    if (srcHash) {
      const originalSourceId = simSrcRecHash[originalSimId]['srcHmap'][srcHash];
      for (const receiverId in recUrl[sourceId]) {
        audioSettings = {
          ...audioSettings,
          [originalSourceId]: { normFactor: recUrl[sourceId][receiverId].normalizationFactor },
        };
        break;
      }
    }
  }
  return audioSettings;
};

const getSourceHashById = (simId: string, sourceId: string, simSrcRecHash: SimSrcRecHash) => {
  try {
    let map = simSrcRecHash[simId]?.srcHashes;
    return Object.keys(map).find((key) => map[key].id === sourceId);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getAudioSettingsForStandalone = (recUrl: RecUrl, originalSources: Source[], currentSources: Source[]) => {
  let audioSettings: {
    [sourceId: string]: {
      normFactor: number;
    };
  } = {};

  for (const sourceId in recUrl) {
    const currentSource = currentSources.find((source) => source.id === sourceId);
    const originalSource = originalSources.find((source) => source.orderNumber === currentSource?.orderNumber);
    if (originalSource) {
      for (const receiverId in recUrl[sourceId]) {
        audioSettings = {
          ...audioSettings,
          [originalSource.id]: { normFactor: recUrl[sourceId][receiverId].normalizationFactor },
        };
        break;
      }
    }
  }
  return audioSettings;
};
