import { FC, useEffect } from 'react';

import styles from './styles.module.scss';
import { TrblChevronLeft, TrblChevronRight } from '@/components/Icons';

type StepArrowProps = {
  direction: 'left' | 'right';
  hidden: boolean;
  onStep: () => void;
};

export const StepArrow: FC<StepArrowProps> = ({ direction, onStep, hidden }) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!hidden) {
        if (direction === 'left') {
          if (event.key === 'ArrowLeft') {
            onStep();
          }
        } else if (event.key === 'ArrowRight') {
          onStep();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onStep]);

  return (
    <div className={styles['step-arrow']}>
      {!hidden ? (
        <span onClick={onStep}>{direction === 'left' ? <TrblChevronLeft /> : <TrblChevronRight />}</span>
      ) : null}
    </div>
  );
};
