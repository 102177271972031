/** Types */
import { ChannelBuffers } from '@/components/Auralizer/types';

const utf8decoder = new TextDecoder();

export const deinterleaveBuffers = (myBuffer: ArrayBuffer): ChannelBuffers | undefined => {
  // explanation: https://www.mmsp.ece.mcgill.ca/Documents/AudioFormats/WAVE/WAVE.html

  // uint8_bufferView shows the data as Uint8 (bytes)
  const uint8_bufferView = new Uint8Array(myBuffer);
  // this is the actual buffer data
  const arrayBuffer = uint8_bufferView.buffer;

  // search the data chunk among the first 70 bytes
  const searchString = utf8decoder.decode(uint8_bufferView.slice(0, 70));

  // find the index of the byte with 'data'
  let dataIndex = searchString.indexOf('data');

  if (dataIndex >= 0) {
    if (dataIndex % 2 != 0) {
      dataIndex = dataIndex + 1;
    }
  }
  if (dataIndex === -1) {
    console.warn('Data tag not found in the input buffer.');
    return undefined;
  }

  const partialBuffer = arrayBuffer.slice(0, dataIndex);

  const uint16 = new Uint16Array(partialBuffer, 0);

  const numberOfChannels = uint16[11];

  const dataType = uint16[10];

  const dataChunk = utf8decoder.decode(uint8_bufferView.slice(dataIndex, dataIndex + 4));
  // make sure data includes the whole word "data"
  if (dataChunk !== 'data') {
    console.warn('Unexpected formatting for audio data');
    return undefined;
  }

  // chunk size always follows data tag
  const dataSize = arrayBuffer.slice(dataIndex + 4, dataIndex + 8);
  const subChunk2Size = new Uint32Array(dataSize);

  const bytePadding = subChunk2Size[0] % 2;

  if (bytePadding !== 0) {
    console.warn('Audio Format not supported');
    return undefined;
  }

  let bufferData: Int16Array | Float32Array;
  let length;

  if (dataType == 1) {
    length = subChunk2Size[0] / numberOfChannels / 2;

    // may happen only with 8 and 24 bit audio
    bufferData = new Int16Array(arrayBuffer.slice(dataIndex + 8));
  } else if (dataType == 3) {
    // 32 bit
    length = subChunk2Size[0] / numberOfChannels / 4;

    if (arrayBuffer.slice(dataIndex + 8).byteLength % 4 !== 0) {
      console.warn('Padding might be needed');
    }

    // skip data tag and subchunksize
    bufferData = new Float32Array(arrayBuffer.slice(dataIndex + 8));
  }

  // @ts-ignore
  if (!bufferData) {
    console.warn('Unable to parse buffer data.');
    return undefined;
  }

  let ChannelBuffers: ChannelBuffers = { buffers: [], datatype: dataType };

  for (let n = 0; n < numberOfChannels; n++) {
    const channelBuffer =
      dataType === 1 ? Int16Array.from(new Array(length).fill(0)) : Float32Array.from(new Array(length).fill(0));
    // @ts-ignore
    ChannelBuffers.buffers.push(channelBuffer);
  }

  for (let frame = 0; frame < bufferData.length / numberOfChannels; frame++) {
    for (let n = 0; n < numberOfChannels; n++) {
      const sample = frame * numberOfChannels + n;
      ChannelBuffers.buffers[n][frame] = bufferData[sample];
    }
  }

  return ChannelBuffers;
};
