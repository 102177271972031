import { FC } from 'react';

import { IconProps } from '@/types';

export const TrblChevronDownIcon: FC<IconProps> = ({ fill, width = 12, height = 8, props }) => (
  <svg {...props} width={width} height={height} viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 1.42847L6 6.57132L1.5 1.42847"
      stroke={fill || '#F5F5F5'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
