import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Vector3 } from 'three';

/** Components */
import { Controls } from '@/components/AuralizerStandalone/Controls';
import { Header } from '@/components/AuralizerStandalone/Header';
import { Viewport } from '@/components/AuralizerStandalone/Viewport/Viewport';
import { WaveSpinner } from '@/components/Lottie';
import { ControlButton } from '@/components/Shared/Auralizer';
import { TrblPauseIcon, TrblPlayIcon } from '@/components/Icons';
import { AudioSource } from '@/components/AuralizerStandalone/Controls/AudioSource';
import { TutorialPopup } from '@/components/AuralizerStandalone/TutorialPopup';

/** Types */
import { AuralizerSimulationDto, TaskResultsForTaskGroup } from '@/components/AuralizerStandalone/types';
import { SourceResults } from '@/types';

/** Context */
import { ModelActionType, useModelContext } from '@/context/ModelContext';

/** Hooks */
import {
  useGetSharedAuralization,
  useGetSharedAuralizationSimulationRunResults,
} from '@/components/AuralizerStandalone/hooks';
import { useLoadAndExtractFilesFromUrls } from '@/hooks/useLoadAndExtractFilesFromUrls';

/** Utils */
import { getLayerColors } from '@/components/Editor/components/Viewport/utils';
import { mapToTaskResultsForReceivers } from '@/components/AuralizerStandalone/utils/mapToTaskResultsForReceivers';
import { getOrderedSourceResults } from '@/components/AuralizerStandalone/utils/getOrderedSourceResults';
import { getOrderedSimulations } from '@/components/AuralizerStandalone/utils/getOrderedSimulations';

/** Class */
import { StandaloneAudioEngine } from '@/components/AuralizerStandalone/StandaloneAudioEngine';

/** Styles */
import styles from './styles.module.scss';

export const AuralizerPage = () => {
  const { dispatch: modelDispatch, addModelFromFile, isModelLoaded, currentModel3dLayerGroups } = useModelContext();

  const [isModelRendered, setIsModelRendered] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const [isPlaying, setIsPlaying] = useState<boolean | undefined>(undefined);

  const [searchParams, _] = useSearchParams();
  const accessId = searchParams.get('accessId');
  const token = searchParams.get('token');

  const navigate = useNavigate();

  const handleError = () => {
    navigate('/error');
  };

  useEffect(() => {
    if (!accessId || !token) {
      handleError();
    }
  }, [accessId, token]);

  const { data } = useGetSharedAuralization(accessId, token, handleError);
  useLoadAndExtractFilesFromUrls(
    /** Load all distinct models and add to context */
    data?.simulations.reduce((acc: { modelId: string; url: string }[], curr) => {
      if (acc.findIndex((x) => x.modelId === curr.modelId) > -1) {
        return [...acc];
      } else {
        return [...acc, { modelId: curr.modelId, url: curr.modelUrl }];
      }
    }, []) ?? [],
    (modelId, data) => {
      addModelFromFile(modelId, data);
    }
  );

  const results = useGetSharedAuralizationSimulationRunResults(
    data?.simulations.map((x) => x.latestSimulationRun.id) ?? [],
    accessId,
    token
  );

  const [taskResultsForReceivers, setTaskResultsForReceivers] = useState<TaskResultsForTaskGroup>();
  const [orderedSourceResults, setOrderedSourceResults] = useState<SourceResults[] | undefined>([]);

  useEffect(() => {
    if (!taskResultsForReceivers && results.length && results.every((x) => !x.isLoading && !x.isError && x.data)) {
      setTaskResultsForReceivers(mapToTaskResultsForReceivers(results, data?.simulations));
      setOrderedSourceResults(getOrderedSourceResults(results));
    }
  }, [results]);

  const orderedSimulations = getOrderedSimulations(data?.simulations, data?.auralizationPreset);

  useEffect(() => {
    if (orderedSimulations && taskResultsForReceivers) {
      initializeAudioEngine(orderedSimulations, taskResultsForReceivers);
    }
  }, [orderedSimulations, taskResultsForReceivers]);

  const audioEngine = StandaloneAudioEngine.getInstance();

  const initializeAudioEngine = async (
    orderedSimulations: AuralizerSimulationDto[],
    taskResultsForReceivers: TaskResultsForTaskGroup
  ) => {
    audioEngine.orderedSimulations = orderedSimulations;
    audioEngine.taskResultsForReceivers = taskResultsForReceivers;

    const audioSettings = await audioEngine.getAudioSettingsObject(orderedSimulations, taskResultsForReceivers);

    await audioEngine.getReceiverConvolvers(orderedSimulations, taskResultsForReceivers, audioSettings);

    setIsLoadingData(false);
  };

  const [selectedSimulationId, setSelectedSimulationId] = useState<string | null>(null);
  const selectedSimulation = data?.simulations.find((sim) => sim.id === selectedSimulationId);

  const [selectedReceiverId, setSelectedReceiverId] = useState<string | null>(null);
  const selectedReceiver = selectedSimulation?.latestSimulationRun.receivers.find(
    (rec) => rec.id === selectedReceiverId
  );

  const firstSource = selectedSimulation?.latestSimulationRun.sources[0];

  // Initialize the selected simulation and receiver
  useEffect(() => {
    if (data?.simulations.length) {
      setSelectedSimulationId(data.simulations[0].id);
      setSelectedReceiverId(data.simulations[0].latestSimulationRun.receivers[0].id);
    }
  }, [data?.simulations]);

  useEffect(() => {
    if (selectedSimulation?.modelId) {
      modelDispatch({
        type: ModelActionType.SET_CURRENT_MODEL_ID,
        modelId: selectedSimulation.modelId,
      });
    }
  }, [selectedSimulation?.modelId]);

  const currentPosition = useMemo(
    () => (selectedReceiver ? new Vector3(selectedReceiver.x, selectedReceiver.y, selectedReceiver.z) : null),
    [selectedReceiverId]
  );

  const lookingAt = useMemo(
    () => (firstSource ? new Vector3(firstSource.x, firstSource.y, firstSource.z) : null),
    [firstSource?.x, firstSource?.y, firstSource?.z]
  );

  return (
    <div className={styles['page-layout']}>
      {data ? (
        <Header presetName={data.auralizationPreset.name} presetDescription={data.auralizationPreset.description} />
      ) : null}
      <div className={styles['viewport-container']}>
        <div className={`${styles['loading-overlay']} ${isModelRendered && !isLoadingData ? styles['hidden'] : ''}`}>
          <WaveSpinner />
        </div>
        {isModelLoaded && selectedSimulation?.latestSimulationRun && lookingAt && currentPosition ? (
          <Viewport
            updateStartAngle={audioEngine.updateStartAngle}
            sources={selectedSimulation.latestSimulationRun.sources}
            receivers={selectedSimulation.latestSimulationRun.receivers}
            layerGroups={currentModel3dLayerGroups ?? []}
            layerColors={getLayerColors(
              selectedSimulation.latestSimulationRun.modelSettings.materialIdByObjectId,
              data?.materials ?? []
            )}
            currentPosition={currentPosition}
            lookingAt={lookingAt}
            onModelRendered={() => setTimeout(() => setIsModelRendered(true), 2000)} // Add two second delay to guarantee no flickering on the loading spinner
          />
        ) : null}
      </div>
      {data && selectedSimulationId && selectedReceiverId ? (
        <Controls
          simulations={orderedSimulations}
          selectedSimulationId={selectedSimulationId}
          onSimulationChanged={setSelectedSimulationId}
          selectedReceiverId={selectedReceiverId}
          onReceiverChanged={setSelectedReceiverId}>
          <ControlButton
            style={{ width: 52, height: 52 }}
            onClick={() => setIsPlaying(!isPlaying)}
            icon={isPlaying ? <TrblPauseIcon width="12" height="18" /> : <TrblPlayIcon width="12" height="18" />}
          />
          {!isLoadingData &&
            orderedSourceResults?.map((sourceResults, index: number) => (
              <AudioSource
                key={sourceResults.sourcePointId}
                selectedSimulation={orderedSimulations?.find((sim) => sim.id === selectedSimulationId)}
                sourceId={sourceResults.sourcePointId}
                sourceSettings={data.auralizationPreset.mixerSettings.sourceSettings[index]}
                masterVolume={data.auralizationPreset.mixerSettings.masterVolume}
                isPlayingAudio={isPlaying}
                selectedReceiverId={selectedReceiverId}
              />
            ))}
        </Controls>
      ) : null}
      {isModelRendered && !isLoadingData ? <TutorialPopup /> : null}
    </div>
  );
};
