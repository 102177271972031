/** Hooks */
import { useReducer, createContext, useContext, ReactNode, useMemo, useEffect } from 'react';

/** Context */
import { useSimulationContext } from '@/context/SimulationContext';
import { useEditorContext } from '@/context/EditorContext';
import { useAppContext } from '@/context/AppContext';

/** Types */
import { Material, MaterialLayer } from '@/types';
import { Source } from '@/context/EditorContext/types';
import { SourceDefinition } from '@/types';

import { EMPTY_GUID } from '@/components/Shared/constants';

enum ActionType {
  SET_MATERIALS_PANEL_OPEN = 'SET_MATERIALS_PANEL_OPEN',
  SET_HIGHLIGHTED_ITEM = 'SET_HIGHLIGHTED_ITEM',
  CLOSE_LIBRARY_PANEL = 'CLOSE_LIBRARY_PANEL',
  SET_SOURCE_DEFINITION_PANEL_OPEN = 'SET_SOURCE_DEFINITION_PANEL_OPEN',
  SET_SELECTED_SOURCE = 'SET_SELECTED_SOURCE',
}

type LibraryPanelAction =
  | {
      type: ActionType.SET_MATERIALS_PANEL_OPEN;
      isOpen: boolean;
      material: Material | null;
      highlightedItemId: string | null;
    }
  | {
      type: ActionType.SET_HIGHLIGHTED_ITEM;
      highlightedItemId: string | null;
    }
  | {
      type: ActionType.CLOSE_LIBRARY_PANEL;
    }
  | {
      type: ActionType.SET_SOURCE_DEFINITION_PANEL_OPEN;
      isOpen: boolean;
    }
  | {
      type: ActionType.SET_SELECTED_SOURCE;
      source: Source | null;
      sourceDefinition: SourceDefinition | null;
    };

type State = {
  isMaterialsLibraryOpen: boolean;
  isSourceDefinitionLibraryOpen: boolean;
  selectedMaterial: Material | null;
  selectedSourceDefinition: SourceDefinition | null;
  highlightedItemId: string | null;
  selectedLayer: MaterialLayer | null;
  selectedSource: Source | null;
  previouslyAssignedMaterial: Material | null;
  dispatch: (action: LibraryPanelAction) => void;
};

const initialState: State = {
  isMaterialsLibraryOpen: false,
  isSourceDefinitionLibraryOpen: false,
  selectedMaterial: null,
  selectedSourceDefinition: null,
  selectedLayer: null,
  selectedSource: null,
  highlightedItemId: null,
  previouslyAssignedMaterial: null,
  dispatch: () => {},
};

const LibraryPanelContext = createContext(initialState);

function handleUnknownAction(action: never): never;
function handleUnknownAction(action: LibraryPanelAction) {
  throw new Error(`Unhandled action type: ${action.type}`);
}

const libraryPanelReducer = (state: State, action: LibraryPanelAction): State => {
  switch (action.type) {
    case ActionType.SET_MATERIALS_PANEL_OPEN: {
      const previouslyAssignedMaterial =
        action.material?.name !== 'unassigned' ? action.material : state.previouslyAssignedMaterial;
      let highlightedItemId = action.highlightedItemId ?? state.highlightedItemId;
      if (!action.isOpen) highlightedItemId = null;

      return {
        ...state,
        isMaterialsLibraryOpen: action.isOpen,
        selectedMaterial: action.material,
        previouslyAssignedMaterial,
        highlightedItemId,
      };
    }

    case ActionType.CLOSE_LIBRARY_PANEL: {
      return {
        ...state,
        isMaterialsLibraryOpen: false,
        isSourceDefinitionLibraryOpen: false,
        highlightedItemId: null,
        selectedMaterial: null,
        previouslyAssignedMaterial: null,
        selectedSource: null,
        selectedSourceDefinition: null,
      };
    }

    case ActionType.SET_SOURCE_DEFINITION_PANEL_OPEN: {
      return {
        ...state,
        isSourceDefinitionLibraryOpen: action.isOpen,
      };
    }

    case ActionType.SET_HIGHLIGHTED_ITEM: {
      return {
        ...state,
        highlightedItemId: action.highlightedItemId,
      };
    }

    case ActionType.SET_SELECTED_SOURCE: {
      return {
        ...state,
        selectedSource: action.source,
        selectedSourceDefinition: action.sourceDefinition,
        highlightedItemId: action.sourceDefinition?.id ?? null,
      };
    }

    default: {
      handleUnknownAction(action);
    }
  }
};

type LibraryPanelProviderProps = { children: ReactNode };

const LibraryPanelProvider = ({ children }: LibraryPanelProviderProps) => {
  const [libraryPanelState, dispatch] = useReducer(libraryPanelReducer, initialState);
  const {
    simulationState: { surfaceLayers, selectedSimulation },
  } = useSimulationContext();
  const { selected, sources } = useEditorContext();
  const {
    appState: { filteredSourceDefinitions },
  } = useAppContext();

  useEffect(() => {
    // whenever selected simulation changes we want to reset the state
    dispatch({
      type: ActionType.CLOSE_LIBRARY_PANEL,
    });
  }, [selectedSimulation?.id]);

  useEffect(() => {
    if (libraryPanelState.isSourceDefinitionLibraryOpen) {
      if (selected?.type === 'SourcePoint') {
        const selectedSource = sources.find((source) => source.id === selected.id);
        let selectedSourceDefinition: SourceDefinition | undefined;
        // for backwards compatability, if a source is EMPTY_GUID, then it is a Omni
        if (selectedSource?.params.directivityPattern === EMPTY_GUID) {
          selectedSourceDefinition = filteredSourceDefinitions.find(
            (sourceDefinition) => sourceDefinition.name === 'Omni'
          );
        } else {
          selectedSourceDefinition = filteredSourceDefinitions.find(
            (sourceDefinition) => sourceDefinition.id === selectedSource?.params.directivityPattern
          );
        }
        if (selectedSource) {
          dispatch({
            type: ActionType.SET_SELECTED_SOURCE,
            source: selectedSource,
            sourceDefinition: selectedSourceDefinition ?? null,
          });
        }
      } else if (selected?.type === 'GridReceiver' || selected?.type === 'ReceiverPoint') {
        // If selected is receiver close the library panel (in case it was open)
        dispatch({
          type: ActionType.CLOSE_LIBRARY_PANEL,
        });
      }
    }
  }, [selected]);

  const selectedLayer = useMemo(() => {
    if (selected?.type === 'Layer') {
      return surfaceLayers.flatMap((s) => s.children).find((x) => x.id === selected.id) || null;
    } else if (selected?.type === 'LayerGroup' && surfaceLayers) {
      return surfaceLayers.find((x) => x.id === selected.id) || null;
    }
    return null;
  }, [selected, surfaceLayers]);

  const value = useMemo(() => {
    return {
      ...libraryPanelState,
      selectedLayer,
      dispatch,
    };
  }, [libraryPanelState, selectedLayer]);

  return <LibraryPanelContext.Provider value={value}>{children}</LibraryPanelContext.Provider>;
};

const useLibraryPanelContext = () => {
  const context = useContext(LibraryPanelContext);
  if (context === undefined) {
    throw new Error('useLibraryPanelContext must be used within LibraryPanelProvider');
  }
  return context;
};

export { LibraryPanelProvider, useLibraryPanelContext, ActionType };
