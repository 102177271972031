import axios from '@/axios';
import { useQueries } from '@tanstack/react-query';

import { SolveTask } from '@/types';

const getSharedAuralizationSimulationRunResult = async (
  simulationRunId: string,
  auralizationShareAccessId: string,
  token: string
): Promise<SolveTask> => {
  const { data } = await axios.post(`/api/SharedAuralization/GetSharedAuralizationSimulationRunResult`, {
    simulationRunId,
    auralizationShareAccessId,
    token,
  });
  return data;
};

export const useGetSharedAuralizationSimulationRunResults = (
  simulationRunIds: string[],
  shareId: string | null,
  token: string | null
) => {
  const results = useQueries({
    queries:
      shareId && token
        ? simulationRunIds.map((id) => ({
            queryKey: ['simulationRunResult', id],
            queryFn: () => getSharedAuralizationSimulationRunResult(id, shareId, token),
            refetchOnWindowFocus: false,
          }))
        : [],
  });

  return results;
};
