import { CSSProperties, FC } from 'react';

/** Styles */
import componentStyles from './styles.module.scss';

type ControlButtonProps = {
  icon: React.ReactElement;
  style?: CSSProperties;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const ControlButton: FC<ControlButtonProps> = ({ icon, style, ...props }) => (
  <button {...props} className={componentStyles['control-button']} style={style}>
    {icon}
  </button>
);
