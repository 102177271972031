import { useRouteError } from 'react-router-dom';

import { ErrorPage } from '../Error';

export const UnexpectedErrorPage = () => {
  const error = useRouteError();
  if (error) {
    console.error(error);
  }

  return <ErrorPage linkOptions={null} logoHref="https://www.treble.tech/" />;
};
