import styles from './styles.module.scss';

export const MobileUnsupported = () => {
  return (
    <div className={styles['page']}>
      <div className={styles['background']}>
        <div className={styles['image']} />
        <div className={styles['overlay']} />
      </div>
      <div className={styles['content']}>
        <div className={styles['text']}>
          <div className={styles['headline']}>
            <span className={styles['app']}>Treble Auralizer</span>
            <span className={styles['title']}>Mobile devices not supported</span>
          </div>
          <span className={styles['subtext']}>
            Currently we do not support mobile devices. Please try again on your computer.{' '}
          </span>
        </div>
      </div>
    </div>
  );
};
