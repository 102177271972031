import React, { ForwardedRef, useEffect, useState } from 'react';

import { TrblNumberInput } from '@/components/Shared/NumberInput';
import { TrblEditableLabel } from '../Shared/TrblEditableLabel';
import { Marker } from './Marker';
import {
  TrblAzimuthIcon,
  TrblElevationIcon,
  TrblIcon,
  TrblRotationIcon,
  TrblOpenLibraryIcon,
} from '@/components/Icons';
import { IconButton } from '../Shared/Buttons';
import { TrblTooltip } from '../Shared';
/** Types */
import { Source } from '@/context/EditorContext/types';
import { SourceDefinition } from '@/types';
import { DisplayMissingSourceDefinition } from './hooks/useGetDisplaySources';
import { EMPTY_GUID, ZEROS_ONE_GUID } from '@/components/Shared/constants';

import styles from './styles.module.scss';

type SourceRowProps = {
  source: Source;
  sourceDefinition: SourceDefinition | DisplayMissingSourceDefinition;
  index: number;
  isSelected: boolean;
  isDisabled: boolean;
  isNewPoint: boolean;
  onSelect: () => void;
  onViewSourceSettings: () => void;
  onOpenSourceDefinitionLibrary: () => void;
  onDelete: () => void;
  onChangeLabel: (label: string) => void;
  onChangeAxis: (axis: 'x' | 'y' | 'z', value?: number) => void;
  onChangeParam: (param: 'azimuth' | 'elevation' | 'rotation', value?: number) => void;
};

export const SourceRow = React.forwardRef(
  (
    {
      source,
      sourceDefinition,
      index,
      isSelected,
      isDisabled,
      isNewPoint,
      onSelect,
      onDelete,
      onViewSourceSettings,
      onOpenSourceDefinitionLibrary,
      onChangeAxis,
      onChangeParam,
      onChangeLabel,
    }: SourceRowProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [posX, setPosX] = useState<number | undefined>(source.x);
    const [posY, setPosY] = useState<number | undefined>(source.y);
    const [posZ, setPosZ] = useState<number | undefined>(source.z);
    const [azimuth, setAzimuth] = useState<number | undefined>(source.params.azimuth);
    const [elevation, setElevation] = useState<number | undefined>(source.params.elevation);
    const [rotation, setRotation] = useState<number | undefined>(source.params.rotation);

    // to handle when the position is changed with the viewportControls
    useEffect(() => {
      if (source.x !== posX) setPosX(source.x);
    }, [source.x]);
    useEffect(() => {
      if (source.y !== posY) setPosY(source.y);
    }, [source.y]);
    useEffect(() => {
      if (source.z !== posZ) setPosZ(source.z);
    }, [source.z]);

    /* 
    Needed since the key of the row (in parent component) is not working as expected. 
    First of all the sourceId is not unique since the id gets copied over when duplicating a simulation.
    Second of all we are not able to use the simulationId in combination with the sourceId as the key since the source hasn't been updated when simulationId is changed
    */
    useEffect(() => {
      if (source.params.azimuth !== azimuth) setAzimuth(source.params.azimuth);
    }, [source.params.azimuth]);
    useEffect(() => {
      if (source.params.elevation !== elevation) setElevation(source.params.elevation);
    }, [source.params.elevation]);
    useEffect(() => {
      if (source.params.rotation !== rotation) setElevation(source.params.rotation);
    }, [source.params.rotation]);

    const handlePosXBlur = (value: number | undefined) => {
      if (source.x !== value) {
        onChangeAxis('x', value);
      }
    };
    const handlePosYBlur = (value: number | undefined) => {
      if (source.y !== value) {
        onChangeAxis('y', value);
      }
    };
    const handlePosZBlur = (value: number | undefined) => {
      if (source.z !== value) {
        onChangeAxis('z', value);
      }
    };

    const handleAzimuthBlur = (value: number | undefined) => {
      if (source.params.azimuth !== value) {
        onChangeParam('azimuth', value);
      }
    };
    const handleElevationBlur = (value: number | undefined) => {
      if (source.params.elevation !== value) {
        onChangeParam('elevation', value);
      }
    };
    const handleRotationBlur = (value: number | undefined) => {
      if (source.params.rotation !== value) {
        onChangeParam('rotation', value);
      }
    };

    const handleLabelChange = (value: string) => {
      if (source.label !== value) {
        onChangeLabel(value);
      }
    };

    const handleContainerClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onSelect();
    };

    const handleDelete = (e: React.MouseEvent) => {
      e.stopPropagation();
      onDelete();
    };

    return (
      <div
        ref={ref}
        onClick={handleContainerClick}
        className={`${styles['row-container']} ${isSelected ? styles['selected'] : ''} ${
          isDisabled ? styles['disabled'] : ''
        }`}>
        <div style={{ padding: '2px 0', maxWidth: '130px', width: '100%' }}>
          <TrblEditableLabel
            label={source.label}
            placeholder={source.label ? undefined : `Source ${index + 1}`}
            editableOnRender={isNewPoint}
            onBlur={handleLabelChange}
          />
        </div>

        {sourceDefinition && (
          <button
            className={`${styles['extra-name']} ${
              sourceDefinition.hasOwnProperty('noLongerAvailable') ? styles['not-available'] : ''
            }`}
            onClick={onOpenSourceDefinitionLibrary}
            title="Source definition library">
            <span>
              {sourceDefinition.manufacturer && sourceDefinition.manufacturer + ': '} {sourceDefinition.name}
            </span>
            <TrblOpenLibraryIcon />
          </button>
        )}

        <div className={styles['settings-row']}>
          <Marker isSelected={isSelected} color="green" label={(index + 1).toString()} />
          <div className={styles['coordinate-inputs-wrapper']}>
            <TrblNumberInput
              value={posX}
              step={0.5}
              decimals={2}
              onChange={setPosX}
              onBlur={handlePosXBlur}
              startAdornment={'X'}
              alignment={'right'}
            />
            <TrblNumberInput
              value={posY}
              step={0.5}
              decimals={2}
              onChange={setPosY}
              onBlur={handlePosYBlur}
              startAdornment={'Y'}
              alignment={'right'}
            />
            <TrblNumberInput
              value={posZ}
              step={0.1}
              decimals={2}
              onChange={setPosZ}
              onBlur={handlePosZBlur}
              startAdornment={'Z'}
              alignment={'right'}
            />
          </div>
          <div className={styles['point-icons']}>
            {/* Until we have data to populate the detail popup when item is deleted/unshared we disable the button for those items */}
            <TrblTooltip
              title={
                sourceDefinition?.hasOwnProperty('noLongerAvailable')
                  ? 'Assign a new source definition to access sources settings'
                  : ''
              }>
              <span>
                <IconButton
                  disabled={sourceDefinition?.hasOwnProperty('noLongerAvailable')}
                  className={`${styles['icon-btn']} ${
                    sourceDefinition?.hasOwnProperty('noLongerAvailable') ? styles['disabled'] : ''
                  }`}
                  onClick={onViewSourceSettings}
                  title="Source settings"
                  icon={<TrblIcon icon="slider" hoverColor="#f5f5f5" />}
                />
              </span>
            </TrblTooltip>
            <TrblTooltip title="Delete source">
              <span>
                <IconButton
                  className={styles['icon-btn']}
                  onClick={handleDelete}
                  icon={<TrblIcon icon="delete" hoverColor="#f5f5f5" />}
                />
              </span>
            </TrblTooltip>
          </div>
        </div>
        {/* Only show Azimuth, Elevation and Rotation if source is not Omni */}
        {/* for backwards compatibility, a Omni source can both be EMPTY_GUID or ZEROS_ONE_GUID */}
        {source.params &&
          source.params.directivityPattern !== EMPTY_GUID &&
          source.params.directivityPattern !== ZEROS_ONE_GUID && (
            <div className={styles['settings-row']}>
              <div className={styles['param-inputs-wrapper']}>
                <TrblNumberInput
                  value={azimuth}
                  step={15}
                  decimals={2}
                  onChange={setAzimuth}
                  onBlur={handleAzimuthBlur}
                  startAdornment={<TrblAzimuthIcon />}
                  endAdornment={'°'}
                  alignment={'right'}
                  min={0}
                  max={360}
                />
                <TrblNumberInput
                  value={elevation}
                  step={15}
                  decimals={2}
                  onChange={setElevation}
                  onBlur={handleElevationBlur}
                  startAdornment={<TrblElevationIcon />}
                  endAdornment={'°'}
                  alignment={'right'}
                  min={-90}
                  max={90}
                />
                <TrblNumberInput
                  value={rotation}
                  step={15}
                  decimals={2}
                  onChange={setRotation}
                  onBlur={handleRotationBlur}
                  startAdornment={<TrblRotationIcon />}
                  endAdornment={'°'}
                  alignment={'right'}
                  min={0}
                  max={360}
                />
              </div>
            </div>
          )}
      </div>
    );
  }
);
