export const TrblCloseIcon = ({ stroke = '#DADADA', width = '13', height = '13' }) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8991 12.1336L6.99912 7.23361M6.99912 7.23361L2.09912 2.33361M6.99912 7.23361L11.8991 2.33361M6.99912 7.23361L2.09912 12.1336"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
