import { IconProps } from '@/types';
import { FC } from 'react';

export const TrblTimewatchUnfilledIcon: FC<IconProps> = ({ fill }) => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1768 4.62314L12.079 3.75648C12.2306 3.61588 12.2914 3.40767 12.238 3.21188C12.1846 3.01608 12.0254 2.86318 11.8214 2.81193C11.6175 2.76068 11.4006 2.81906 11.2541 2.96464L10.3208 3.86064C9.52231 3.2855 8.5761 2.92936 7.58321 2.83024V1.12H8.74987C9.07204 1.12 9.33321 0.869279 9.33321 0.56C9.33321 0.250721 9.07204 0 8.74987 0H5.24987C4.92771 0 4.66654 0.250721 4.66654 0.56C4.66654 0.869279 4.92771 1.12 5.24987 1.12H6.41654V2.83024C3.877 3.08879 1.85034 4.9799 1.50901 7.40953C1.16768 9.83916 2.60009 12.1781 4.97755 13.0733C7.35501 13.9684 10.0618 13.188 11.5321 11.1834C13.0021 9.17914 12.8553 6.47013 11.1768 4.62314ZM2.33301 8.11989C2.33301 10.5941 4.42235 12.5999 6.99967 12.5999C8.23735 12.5999 9.42434 12.1279 10.2995 11.2877C11.1747 10.4476 11.6663 9.30806 11.6663 8.11989C11.6663 5.64566 9.577 3.63989 6.99967 3.63989C4.42235 3.63989 2.33301 5.64566 2.33301 8.11989Z"
      fill={fill || '#DADADA'}
    />
    <path
      d="M7.30878 8.30029C7.1368 8.46134 6.86242 8.46134 6.69044 8.30029L5.49928 7.15789C5.32837 6.99382 5.32837 6.7278 5.49928 6.56373C5.67018 6.39966 5.94728 6.39966 6.11819 6.56373L7.30878 7.70725C7.39085 7.78593 7.43697 7.8927 7.43697 8.00405C7.43697 8.11539 7.39085 8.22217 7.30878 8.30085V8.30029Z"
      fill={fill || '#DADADA'}
    />
  </svg>
);
