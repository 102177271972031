// @ts-ignore because no declaration file
import Omnitone from 'omnitone/build/omnitone.min.esm.js';

/** Types */
import { AuralizerSimulationDto, ReceiverConvolvers, TaskResultsForTaskGroup } from './types';
import { AudioSettings, SoaRenderer } from '../Auralizer/types';

/** Helpers */
import { crossProduct, normalize } from '../Auralizer/utils';
import { getSourceReceiverConvolverPairs } from './utils/getSourceReceiverConvolverPairs';
import { int16ToFloat32 } from '@/components/Auralizer/aural/utils/int16ToFloat32';
import { getAudioSettingsForStandalone } from './utils/getAudioSettings';
import { getFirstSimNormMax } from './utils/getFirstSimNormMax';

export class StandaloneAudioEngine {
  private static instance: StandaloneAudioEngine;

  private constructor() {
    this.audioContext = new AudioContext({
      latencyHint: 'interactive',
      sampleRate: 32000,
    });
    this.soaRenderer = Omnitone.createHOARenderer(this.audioContext, {
      ambisonicOrder: 2,
    });

    this.soaRenderer.output.connect(this.audioContext.destination);
    this.soaRenderer.setRenderingMode('ambisonic');
    this.soaRenderer.initialize();
  }

  public static getInstance(): StandaloneAudioEngine {
    if (!StandaloneAudioEngine.instance) {
      StandaloneAudioEngine.instance = new StandaloneAudioEngine();
    }

    return StandaloneAudioEngine.instance;
  }

  audioContext: AudioContext;
  soaRenderer: SoaRenderer;

  orderedSimulations: AuralizerSimulationDto[] | undefined;
  taskResultsForReceivers: TaskResultsForTaskGroup | undefined;
  audioSettings: AudioSettings = {} as AudioSettings;
  receiverConvolvers: ReceiverConvolvers = {} as ReceiverConvolvers;

  getAudioSettingsObject = async (
    orderedSimulations: AuralizerSimulationDto[],
    taskResultsForReceivers: TaskResultsForTaskGroup
  ) => {
    let partialAudioSettings: {
      [simId: string]: {
        [sourceId: string]: {
          normFactor: number;
        };
      };
    } = {};
    const originalSources = orderedSimulations[0].latestSimulationRun.sources;
    orderedSimulations.forEach((simulation) => {
      partialAudioSettings = {
        ...partialAudioSettings,
        [simulation.id]: getAudioSettingsForStandalone(
          taskResultsForReceivers[simulation.id],
          originalSources,
          simulation.latestSimulationRun.sources
        ),
      };
    });
    // @ts-ignore Property 'firstSimNormMax' is incompatible with index signature.
    let audioSettings: AudioSettings = {
      ...partialAudioSettings,
      firstSimNormMax: {
        relMax: 1,
        rulingSource: null,
        firstSim: null,
      },
    };

    audioSettings = getFirstSimNormMax(audioSettings, orderedSimulations[0].id);
    this.audioSettings = audioSettings;

    return audioSettings;
  };

  getReceiverConvolvers = async (
    orderedSimulations: AuralizerSimulationDto[],
    taskResultsForReceivers: TaskResultsForTaskGroup,
    audioSettings: AudioSettings
  ) => {
    const newReceiverConvolvers: ReceiverConvolvers = await getSourceReceiverConvolverPairs(
      orderedSimulations,
      taskResultsForReceivers,
      audioSettings
    );
    this.receiverConvolvers = newReceiverConvolvers;
  };

  setScaledNormFactor = (value: number, simId: string, sourceId: string, receiverId: string) => {
    const unitValue = Math.pow(10, value / 20);
    const normFactor = this.audioSettings[simId]?.[sourceId].normFactor;
    if (normFactor && this.receiverConvolvers) {
      const rescaleGainBasedOnNormFactor = this.rescaleGainBasedOnNormFactor(unitValue, normFactor);
      if (rescaleGainBasedOnNormFactor) {
        // @ts-ignore The left-hand side of an assignment expression may not be an optional property access.
        this.receiverConvolvers[simId][receiverId][sourceId].audioNodes.inputGain.gain.value =
          rescaleGainBasedOnNormFactor;
      }
    }
  };

  updateStartAngle = (azimuth: number, elevation: number) => {
    const theta = azimuth;
    const phi = elevation;

    const forward = [Math.sin(theta) * Math.cos(phi), Math.sin(phi), Math.cos(theta) * Math.cos(phi)];

    const upInitial = [0, 1, 0];

    const right = normalize(crossProduct(forward, upInitial));
    const up = normalize(crossProduct(right, forward));

    const rotationMatrix3 = new Float32Array(9);
    rotationMatrix3[0] = right[0];
    rotationMatrix3[1] = right[1];
    rotationMatrix3[2] = right[2];
    rotationMatrix3[3] = up[0];
    rotationMatrix3[4] = up[1];
    rotationMatrix3[5] = up[2];
    rotationMatrix3[6] = forward[0];
    rotationMatrix3[7] = forward[1];
    rotationMatrix3[8] = forward[2];

    this.soaRenderer.setRotationMatrix3(rotationMatrix3);
  };

  createConvolver = (buffer: Buffer, dataType: number, sampleRate: number) => {
    let floatBuffer = null;

    if (dataType === 1) {
      floatBuffer = int16ToFloat32(buffer, 0, buffer.length);
    } else if (dataType === 3) {
      floatBuffer = buffer;
    }

    let convolver = this.audioContext.createConvolver();
    convolver.normalize = false;
    convolver.channelCount = 1;

    if (floatBuffer) {
      let audioBuffer = this.audioContext.createBuffer(1, floatBuffer?.length, sampleRate);
      audioBuffer.getChannelData(0).set(floatBuffer);
      convolver.buffer = audioBuffer;
    }
    return convolver;
  };

  private rescaleGainBasedOnNormFactor = (unitValue: number, scaling: number) => {
    let originalMax = this.audioSettings.firstSimNormMax.relMax;
    if (originalMax) {
      let ratio = scaling / originalMax;
      return unitValue * ratio;
    }
  };
}
