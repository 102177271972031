import { FC } from 'react';

/** Components */
import { Text } from '@/components/Shared/Text';
import { MouseSVG } from './MouseSVG';

/** Styles */
import styles from './styles.module.scss';

export const MouseStep: FC = () => {
  return (
    <div className={styles['step']}>
      <div className={styles['controls']}>
        <MouseSVG />
      </div>
      <Text type="regular-12px" style={{ textAlign: 'center', lineHeight: '160%', letterSpacing: '0.24px' }}>
        To look around, move your mouse while holding down the left mouse button.
      </Text>
    </div>
  );
};
