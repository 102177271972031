import { BufferGeometry, Mesh, MeshStandardMaterial, Object3D, DoubleSide, Material } from 'three';

/* Utils */
import { Rhino3dmLoader } from 'three/examples/jsm/loaders/3DMLoader';
// @ts-ignore
import rhino3dm from 'https://cdn.jsdelivr.net/npm/rhino3dm@7.15.0/rhino3dm.module.js';

/** Components */
import { toast } from 'react-toastify';

/** Types */
import { LayerUserAttribute, ModelFile, ModelLayerGroup } from './types';

export const parseModelAsObject3D = async (modelFile: ModelFile): Promise<Object3D> => {
  const loader = new Rhino3dmLoader();
  return new Promise((resolve, reject) => {
    loader.setLibraryPath('https://cdn.jsdelivr.net/npm/rhino3dm@7.15.0/');
    loader.parse(
      modelFile.fileData,
      (model) => {
        resolve(model);
      },
      () => {
        reject(new Error('Could not parse 3D model'));
      }
    );
  });
};

export const parseModelAs3dmFile = async (fileData: ArrayBufferLike): Promise<any> => {
  let file3dm = rhino3dm().then((rhino: any) => {
    const arrayBuffer = new Uint8Array(fileData);
    let doc = rhino.File3dm.fromByteArray(arrayBuffer);
    doc.settings().pageUnitSystem = rhino.UnitSystem.Meters;
    return doc;
  });
  return file3dm;
};

export const createLayerGroupsFromModel = (model3d: Object3D): Array<ModelLayerGroup> => {
  const layers = model3d.userData['layers'] as Array<LayerUserAttribute>;
  const modelLayerGroups: Array<ModelLayerGroup> = [];

  layers
    .filter((layer: any) => layer.fullPath !== 'Treble' && layer.fullPath !== 'Treble::Geometry')
    .forEach((layer) => {
      const surfaces: Array<Mesh<BufferGeometry, MeshStandardMaterial>> = (model3d.children.filter(
        (x) => x.type === 'Mesh' && layers[x.userData.attributes['layerIndex']].id === layer.id
      ) || []) as Array<Mesh<BufferGeometry, MeshStandardMaterial>>;

      // Give the meshes a name so we can destinguish from other meshes
      surfaces.forEach((s) => (s.name = 'Layer'));

      if (surfaces.length) {
        modelLayerGroups.push({
          id: layer.id,
          name: layer.name,
          children: surfaces,
        });
      }
    });

  return modelLayerGroups;
};

export const setupAreaAndGroupIntByObjectId = (file3dm: any) => {
  const rhinoObjects = file3dm.objects();

  const geometryGroupByGroupId = getRhinoGeometryGroupDict(file3dm);

  const areaByObjectId: Record<string, number> = {};
  const groupIntegerByObjectId: Record<string, number> = {};

  for (let i = 0; i < rhinoObjects.count; i++) {
    const rhinoObject = rhinoObjects.get(i);
    const rhonoObjectGeometry = rhinoObject.geometry();

    if (rhonoObjectGeometry.$$.ptrType.name === 'Mesh*') {
      const objectId = rhinoObject.attributes().id;
      const userStrings = rhonoObjectGeometry.getUserStrings();

      if (userStrings.length > 0) {
        areaByObjectId[objectId] = +userStrings.find((key: any) => key[0] === 'tech.treble.surface_area')[1];
        const geometryGroupId = userStrings.find((key: any) => key[0] === 'tech.treble.geometry_group_id')[1];
        groupIntegerByObjectId[objectId] = +geometryGroupByGroupId[geometryGroupId];
      }
    }
  }

  return {
    areaByObjectId,
    groupIntegerByObjectId,
  };
};

export const getInnerAndOuterMeshes = (meshes: Mesh[], groupIntegerByObjectId: Record<string, number>) => {
  const outerMeshes: Object3D[] = [];
  const innerMeshes: Object3D[] = [];

  meshes.forEach((mesh) => {
    (mesh.material as Material).side = DoubleSide;
    let meshId = mesh.userData.attributes.id;
    let groupInteger = groupIntegerByObjectId[meshId];

    if (groupInteger == 1) {
      outerMeshes.push(mesh);
    } else if (groupInteger == 2) {
      innerMeshes.push(mesh);
    }
  });

  return {
    innerMeshes,
    outerMeshes,
  };
};

const getRhinoGeometryGroupDict = (file3dm: any) => {
  const rhinoLayerTable = file3dm.layers();
  let geometryLayerIndex = null;

  for (let i = 0; i < rhinoLayerTable.count(); i++) {
    if (rhinoLayerTable.get(i).name === 'Geometry') {
      geometryLayerIndex = i;
      break;
    }
  }

  if (geometryLayerIndex === null) return;

  const geometryGroupByGroupIdStrings = rhinoLayerTable
    .get(geometryLayerIndex)
    .getUserStrings()
    .find((key: any) => key[0] === 'tech.treble.geometry_group_type_by_group_id');

  if (geometryGroupByGroupIdStrings === null) return;

  return JSON.parse(geometryGroupByGroupIdStrings[1]);
};

export const getModelVolume = (file3dm: any) => {
  let layers = file3dm.layers();
  let layer;

  let modelVolumes: any[] = [];

  for (var i = 0; i < layers.count(); i++) {
    layer = layers.findIndex(i);

    if (layer.name == 'Geometry' && layer.userStringCount > 0) {
      const volumeUserString = JSON.parse(layer.getUserString('tech.treble.volume_by_group_id'));
      modelVolumes = Object.keys(volumeUserString).map((key: any) => [key, volumeUserString[key]]);
      break;
    }
  }

  let totalVolume = 0;

  if (modelVolumes.length < 1) return totalVolume;

  if (modelVolumes.length > 1) {
    let volume;

    for (var i = 0; i < modelVolumes.length; i++) {
      volume = modelVolumes[i][1];

      if (volume > totalVolume) totalVolume = volume;
    }

    for (i = 0; i < modelVolumes.length; i++) {
      volume = modelVolumes[i][1];
      if (volume < totalVolume) totalVolume -= volume;
    }
  } else {
    totalVolume = modelVolumes[0][1];
  }

  if (totalVolume <= 0.01) {
    toast.warning('This model has too little volume');
  }

  return totalVolume;
};
