import { FC, useState } from 'react';

import { TrblIcon } from '@/components/Icons';

import styles from './styles.module.scss';

type TrblEditableLabelProps = {
  label: string;
  placeholder?: string;
  editableOnRender?: boolean;
  onBlur: (label: string) => void;
  fontSize?: string;
  showPencil?: boolean;
  noEmpty?: boolean;
};

export const TrblEditableLabel: FC<TrblEditableLabelProps> = ({
  label,
  placeholder,
  onBlur,
  editableOnRender = false,
  fontSize,
  showPencil = false,
  noEmpty = false,
}) => {
  const [isEditing, setIsEditing] = useState(editableOnRender);
  const [value, setValue] = useState(label);

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    handleSaveValue(e.target.value);
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      handleSaveValue(e.target.value);
    }
  };

  const handleSaveValue = (newValue: string) => {
    if (noEmpty && newValue === '') {
      setValue(label);
    } else {
      onBlur(newValue);
    }
    setIsEditing(false);
  };

  const handleEditClick = () => setIsEditing(true);

  // this is needed to prevent other global click events
  // from firing and causing some unexpected behavior
  const onClick: React.MouseEventHandler<HTMLInputElement> = (e) => {
    e.stopPropagation();
  };

  const handleLabelKeyDown: React.KeyboardEventHandler<HTMLLabelElement> = (e) => {
    setIsEditing(true);
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    // We want the input value to dictate what the rendered label value will be
    // so we set the new state value here, as it changes, BUT only save it when
    // the blur event happens. Since the new value prop from the parent will be the
    // same as the state value we use for rendering at that point, React does not
    // rerender the component (React always checks if it is different or not)
    setValue(e.target.value);
  };

  return (
    <div className={styles['label-container']}>
      {isEditing ? (
        <input
          autoFocus
          className={styles['label-input']}
          value={value}
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={onClick}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          style={{ fontSize: fontSize }}
        />
      ) : (
        <label
          tabIndex={0}
          onKeyDown={handleLabelKeyDown}
          onClick={handleEditClick}
          className={styles['label-inner-container']}
          style={{ fontSize: fontSize }}>
          <span className={styles['label']}>{value || placeholder}</span>
          <span className={`${styles['edit-label-icon']} ${showPencil ? styles['show'] : ''}`} title="Edit label">
            <TrblIcon icon="edit" hoverColor="#f5f5f5" />
          </span>
        </label>
      )}
    </div>
  );
};
