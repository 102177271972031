import { FC } from 'react';

/** Types */
import { IconProps } from '@/types';

export const TrblStopIcon: FC<IconProps> = ({ fill = '#DADADA' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      d="M3.39463 5.54432L5.47992 3.42232L8.45491 3.39636L10.5769 5.48164L10.6029 8.45664L8.51759 10.5786L5.54259 10.6046L3.4206 8.51931L3.39463 5.54432Z"
      fill={fill}
    />
    <path
      d="M0.510918 4.37885L4.26609 0.557565L9.62345 0.510812L13.4447 4.26598L13.4915 9.62335L9.73632 13.4446L4.37896 13.4914L0.557671 9.73622L0.510918 4.37885Z"
      stroke={fill}
    />
  </svg>
);
