import { FC, useEffect, useRef, useState } from 'react';
import { DoubleSide, DynamicDrawUsage, Euler, MathUtils, Mesh, PlaneGeometry, TextureLoader, Vector3 } from 'three';

/** Context */
import { ActionType, useEditorContext } from '@/context/EditorContext';
import { GridReceiver, GridReceiverPoint, GridReceiverUserInput } from '@/context/EditorContext/types';
import { deepCompare } from '@/utils/trebleFunctions';

/** Hooks */
import { useGridReceivers } from '@/components/SourceRecieverSettings/hooks/useGridReceivers';
import { useObjectClickHandler } from '../../hooks';
import { useCreateGridPoints } from '../../hooks/useCreateGridPoints';
import { useCreateGridLabel } from '../../hooks/useCreateLabel';

/** Images */
import diamondImageUrl from './images/diamond.png';
import diamonSelectedImageUrl from './images/diamond_selected.png';

const pointTexture = new TextureLoader().load(diamondImageUrl);
const pointTextureSelected = new TextureLoader().load(diamonSelectedImageUrl);

type GridProps = {
  id: string;
  index: number;
  type: 'GridReceiver';
  gridPoints: GridReceiverPoint[];
  invalidGridPoints: GridReceiverPoint[];
  position: Vector3;
  rotation: Euler;
  resolution: number;
  isSelected: boolean;
  gridVisible: boolean;
  isInResultsMode: boolean;
  onSelect: (id: string, type: 'GridReceiver') => void;
};

const Grid: FC<GridProps> = ({
  id,
  type,
  index,
  position,
  rotation,
  gridPoints,
  invalidGridPoints,
  resolution,
  isSelected,
  gridVisible,
  isInResultsMode,
  onSelect,
}) => {
  const clickHandlerProps = useObjectClickHandler(() => onSelect(id, type), !isSelected && !isInResultsMode);

  const pointLabel = useCreateGridLabel((index + 1).toString());

  return (
    <group uuid={id} key={id} name="GridReceiver" position={position} {...clickHandlerProps}>
      {gridPoints.map((point: GridReceiverPoint, index: number) => (
        <GridTile
          key={index}
          point={point}
          gridPosition={position}
          gridRotation={rotation}
          gridVisible={gridVisible}
          gridResolution={resolution}
          isSelected={isSelected}
        />
      ))}

      {invalidGridPoints.map((point: GridReceiverPoint, index: number) => (
        <GridTile
          key={index}
          point={point}
          gridPosition={position}
          gridRotation={rotation}
          gridVisible={gridVisible}
          gridResolution={resolution}
          isSelected={isSelected}
          invalid={true}
        />
      ))}

      <points name={type} position={new Vector3(0, 0, 0.1)}>
        <primitive object={pointLabel}></primitive>
        <bufferGeometry attach="geometry">
          <bufferAttribute
            attach="attributes-position"
            count={1}
            array={new Float32Array([0, 0, 0])}
            itemSize={3}
            usage={DynamicDrawUsage}
          />
        </bufferGeometry>
        <pointsMaterial
          attach="material"
          map={!isSelected ? pointTexture : pointTextureSelected}
          color={'#dddddd'}
          size={!isSelected ? 13 : 17}
          sizeAttenuation={false}
          alphaTest={0.2}
          transparent={true}
        />
      </points>
    </group>
  );
};

type GridTileProps = {
  point: GridReceiverPoint;
  gridPosition: Vector3;
  gridRotation: Euler;
  gridResolution: number;
  gridVisible: boolean;
  isSelected: boolean;
  invalid?: boolean;
};

const GridTile: FC<GridTileProps> = ({
  point,
  gridPosition,
  gridRotation,
  gridResolution,
  gridVisible,
  isSelected,
  invalid,
}) => {
  const planeRef = useRef<PlaneGeometry>(null);

  return (
    <mesh
      visible={gridVisible && (invalid ? (isSelected ? true : false) : true)}
      position={new Vector3(point.x - gridPosition.x, point.y - gridPosition.y, point.z - gridPosition.z + 0.001)}
      rotation={gridRotation}
      renderOrder={9}>
      <planeGeometry attach="geometry" args={[gridResolution - 0.03, gridResolution - 0.03]} ref={planeRef} />
      <meshBasicMaterial
        color={invalid ? '#FF9781' : isSelected ? '#5fbcff' : '#70b6ff'}
        side={DoubleSide}
        opacity={invalid ? 0.1 : isSelected ? 0.55 : 0.4}
        transparent={true}
      />
      {planeRef.current && (
        <lineSegments>
          <edgesGeometry attach="geometry" args={[planeRef.current]} />
          <lineBasicMaterial
            attach="material"
            color={invalid ? '#FF9781' : '#86ccff'}
            opacity={invalid ? 0.4 : isSelected ? 0.55 : 0.4}
            transparent={true}
          />
        </lineSegments>
      )}
    </mesh>
  );
};

export const GridReceivers = () => {
  const { selected, dispatch, isInResultsMode, hiddenSurfaceReceivers, gridReceivers, sources, editSimulation } =
    useEditorContext();

  const { handleGridReceiverPointsChange } = useGridReceivers();
  const createGridPoints = useCreateGridPoints();

  const [gridMesh, setGridMesh] = useState<Mesh[]>([]);
  const [gridPoints, setGridPoints] = useState<GridReceiverPoint[][]>([]);
  const [invalidGridPoints, setInvalidGridPoints] = useState<GridReceiverPoint[][]>([]);

  const handlePointSelect = (pointId: string, type: 'GridReceiver') => {
    if (selected?.id !== pointId) {
      dispatch({
        type: ActionType.SET_SELECTED,
        selected: {
          type: type,
          id: pointId,
        },
      });
    } else {
      dispatch({ type: ActionType.CLEAR_SELECTED });
    }
  };

  useEffect(() => {
    if (gridReceivers.length > 0) {
      createNewGridPoints(gridReceivers);
    }
  }, [gridReceivers, hiddenSurfaceReceivers, editSimulation.showModal, sources]);

  const createNewGridPoints = async (gridReceivers: GridReceiver[]) => {
    let meshArray: Mesh[] = [];
    let pointsArray: GridReceiverPoint[][] = [];
    let invalidPointsArray: GridReceiverPoint[][] = [];

    for (let i = 0; i < gridReceivers.length; i++) {
      const grUserInput: GridReceiverUserInput = gridReceivers[i].userInput;
      const grVisibility = !hiddenSurfaceReceivers.find((re) => re.id === gridReceivers[i].id);
      const mesh = createGridPlane(grUserInput, grVisibility);

      let newPoints: GridReceiverPoint[] = [];
      let invalidPoints: GridReceiverPoint[] = [];

      [newPoints, invalidPoints] = await createGridPoints(mesh, grUserInput);
      if (!isInResultsMode) {
        if (!deepCompare(newPoints, gridReceivers[i].points)) handleGridReceiverPointsChange(i, newPoints);
      }
      pointsArray.push(newPoints);
      invalidPointsArray.push(invalidPoints);
      meshArray.push(mesh);
    }

    setGridMesh(meshArray);
    setGridPoints(pointsArray);
    setInvalidGridPoints(invalidPointsArray);
  };

  return (
    <>
      {gridPoints &&
        gridMesh.length == gridReceivers.length &&
        gridMesh.map((mesh, index) => (
          <Grid
            id={gridReceivers[index].id}
            key={gridReceivers[index].id}
            index={index}
            type="GridReceiver"
            position={mesh.position}
            rotation={mesh.rotation}
            gridPoints={gridPoints[index]}
            invalidGridPoints={invalidGridPoints[index]}
            resolution={gridReceivers[index].userInput.coarseness}
            onSelect={handlePointSelect}
            isInResultsMode={isInResultsMode}
            gridVisible={!isInResultsMode && mesh.visible}
            isSelected={selected?.type === 'GridReceiver' && selected.id === gridReceivers[index].id}
          />
        ))}
    </>
  );
};

export const createGridPlane = (
  grUserInput: GridReceiverUserInput,
  grVisibility: boolean = true,
  coarsenessOverride: number | undefined = undefined,
  transform: boolean = true
) => {
  const coarseness = coarsenessOverride == undefined ? grUserInput.coarseness : coarsenessOverride;
  const coarsenessW = grUserInput.width / coarseness;
  const coarsenessL = grUserInput.length / coarseness;

  // make the Plane geometry for the GridReceiver
  const geometry = new PlaneGeometry(grUserInput.width, grUserInput.length, coarsenessW, coarsenessL);
  let mesh = new Mesh(geometry);
  mesh.visible = grVisibility;

  // The convetion is that as follows:
  // - Azimuth is CW around the positive world Z axis (Right hand rule)
  // - Elevation is 0 towards the world X axis and positive towards the Z axis
  // - Rotation is CW around the planes local X axis (Right hand rule)
  //
  // The order of the rotations in the following ensures that rotations are done correctly.
  if (transform) {
    mesh.position.set(grUserInput.centerPoint.x, grUserInput.centerPoint.y, grUserInput.centerPoint.z);
    mesh.rotateOnWorldAxis(new Vector3(1, 0, 0), MathUtils.degToRad(grUserInput.roll));
    mesh.rotateOnWorldAxis(new Vector3(0, 1, 0), MathUtils.degToRad(-grUserInput.yaw));
    mesh.rotateOnWorldAxis(new Vector3(0, 0, 1), MathUtils.degToRad(grUserInput.pitch));
  }
  // mesh.rotateZ(MathUtils.degToRad(grUserInput.pitch))
  // mesh.rotation.set(

  return mesh;
};
