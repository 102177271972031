import { ReactNode } from 'react';

/** Components */
import { AlphabetOption } from '@/components/Shared/Auralizer';
import { ReceiverControls } from './ReceiverControls';
import { TrblTooltip } from '@/components/Shared';

/** Types */
import { AuralizerSimulationDto } from '../types';

/** Utils */
import { toSorted } from '@/utils/trebleFunctions';

/** Styles */
import styles from './styles.module.scss';

type Option = { name: string; id: string };

export const Controls = ({
  simulations,
  selectedSimulationId,
  onSimulationChanged,
  selectedReceiverId,
  onReceiverChanged,
  children,
}: {
  simulations: AuralizerSimulationDto[] | undefined;
  selectedSimulationId: string;
  onSimulationChanged: (simulationId: string) => void;
  selectedReceiverId: string;
  onReceiverChanged: (receiverId: string) => void;
  children: ReactNode;
}) => {
  const selectedSimulation = simulations?.find((sim) => sim.id === selectedSimulationId);
  const receiverOptions = toSorted(
    simulations?.[0].latestSimulationRun.receivers ?? [],
    (a, b) => a.orderNumber - b.orderNumber
  ).map((receiver, i) => ({
    id: receiver.id,
    name: receiver.label ? receiver.label : `Position ${i + 1}`,
  }));

  return (
    <div className={styles['controls-container']}>
      <div className={styles['left-content']}>
        <div className={styles['options-container']}>
          {simulations?.map((comparison: Option, index: number) => (
            <TrblTooltip key={comparison.id} placement="top" arrow title={comparison.name}>
              <span>
                <AlphabetOption
                  selectedOptionId={selectedSimulationId}
                  optionId={comparison.id}
                  index={index}
                  onSelect={onSimulationChanged}
                  size="large"
                />
              </span>
            </TrblTooltip>
          ))}
        </div>
        <p className={styles['simulation-name']}>{selectedSimulation?.name}</p>
      </div>
      <div className={styles['center-content']}>{children}</div>
      <div className={styles['right-content']}>
        <ReceiverControls
          receiverOptions={receiverOptions}
          selectedReceiverId={selectedReceiverId}
          onReceiverChanged={onReceiverChanged}
        />
      </div>
    </div>
  );
};
