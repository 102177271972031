import { useReducer, createContext, useContext, ReactNode, useEffect } from 'react';

/** Types */
import { ResultComparisonState } from '../../../types';

/** Contants */
import { ActionType, initialState } from '../constants';

/** Reducer */
import { ResultComparisonReducer } from './reducer';
import { ResultComparisonAction } from './actions';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

type ResultComparisonProviderProps = { children: ReactNode };

type Dispatch = (action: ResultComparisonAction) => void;

const ResultComparisonContext = createContext<
  | {
      state: ResultComparisonState;
      dispatch: Dispatch;
    }
  | undefined
>(undefined);

const ResultComparisonProvider = ({ children }: ResultComparisonProviderProps) => {
  const [state, dispatch] = useReducer(ResultComparisonReducer, initialState);

  const value = { state, dispatch };

  useEffect(() => {
    if (state.lastGridResults && !state.simulationData?.gridReceiverParameterValues) {
      const promisesWithParameters: {
        promise: Promise<AxiosResponse<any, any>>;
        sourcePointId: string;
        gridReceiverPointId: string;
      }[] = [];

      // We want to load the result parameter values for all source / receiver pairs for the default result type
      state.lastGridResults
        .filter((x) => x.resultType === state.resultType)
        .forEach((y) => {
          y.gridReceiverResults.forEach((x) => {
            const promise = axios.get(x.parametersDownloadUrl);

            promisesWithParameters.push({ promise, sourcePointId: y.sourcePointId, gridReceiverPointId: x.pointId });
          });
        });

      Promise.all(promisesWithParameters.map((p) => p.promise))
        .then((results) => {
          let gridReceiverParametersForSource: Record<string, Record<string, any>> = {};

          for (let i = 0; i < results.length; i++) {
            const result = results[i];
            const { sourcePointId, gridReceiverPointId } = promisesWithParameters[i];

            if (gridReceiverParametersForSource[sourcePointId]) {
              gridReceiverParametersForSource[sourcePointId][gridReceiverPointId] = result.data;
            } else {
              gridReceiverParametersForSource[sourcePointId] = {};
              gridReceiverParametersForSource[sourcePointId][gridReceiverPointId] = result.data;
            }
          }

          dispatch({
            type: ActionType.UPDATE_GRID_RECEIVER_RESULT_PARAMETERS,
            payload: gridReceiverParametersForSource,
          });
        })
        .catch((error) => {
          toast.error('Failed to load results for surface receiver');
        });
    }
  }, [state.lastGridResults]);

  return <ResultComparisonContext.Provider value={value}>{children}</ResultComparisonContext.Provider>;
};

// Custom Context hook to easily access the state and dispatch actions
const useResultComparisonContext = () => {
  const context = useContext(ResultComparisonContext);
  if (context === undefined) {
    throw new Error('useResultComparisonContext must be used within ResultComparisonProvider');
  }
  return context;
};

export { ResultComparisonProvider, useResultComparisonContext };
