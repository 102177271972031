import { FC } from 'react';

/** Types */
import { IconProps } from '@/types';

export const TrblEditIcon: FC<IconProps> = ({ fill, height = '8', width = '8' }) => (
  <svg width={width} height={height} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.02214 1.32863C4.99094 1.29715 4.94847 1.27939 4.90414 1.2793C4.85986 1.27931 4.81741 1.29694 4.78614 1.3283L1.14548 4.96996C1.08041 5.03505 1.08041 5.14055 1.14548 5.20563L2.79481 6.85496C2.85989 6.92003 2.96539 6.92003 3.03048 6.85496L6.66948 3.21596C6.73432 3.15092 6.73432 3.04568 6.66948 2.98063L5.02214 1.32863Z"
      fill={fill || '#DADADA'}
    />
    <path
      d="M0.810004 5.60002C0.76732 5.55747 0.704995 5.54129 0.647004 5.55768C0.58892 5.57397 0.544172 5.62038 0.530004 5.67902L0.0280044 7.77135C0.0145329 7.82773 0.0313216 7.88706 0.0723377 7.92802C0.113596 7.96851 0.172694 7.98511 0.229004 7.97202L2.31967 7.47202C2.37837 7.45797 2.42489 7.41328 2.44128 7.35518C2.45767 7.29709 2.44137 7.23468 2.39867 7.19202L0.810004 5.60002Z"
      fill={fill || '#DADADA'}
    />
    <path
      d="M7.73361 0.974683L7.02595 0.266683C6.70064 -0.0579697 6.17391 -0.0579697 5.84861 0.266683L5.37595 0.739016C5.31088 0.804099 5.31088 0.909599 5.37595 0.974683L7.02595 2.62435C7.09103 2.68941 7.19653 2.68941 7.26161 2.62435L7.73361 2.15135C8.05782 1.82612 8.05782 1.29991 7.73361 0.974683Z"
      fill={fill || '#DADADA'}
    />
  </svg>
);
