import dayjs from 'dayjs';
import { ReactNode, createContext, useContext, useEffect, useMemo, useReducer } from 'react';

/** Types */
import { FeatureFlags } from '@/components/FeatureToggles/types';
import { ServiceMessage } from '@/hooks/useGetServiceMessage';

/** Hooks */
import { useGetUserMine } from '@/hooks';

/** Constants */
import { AVAILABLE_FEATURE_FLAGS } from '@/components/FeatureToggles/constants';

export type UserInfo = {
  featureFlags: string[];
  roles: string[];
  id: string;
};

export type SubscriptionInfo = {
  type: string;
  daysUntilExpired: number | null;
};

enum BaseContextActionType {
  SET_USER_INFO = 'SET_USER_INFO',
  SET_SUBSCRIPTION_INFO = 'SET_SUBSCRIPTION_INFO',
  SET_FEATURE_FLAGS = 'SET_FEATURE_FLAGS',
  SET_SERVICE_MESSAGE = 'SET_SERVICE_MESSAGE',
}

type State = {
  userInfo: UserInfo | null;
  subscriptionInfo: SubscriptionInfo | null;
  featureFlags: FeatureFlags;
  serviceMessage: ServiceMessage | undefined;
};

const initialState: State = {
  userInfo: null,
  subscriptionInfo: null,
  featureFlags: AVAILABLE_FEATURE_FLAGS,
  serviceMessage: undefined,
};

type BaseProviderProps = { serviceMessage: ServiceMessage | undefined; children: ReactNode };

type BaseContextAction =
  | { type: BaseContextActionType.SET_FEATURE_FLAGS; featureFlags: FeatureFlags }
  | { type: BaseContextActionType.SET_SUBSCRIPTION_INFO; subscriptionInfo: SubscriptionInfo }
  | { type: BaseContextActionType.SET_USER_INFO; userInfo: UserInfo }
  | { type: BaseContextActionType.SET_SERVICE_MESSAGE; serviceMessage: ServiceMessage | undefined };

type Dispatch = (action: BaseContextAction) => void;

const BaseContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

const reducer = (state: State, action: BaseContextAction): State => {
  switch (action.type) {
    case BaseContextActionType.SET_USER_INFO: {
      return {
        ...state,
        userInfo: action.userInfo,
      };
    }
    case BaseContextActionType.SET_SUBSCRIPTION_INFO: {
      return {
        ...state,
        subscriptionInfo: action.subscriptionInfo,
      };
    }
    case BaseContextActionType.SET_FEATURE_FLAGS: {
      return {
        ...state,
        featureFlags: action.featureFlags,
      };
    }

    case BaseContextActionType.SET_SERVICE_MESSAGE: {
      return {
        ...state,
        serviceMessage: action.serviceMessage,
      };
    }
  }
};

const BaseProvider = ({ serviceMessage, children }: BaseProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { data: user } = useGetUserMine();

  useEffect(() => {
    dispatch({
      type: BaseContextActionType.SET_SERVICE_MESSAGE,
      serviceMessage,
    });
  }, [serviceMessage]);

  useEffect(() => {
    if (user) {
      dispatch({
        type: BaseContextActionType.SET_USER_INFO,
        userInfo: {
          id: user.id,
          featureFlags: user.featureFlags ?? [],
          roles: user.roles ?? [],
        },
      });
      let daysUntilExpired: number | null = null;

      if (user.tassSubscriptionExpiresAt) {
        const today = dayjs().startOf('day');
        const trialExpiresAt = dayjs(user.tassSubscriptionExpiresAt).startOf('day');
        daysUntilExpired = trialExpiresAt.diff(today, 'day');
      }

      dispatch({
        type: BaseContextActionType.SET_SUBSCRIPTION_INFO,
        subscriptionInfo: {
          type: user.billingType === 'PrepaidOnly' ? 'Flexible' : user.billingType,
          daysUntilExpired,
        },
      });
    }
  }, [user]);

  useEffect(() => {
    if (state.userInfo) {
      // Covers both when running locally and when building for DEV environment
      const isDev = import.meta.env.MODE === 'dev' || import.meta.env.DEV;

      if (isDev) {
        // loop over all available features and set them to true
        const availableFeatures = Object.keys(AVAILABLE_FEATURE_FLAGS).reduce((acc: FeatureFlags, key: string) => {
          acc[key] = true;
          return acc;
        }, {});

        dispatch({
          type: BaseContextActionType.SET_FEATURE_FLAGS,
          featureFlags: availableFeatures,
        });
      }
      // if we are not on dev but the user has that specific feature flag enabled
      // we make that feature available to them
      else {
        // loop over all available features and check if the user has a featureFlags prop
        // and if that array contains the same string for a feature flag
        const availableFeatures = Object.keys(AVAILABLE_FEATURE_FLAGS).reduce((acc: FeatureFlags, key: string) => {
          const userHasFeatureFlag = state.userInfo?.featureFlags?.find((featureFlag: string) => {
            return featureFlag.toLowerCase() === key.toLowerCase();
          });
          acc[key] = !!userHasFeatureFlag;
          return acc;
        }, {});

        dispatch({
          type: BaseContextActionType.SET_FEATURE_FLAGS,
          featureFlags: availableFeatures,
        });
      }
    }
  }, [state.userInfo]);

  // Memoize the value object
  const value = useMemo(
    () => ({
      state: {
        ...state,
      },
      dispatch,
    }),
    [state]
  );

  return <BaseContext.Provider value={value}>{children}</BaseContext.Provider>;
};

const useBaseContext = () => {
  const context = useContext(BaseContext);
  if (context === undefined) {
    throw new Error('useBaseContext must be used within BaseProvider');
  }
  return context;
};

export { BaseContextActionType, BaseProvider, useBaseContext };
