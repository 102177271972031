import { useQueries } from '@tanstack/react-query';

import { loadAndExtractFile } from './useLoadAndExtractFileFromUrl';

export const useLoadAndExtractFilesFromUrls = (
  urls: { url: string; modelId: string }[],
  onSuccess?: (modelId: string, data: { fileData: ArrayBufferLike; fileName: string }) => void
) => {
  const results = useQueries({
    queries: urls.map((urlData) => ({
      queryKey: ['modelFile', urlData.modelId],
      queryFn: () => loadAndExtractFile(urlData.url),
      onSuccess: onSuccess
        ? (data: { fileData: ArrayBufferLike; fileName: string }) => onSuccess(urlData.modelId, data)
        : undefined,
      refetchOnWindowFocus: false,
    })),
  });

  return results;
};
